import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { deletePassenger, deletePassengerFromFlight } from '@/services/api/passengers';
import { PassengerListItemWithFlights } from '@flyblack/common/domains';
import { LegOption } from '@/domains/charter';

import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

import ItemMenu from './ItemMenu';
import PassengerForm from './PassengerForm';

interface Props {
  index: number;
  tripId: number;
  passenger: PassengerListItemWithFlights;
  legOptions?: LegOption[];
  flightId?: number;
  onAction: () => any;
}

const PassengerItem: React.FC<Props> = ({ passenger, index, onAction, tripId, legOptions, flightId }) => {
  const [editable, setEditable] = React.useState(false);

  return editable ? (
    <PassengerForm
      passengerId={passenger.id}
      initialData={{
        birthDate: passenger.birthDate,
        legalName: passenger.legalName,
        weight: passenger.weight ? passenger.weight.value : null,
        ...(passenger.jetCharterFlightIds && { jetCharterFlightIds: passenger.jetCharterFlightIds })
      }}
      onCancel={() => setEditable(false)}
      onSuccess={onAction}
      tripId={tripId}
      legOptions={legOptions}
    />
  ) : (
    <div className="flex flex-row items-center w-full mb-6">
      <Icon type="navigationAccount" className="mr-6" />

      <div className="flex flex-col">
        <Typography is="span" type="hummingbird" color="lightGray">
          <FormattedMessage
            id={translations.pages.myTrips.detail.passengersSection.passengerItemTitle}
            values={{ passengerCount: index + 1 }}
          />
        </Typography>

        <Typography is="span" type="halcyon">
          {passenger.legalName}
        </Typography>
      </div>

      <ItemMenu
        type="passenger"
        onDelete={() => deletePassenger(passenger.id).then(onAction)}
        onDeleteFlight={
          legOptions &&
          legOptions.length > 1 &&
          flightId &&
          (() => deletePassengerFromFlight(passenger.id, flightId).then(onAction))
        }
        onEdit={() => setEditable(true)}
      />
    </div>
  );
};

export default PassengerItem;
