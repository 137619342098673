import * as yup from 'yup';

import { translations } from '@/locale';
import { MembershipApplication } from '@/domains/membership';
import { STRING_LONG_MAX_LENGTH, STRING_VERY_LONG_MAX_LENGTH } from '@/constants';
import { MAX_FILE_SIZE, SUPPORTED_IMAGE_FORMATS } from '@flyblack/common/constants';

const stringSchema = yup.string().max(STRING_LONG_MAX_LENGTH);

const photoSchema = yup
  .mixed()
  .test({
    name: 'fileSize',
    message: translations.validation.custom.fileSize,
    test: (value) => !value || !value.size || value.size <= MAX_FILE_SIZE
  })
  .test({
    name: 'fileFormat',
    message: translations.validation.custom.fileFormat,
    test: (value) => !value || !value.type || SUPPORTED_IMAGE_FORMATS.includes(value.type)
  });

const yourProfileSchemaObject = {
  firstName: stringSchema.label(translations.inputs.firstName.label).required(),
  lastName: stringSchema.label(translations.inputs.lastName.label).required(),
  email: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.email.label)
    .required(),
  phoneNumber: stringSchema.label(translations.inputs.lastName.label).required(),
  birthDate: stringSchema.label(translations.inputs.lastName.label).required(),
  weight: yup
    .number()
    .label(translations.inputs.weight.label)
    .required(),
  profilePictureUrl: photoSchema.label(translations.inputs.profilePicture.label).required(),
  identityDocumentUrl: photoSchema.label(translations.inputs.identityDocument.label).required(),
  nationality: stringSchema.label(translations.inputs.nationality.label).required(),
  gender: stringSchema.label(translations.inputs.gender.label).required()
};

const aboutYouSchemaObject = {
  ...yourProfileSchemaObject,
  workRole: stringSchema.label(translations.inputs.workRole.label).required(),
  workplace: stringSchema.label(translations.inputs.workplace.label).required(),
  applicationReason: yup
    .string()
    .max(STRING_VERY_LONG_MAX_LENGTH)
    .label(translations.inputs.applicationReason.label)
    .required()
};

const summarySchemaObject = {
  ...aboutYouSchemaObject,
  termsAndConditions: yup
    .boolean()
    .oneOf([true], translations.validation.custom.acceptAgreement)
    .label(translations.inputs.termsAndConditions)
    .required()
};

const yourProfileSchema = yup.object({ ...yourProfileSchemaObject });

const aboutYouSchema = yup.object({ ...aboutYouSchemaObject });

const summarySchema = yup.object<MembershipApplication & { termsAndConditions: boolean }>({ ...summarySchemaObject });

export const stepToSchemaMap: Record<number, any> = {
  1: yourProfileSchema,
  2: aboutYouSchema,
  3: summarySchema
};
