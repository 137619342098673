import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { sortPhotos } from '@flyblack/common/util';
import { getAircraftType } from '@/services/api/aircraft-type';

import useLoad from '@flyblack/common/hooks/useLoad';
import Spacer from '@flyblack/common/components/Spacer';
import ClientPhotoGallery from '@/components/ClientPhotoGallery';

import TitleSection from './TitleSection';
import AboutSection from './AboutSection';
import StatsSection from './StatsSection';
import UnknownErrorView from '@/components/UnknownErrorView';

export interface Props {}
interface Params {
  id: string;
}

const AircraftTypeDetail: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const { value: aircraftType, loading, error } = useLoad({ load: () => getAircraftType(parseInt(match.params.id)) });

  return (
    !loading &&
    (error ? (
      <UnknownErrorView />
    ) : (
      <div className="bg-black min-h-full w-full flex flex-col items-center overflow-auto">
        <Spacer xs={5} sm={8} />

        <div className="flex flex-wrap w-full justify-around lg:justify-between max-w-4xl">
          <TitleSection
            title={`${aircraftType.name}, ${aircraftType.aircraftCategory.name}`}
            manufacturer={aircraftType.manufacturer}
            numberOfSeats={aircraftType.numberOfSeats}
            crewNumber={aircraftType.crewNumber}
            className="order-0"
          />

          <ClientPhotoGallery
            images={sortPhotos(aircraftType.photos)}
            loading={loading}
            className="order-1 mb-6 lg:mb-0"
          />

          <AboutSection description={aircraftType.description} className="order-2 lg:order-3" />

          <StatsSection
            maximumPayload={aircraftType.maximumPayload}
            cruiseSpeed={aircraftType.cruiseSpeed}
            serviceCeiling={aircraftType.serviceCeiling}
            maximumRange={aircraftType.maximumRange}
            className="order-3 lg:order-2"
          />
        </div>
        <Spacer xs={8} />
      </div>
    ))
  );
};

export default AircraftTypeDetail;
