import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import { urls } from '@/constants';

import { useQuery } from '@flyblack/common/hooks/useQuery';

interface Params {
  id: string;
}

export const withSeatsParam = (Component: React.ComponentType<any>) => ({
  match,
  ...rest
}: RouteComponentProps<Params>) => {
  const query = useQuery();

  const seats = parseInt(query.get('seats'));

  return seats ? (
    <Component id={parseInt(match.params.id)} seats={seats} {...rest} />
  ) : (
    <Redirect to={urls.jetShuttles.detail.get(parseInt(match.params.id))} />
  );
};
