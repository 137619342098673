import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { translations } from '@/locale';

import ErrorView from '@/components/ErrorView';

interface Props {
  error: any;
}

const CommonErrorView = ({ error }: Props) => {
  const intl = useIntl();
  const history = useHistory();

  const errorMessage =
    error &&
    (typeof error === 'string'
      ? translations.application.common[error] || translations.application.common.unknownError
      : translations.application.common.unknownError);

  return (
    <ErrorView
      title={intl.formatMessage({ id: errorMessage.title })}
      subtitle={intl.formatMessage({ id: errorMessage.subtitle })}
      tryAgain={() => history.go(0)}
    />
  );
};

export default CommonErrorView;
