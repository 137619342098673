import React from 'react';
import { useIntl } from 'react-intl';

import { CheckoutStatus } from '@flyblack/common/domains';
import { translations } from '@/locale';

import { useQuery } from '@flyblack/common/hooks/useQuery';

import LogoLink from '@flyblack/common/components/LogoLink';

import ErrorView from '@/components/ErrorView';
import UnknownErrorView from '@/components/UnknownErrorView';
import StripeElementsProvider from '@/components/StripeElementsProvider';

import MembershipCheckoutView from './MembershipCheckoutView';
import PaymentSuccessView from './PaymentSuccessView';

const MembershipCheckout: React.FC = () => {
  const intl = useIntl();

  const query = useQuery();

  const [checkoutStatus, setCheckoutStatus] = React.useState(CheckoutStatus.Default);

  const resetCheckoutStatus = () => setCheckoutStatus(CheckoutStatus.Default);

  const checkoutViewByStatus: Record<Exclude<CheckoutStatus, CheckoutStatus.BookingNotAllowed>, React.FC> = {
    [CheckoutStatus.Default]: () => (
      <StripeElementsProvider>
        <MembershipCheckoutView
          membershipId={parseInt(query.get('membershipId'))}
          setCheckoutStatus={setCheckoutStatus}
        />
      </StripeElementsProvider>
    ),
    [CheckoutStatus.PaymentSucceeded]: () => <PaymentSuccessView />,
    [CheckoutStatus.PaymentFailed]: () => (
      <ErrorView
        title={intl.formatMessage({ id: translations.application.common.paymentFail.title })}
        subtitle={intl.formatMessage({ id: translations.application.common.paymentFail.subtitle })}
        tryAgain={resetCheckoutStatus}
      />
    ),
    [CheckoutStatus.ACHSetupFailed]: () => (
      <ErrorView
        title={intl.formatMessage({ id: translations.application.common.achSetupFail.title })}
        subtitle={intl.formatMessage({ id: translations.application.common.achSetupFail.subtitle })}
        tryAgain={resetCheckoutStatus}
      />
    ),
    [CheckoutStatus.UnknownError]: () => <UnknownErrorView />
  };

  const CheckoutViewByStatus = checkoutViewByStatus[checkoutStatus];

  return (
    <div className={'flex flex-col relative text-white lg:flex-row'}>
      <div className="absolute top-7 left-10">
        <LogoLink className="h-4 w-auto" />
      </div>
      <CheckoutViewByStatus />
    </div>
  );
};

export default MembershipCheckout;
