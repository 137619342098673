import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Pricing } from '@flyblack/common/domains';
import { translations } from '@/locale';

import Typography from '@flyblack/common/components/Typography';
import ToggleSwitch from '@flyblack/common/components/ToggleSwitch';

export interface Props {
  payCarbonOffset: boolean;
  setPayCarbonOffset: () => any;
  carbonOffset?: Pricing;
  loadingPrice: boolean;
}

const FlightCarbonOffsetSection = ({ payCarbonOffset, setPayCarbonOffset, carbonOffset, loadingPrice }) => {
  return (
    <div className="relative bg-white text-black p-8 pl-6">
      <Typography is="div" type="hummingbird">
        <FormattedMessage
          id={translations.pages.checkout.paymentSection.carbonOffset.title}
          values={
            carbonOffset
              ? { value: carbonOffset.value.toFixed(2), symbol: carbonOffset.symbol }
              : { value: '', symbol: '' }
          }
        />
      </Typography>
      <Typography is="div" type="hummingbird" className="text-flyblack-darker-gray pt-4">
        <FormattedMessage id={translations.pages.checkout.paymentSection.carbonOffset.detail} />
      </Typography>
      <div className="absolute top-8 right-8">
        <ToggleSwitch
          appearance="light"
          checked={payCarbonOffset}
          onClick={setPayCarbonOffset}
          disabled={loadingPrice}
        />
      </div>
    </div>
  );
};

export default FlightCarbonOffsetSection;
