import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';

import DatePickerInput from '@flyblack/common/components/Form/Input/DatePickerInput';
import DateRangePickerInput from '@flyblack/common/components/Form/Input/DateRangePickerInput';

import CharterAirportSelect from '@flyblack/common/components/CharterAirportSelect';
import PassengerNumberSelect from '@/components/PassengerNumberSelect';

export interface Props {
  values;
  multiLeg?;
  roundTrip?;
  onChange;
  onDelete?;
}

const ResultsSearchRow: React.FC<Props> = ({ values, multiLeg, roundTrip, onChange, onDelete }) => {
  const intl = useIntl();

  const dateSelect = roundTrip ? (
    <DateRangePickerInput
      id="from-date"
      type="text"
      name="dateRange"
      placeholder={intl.formatMessage({ id: translations.inputs.dateRange.label })}
      startDateValue={values.fromDateStart}
      endDateValue={values.fromDateEnd}
      changeStartDate={(_, value) => onChange('fromDateStart', value)}
      changeEndDate={(_, value) => onChange('fromDateEnd', value)}
      className="w-[210px] flex-1 mr-4"
    />
  ) : (
    <DatePickerInput
      id="from-date"
      type="text"
      name="fromDate"
      placeholder={intl.formatMessage({ id: translations.inputs.dateRange.label })}
      value={values.fromDate}
      change={(_, value) => onChange('fromDate', value)}
      className="w-[210px] flex-1 mr-4"
    />
  );

  return (
    <div className="flex flex-col gap-6 sm:gap-0 mb-6 md:flex-row w-full">
      <div className="w-full mr-4">
        <CharterAirportSelect
          value={values.fromICAO}
          onChange={(value) => onChange('fromICAO', value)}
          id={`from-icao`}
          name={`fromICAO`}
          type="text"
          //@ts-ignore
          leadingIcon="departure"
          placeholder={intl.formatMessage({
            id: translations.inputs.searchBars.charterDeparture.placeholder
          })}
          className="w-full flex-1"
          editable
        />
      </div>

      <div className="w-full mr-4">
        <CharterAirportSelect
          value={values.toICAO}
          onChange={(value) => onChange('toICAO', value)}
          id={`to-icao`}
          name={`toICAO`}
          type="text"
          //@ts-ignore
          leadingIcon="destination"
          placeholder={intl.formatMessage({
            id: translations.inputs.searchBars.charterDestination.placeholder
          })}
          className="w-full flex-1"
          editable
        />
      </div>

      <div className="flex w-full">
        {dateSelect}

        {!multiLeg ? (
          <PassengerNumberSelect
            value={values.paxNum}
            onChange={(value) => onChange('paxNum', value)}
            id={`pax-num`}
            name={`paxNum`}
            type="text"
            //@ts-ignore
            leadingIcon="crew"
            inputClassName="pr-[10px]"
            className="w-20"
          />
        ) : (
          <div className="ml-auto w-20 flex" />
        )}
      </div>
    </div>
  );
};

export default ResultsSearchRow;
