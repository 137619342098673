import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ErrorResultType, ResultScope, SuccessResultType, urls } from '@/constants';
import { translations } from '@/locale';
import { hasValidMembership } from '@/util/membership';

import LogoLink from '@flyblack/common/components/LogoLink';
import { SessionContext } from '@/components/Context/Session';

import SuccessPage from './SuccessPage';
import ErrorPage from './ErrorPage';

interface UrlSuccessParams {
  scope: ResultScope.Success;
  type: SuccessResultType;
}

interface UrlErrorParams {
  scope: ResultScope.Error;
  type: ErrorResultType;
}

const ResultPage = () => {
  const { scope, type } = useParams<UrlSuccessParams | UrlErrorParams>();
  const intl = useIntl();
  const history = useHistory();

  const { me } = React.useContext(SessionContext);

  const goToMyTrips = () => history.push(urls.myTrips.base);

  const goToCharters = () => history.push(urls.jetCharters.base);

  const goBack = () => history.goBack();

  const renderResult = () => {
    if (scope === ResultScope.Success) {
      switch (type) {
        case SuccessResultType.CharterBookingRequestHandled:
          return me.membership && hasValidMembership(me.membership.status) ? (
            <SuccessPage
              title={intl.formatMessage({ id: translations.pages.result.success.charterBookingRequestSent.title })}
              subtitle={intl.formatMessage({
                id: translations.pages.result.success.charterBookingRequestSent.subtitle
              })}
              details={intl.formatMessage({ id: translations.pages.result.success.charterBookingRequestSent.details })}
              buttonTitle={intl.formatMessage({
                id: translations.pages.result.success.charterBookingRequestSent.buttonTitle
              })}
              onButtonClick={goToMyTrips}
            />
          ) : (
            <SuccessPage
              title={intl.formatMessage({
                id: translations.pages.result.success.charterBookingRequestRegistered.title
              })}
              details={intl.formatMessage({
                id: translations.pages.result.success.charterBookingRequestRegistered.details
              })}
              buttonTitle={intl.formatMessage({
                id: translations.pages.result.success.charterBookingRequestRegistered.buttonTitle
              })}
              onButtonClick={goToCharters}
            />
          );
        default:
          return <Redirect to={urls.home} />;
      }
    }

    switch (type) {
      case ErrorResultType.CharterBookingRequestFail:
        return (
          <ErrorPage
            title={intl.formatMessage({ id: translations.pages.result.error.charterBookingRequestFail.title })}
            subtitle={intl.formatMessage({ id: translations.pages.result.error.charterBookingRequestFail.subtitle })}
            onTryAgain={goBack}
          />
        );
      case ErrorResultType.Unknown:
        return (
          <ErrorPage
            title={intl.formatMessage({ id: translations.application.common.unknownError.title })}
            subtitle={intl.formatMessage({ id: translations.application.common.unknownError.subtitle })}
            onTryAgain={goBack}
          />
        );
      default:
        return <Redirect to={urls.home} />;
    }
  };

  return (
    <div className={'flex flex-col relative text-white lg:flex-row'}>
      <div className="absolute top-7 left-10">
        <LogoLink className="h-4 w-auto" />
      </div>
      {renderResult()}
    </div>
  );
};

export default ResultPage;
