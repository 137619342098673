import { useStripe } from '@stripe/react-stripe-js';

import { createPaymentIntent } from '@/services/api/payment';
import { PaymentChargeRequest, PaymentIntentResponse, PaymentResponseStatus } from '@flyblack/common/domains';

interface PaymentSubmitOption extends Omit<PaymentChargeRequest, 'bankAccountId'> {
  paymentMethodId: string;
}

export const useStripePayment = () => {
  const stripe = useStripe();

  const submitPayment = ({ paymentMethodId, ...paymentChargeRequestParams }: PaymentSubmitOption) => {
    return new Promise((resolve, reject) => {
      return createPaymentIntent(paymentChargeRequestParams)
        .then(({ clientSecret, flightStatus }: PaymentIntentResponse) => {
          flightStatus !== PaymentResponseStatus.FreeFlight
            ? stripe
                /* eslint-disable @typescript-eslint/camelcase */
                .confirmCardPayment(clientSecret, { payment_method: paymentMethodId })
                /* eslint-enable @typescript-eslint/camelcase */
                .then(({ error, paymentIntent }) => (paymentIntent ? resolve(paymentIntent) : reject(error)))
            : resolve(flightStatus);
        })
        .catch((error) => reject(error));
    });
  };

  return { submitPayment };
};
