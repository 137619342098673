import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import * as yup from 'yup';
import { Dialog, Transition } from '@headlessui/react';

import { translations } from '@/locale';
import { getUserCities, updateUserCities } from '@/services/api/user-cities';

import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import { SubmitError } from '@flyblack/common/components/Error';
import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';
import Form from '@flyblack/common/components/Form';
import Typography from '@flyblack/common/components/Typography';
import Spacer from '@flyblack/common/components/Spacer';
import Loading from '@flyblack/common/components/Loading';

import LocationSelect from '@/components/LocationSelect';
import CitySelect from '@/components/CitySelect';

const LocationSelectWithValidation = withValidation(LocationSelect);
const CitySelectWithValidation = withValidation(CitySelect);

const schema = yup.object({
  location: yup
    .object({
      placeId: yup.string().required(),
      country: yup.string().required()
    })
    .label(translations.inputs.location.label)
    .default(null)
    .required(),
  cityIds: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        id: yup.number().nullable()
      })
    )
    .required()
});

interface FormState {
  location: { placeId: string; country: string };
  cityIds: { id: number }[];
}

export interface Props {
  open: boolean;
  setOpen: (boolean) => any;
}

const FrequentLocations: React.FC<Props> = ({ open, setOpen }) => {
  const intl = useIntl();

  const [loading, setLoading] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<FormState>();

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      getUserCities()
        .then(({ homeLocation, relevantLocations }) =>
          setInitialValues({
            location: { placeId: homeLocation.placeId, country: homeLocation.countryCode },
            cityIds:
              relevantLocations.length === 1
                ? [{ id: relevantLocations[0].id }, { id: null }]
                : relevantLocations.length > 1
                ? [{ id: relevantLocations[0].id }, { id: relevantLocations[1].id }]
                : [{ id: null }, { id: null }]
          })
        )
        .finally(() => setLoading(false));
    }
  }, [open]);

  const handleSubmit = ({ location, cityIds }: FormState) => {
    return updateUserCities(
      location.placeId,
      cityIds.filter((item) => item.id).map((item) => item.id)
    ).then(() => setOpen(false));
  };

  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-20 shadow-inner" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 left-0 max-w-full flex">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-x-full"
              enterTo="-translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="-translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="w-screen max-w-[468px] px-6 flex flex-col items-center bg-white">
                <div className="relative flex flex-col max-w-[340px] w-full text-black">
                  <div className="flex justify-between items-center mt-6 md:mt-11">
                    <Typography is="span" type="owl" color="black">
                      <FormattedMessage id={translations.pages.profile.frequentLocations.title} />
                    </Typography>

                    <Button
                      type="button"
                      appearance="transparent"
                      className=" z-[1] h-[24px] w-[24px] cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <Icon type="close" className="text-black" />
                    </Button>
                  </div>

                  <Spacer xs={3} />

                  <Typography is="span" type="hummingbird" color="lightGray">
                    <FormattedMessage id={translations.pages.profile.frequentLocations.description} />
                  </Typography>

                  <Spacer xs={3} />

                  {loading ? (
                    <Loading visible={true} className="mt-40" center>
                      <Loading.Indicator size={120} borderWidth={2} />
                    </Loading>
                  ) : (
                    <Form
                      initialValues={initialValues}
                      schema={schema}
                      subscription={{ submitError: true, submitting: true, values: true, valid: true, dirty: true }}
                      onSubmit={handleSubmit}
                      mutators={{ ...arrayMutators }}
                    >
                      {({ submitError, submitting, dirty, values, form: { change } }) => (
                        <div>
                          <Typography is="span" type="hummingbird" color="lightGray">
                            <FormattedMessage id={translations.pages.profile.frequentLocations.homeLocationTitle} />
                          </Typography>

                          <Spacer xs={1} />

                          <Form.Field
                            is={LocationSelectWithValidation}
                            id="location"
                            name="location"
                            type="text"
                            leadingIcon="location"
                            appearance="light"
                            placeholder={intl.formatMessage({ id: translations.inputs.location.placeholder })}
                            readOnly={submitting}
                            editable
                          />

                          <Spacer xs={3} />

                          <Typography is="span" type="hummingbird" color="lightGray">
                            <FormattedMessage
                              id={translations.pages.profile.frequentLocations.relevantLocationsTitle}
                            />
                          </Typography>

                          <FieldArray name="cityIds">
                            {({ fields }) =>
                              fields.map((name, index) => (
                                <React.Fragment key={`${name}-${index}`}>
                                  <Spacer xs={1} />
                                  <div className="flex w-full items-center">
                                    <div className="w-full">
                                      <Form.Field
                                        is={CitySelectWithValidation}
                                        id={`${name}-${index}`}
                                        name={`${name}.id`}
                                        type="text"
                                        leadingIcon="city"
                                        appearance="light"
                                        placeholder={intl.formatMessage({
                                          id: translations.inputs.nearestCity.placeholder
                                        })}
                                        readOnly={submitting}
                                      />
                                    </div>

                                    {index > 0 && values.cityIds[index].id && (
                                      <Button
                                        type="button"
                                        appearance="white"
                                        className="w-6 h-6"
                                        onClick={() => {
                                          //@ts-ignore
                                          change(`cityIds[${index}].id`, null);
                                        }}
                                      >
                                        <Icon type="close" className="text-black cursor-pointer" />
                                      </Button>
                                    )}
                                  </div>
                                </React.Fragment>
                              ))
                            }
                          </FieldArray>

                          <Spacer xs={3} />

                          {submitError && (
                            <div className="flex flex-col items-end">
                              <SubmitError error={submitError} />
                              <Spacer xs={3} />
                            </div>
                          )}

                          <Button
                            fat
                            type="submit"
                            className="w-full"
                            appearance="black"
                            disabled={!dirty || submitting}
                            loading={submitting}
                          >
                            <FormattedMessage id={translations.inputs.buttons.save} />
                          </Button>
                        </div>
                      )}
                    </Form>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FrequentLocations;
