import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { CheckoutStatus, FlightCodeType, PaymentMethodType, TypeOfProduct } from '@flyblack/common/domains';
import { getScheduleDeparture } from '@flyblack/common/util';
import { getPaymentMethods, payWithACH } from '@/services/api/payment';
import { getJetCharterTripPrice, getMyTripDetail } from '@/services/api/trips';

import { useStripePayment } from '@/hooks/useStripePayment';

import useLoad from '@flyblack/common/hooks/useLoad';
import Loading from '@flyblack/common/components/Loading';
import Typography from '@flyblack/common/components/Typography';
import Icon from '@flyblack/common/components/Icon';
import Accordion from '@flyblack/common/components/Accordion';
import { isValidError, SubmitError } from '@flyblack/common/components/Error';

import FlightInfoSection from '@/components/Checkout/FlightInfoSection';
import FlightQuoteSection from '@/components/Checkout/FlightQuoteSection';
import FlightCheckoutForm from '@/components/Checkout/FlightCheckoutForm';
import SpecialCodeSection from '@/components/Checkout/SpecialCodeSection';
import CreditsSection from '@/components/Checkout/CreditsSection';
import UnknownErrorInfoBar from '@/components/UnknownErrorInfoBar';

interface Props {
  tripId: number;
  setCheckoutStatus: React.Dispatch<React.SetStateAction<CheckoutStatus>>;
}

const JetCharterCheckoutView = ({ tripId, setCheckoutStatus }: Props) => {
  const intl = useIntl();

  const { submitPayment } = useStripePayment();

  const [payCarbonOffset, setPayCarbonOffset] = React.useState(true);
  const [specialCode, setSpecialCode] = React.useState('');
  const [useCredits, setUseCredits] = React.useState(false);

  const { value: charterTripDetail, loading: loadingCharterTripDetail, error: errorCharterTripDetail } = useLoad({
    load: getMyTripDetail,
    id: tripId
  });

  const {
    value: charterTripPrice,
    loading: loadingCharterTripPrice,
    reload: reloadCharterTripPrice,
    error: errorCharterTripPrice
  } = useLoad({
    load: () =>
      getJetCharterTripPrice(tripId, {
        carbonOffset: payCarbonOffset ? 'INCLUDED' : 'NOT_INCLUDED',
        ...(useCredits
          ? { discountType: FlightCodeType.Credit }
          : specialCode && { code: specialCode, discountType: FlightCodeType.PromoCode })
      }),
    keepData: true
  });

  const {
    value: paymentMethods,
    loading: loadingPaymentMethods,
    reload: reloadPaymentMethods,
    error: errorPaymentMethods
  } = useLoad({
    load: () =>
      getPaymentMethods().then((paymentMethodResponse) => [
        ...paymentMethodResponse.achs,
        ...paymentMethodResponse.cards
      ])
  });

  React.useEffect(() => {
    if (errorCharterTripDetail) {
      setCheckoutStatus(CheckoutStatus.UnknownError);
    }
  }, [errorCharterTripDetail]);

  React.useEffect(() => {
    if (errorCharterTripPrice && !charterTripPrice) {
      setCheckoutStatus(CheckoutStatus.UnknownError);
    }
  }, [errorCharterTripPrice]);

  React.useEffect(() => {
    if (!loadingCharterTripPrice) {
      if (errorCharterTripPrice) {
        setSpecialCode('');
      } else {
        reloadCharterTripPrice();
      }
    }
  }, [payCarbonOffset]);

  React.useEffect(() => {
    if (!loadingCharterTripPrice) {
      reloadCharterTripPrice();
    }
  }, [specialCode, useCredits]);

  const submitFlightBooking = (values) => {
    const selectedPaymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === values.selectedPaymentMethodId
    );

    if (selectedPaymentMethod.type === PaymentMethodType.Card) {
      return submitPayment({
        productId: charterTripDetail.id,
        typeOfProduct: TypeOfProduct.JetCharter,
        payCarbonOffset,
        paymentMethodId: selectedPaymentMethod.id,
        ...(useCredits
          ? { discountType: FlightCodeType.Credit }
          : specialCode && !errorCharterTripPrice && { code: specialCode, discountType: FlightCodeType.PromoCode })
      })
        .then((response) => setCheckoutStatus(CheckoutStatus.PaymentSucceeded))
        .catch((error) => setCheckoutStatus(CheckoutStatus.PaymentFailed));
    } else {
      // ACH
      return payWithACH({
        bankAccountId: selectedPaymentMethod.id,
        productId: charterTripDetail.id,
        typeOfProduct: TypeOfProduct.JetCharter,
        payCarbonOffset,
        ...(useCredits
          ? { discountType: FlightCodeType.Credit }
          : specialCode && !errorCharterTripPrice && { code: specialCode, discountType: FlightCodeType.PromoCode })
      })
        .then((response) => setCheckoutStatus(CheckoutStatus.PaymentSucceeded))
        .catch((error) => setCheckoutStatus(CheckoutStatus.PaymentFailed));
    }
  };

  return loadingCharterTripDetail ? (
    <Loading visible={loadingCharterTripDetail} center className="mt-40 w-full">
      <Loading.Indicator size={120} borderWidth={2} />
    </Loading>
  ) : (
    <React.Fragment>
      <div className="bg-white pt-28 lg:w-1/3 lg:flex lg:justify-end">
        <div className="w-[320px] px-6 pb-24 mx-auto sm:w-[430px] lg:px-10 lg:mx-0">
          <Typography is="div" type="flamingo" color="black" className="pb-10">
            <FormattedMessage id={translations.pages.checkout.flightInfoSection.title} />
          </Typography>

          {charterTripDetail.jetCharterLegs.length > 1 ? (
            <div className="flex flex-col items-start pb-12">
              {charterTripDetail.jetCharterLegs.map((jetCharterLeg, index) => (
                <Accordion
                  key={jetCharterLeg.flightId}
                  title={intl.formatMessage(
                    {
                      id: translations.pages.checkout.flightInfoSection.accordionTitle
                    },
                    { number: index + 1 }
                  )}
                  defaultOpen={index === 0}
                >
                  <FlightInfoSection
                    sourceAirport={jetCharterLeg.sourceAirport}
                    destinationAirport={jetCharterLeg.destinationAirport}
                    departureStartDate={getScheduleDeparture(jetCharterLeg.schedule)}
                    className="pt-6 pb-8"
                  />
                </Accordion>
              ))}
            </div>
          ) : (
            <FlightInfoSection
              sourceAirport={charterTripDetail.jetCharterLegs[0].sourceAirport}
              destinationAirport={charterTripDetail.jetCharterLegs[0].destinationAirport}
              departureStartDate={getScheduleDeparture(charterTripDetail.jetCharterLegs[0].schedule)}
              className="pt-10 pb-[100px]"
            />
          )}

          {loadingCharterTripPrice ? (
            <Loading visible={loadingCharterTripPrice} center className="h-8">
              <Loading.Indicator size={32} borderWidth={2} color="#000000" />
            </Loading>
          ) : (
            <React.Fragment>
              <FlightQuoteSection
                payCarbonOffset={payCarbonOffset}
                price={charterTripPrice.price}
                carbonOffset={charterTripPrice.carbonOffset}
                subtotal={charterTripPrice.subtotal}
                fetTax={charterTripPrice.fetTax}
                total={charterTripPrice.total}
                discountPrice={charterTripPrice.discountType ? charterTripPrice.discountPrice : null}
                removeDiscount={() => {
                  setSpecialCode('');
                  setUseCredits(false);
                }}
                titles={
                  charterTripPrice.discountType
                    ? {
                        discount: intl.formatMessage({
                          id:
                            translations.pages.checkout.flightQuoteSection[
                              charterTripPrice.discountType === FlightCodeType.PromoCode
                                ? 'codeDiscount'
                                : 'creditsDiscount'
                            ]
                        })
                      }
                    : { discount: null }
                }
              />
              {charterTripPrice.discountType ? (
                charterTripPrice.discountType === FlightCodeType.PromoCode && (
                  <Typography is="div" type="hummingbird" color="darkerGray" className="pt-5 flex">
                    <Icon type="check" className="mr-2" />
                    <FormattedMessage id={translations.pages.checkout.specialCodeSection.promoCodeAdded} />
                  </Typography>
                )
              ) : (
                <SpecialCodeSection specialCode={specialCode} setSpecialCode={setSpecialCode} className="pt-8" />
              )}

              {errorCharterTripPrice &&
                (!isValidError(errorCharterTripPrice) ? (
                  <UnknownErrorInfoBar />
                ) : (
                  <SubmitError error={errorCharterTripPrice} color="black" className="pt-8" />
                ))}

              {charterTripPrice.discountType ? (
                charterTripPrice.discountType === FlightCodeType.Credit && (
                  <Typography is="div" type="hummingbird" color="darkerGray" className="pt-5 flex">
                    <Icon type="check" className="mr-2" />
                    <FormattedMessage id={translations.pages.checkout.creditsSection.creditsAdded} />
                  </Typography>
                )
              ) : (
                <CreditsSection className="pt-12" onUseCreditsClick={() => setUseCredits(true)} />
              )}
            </React.Fragment>
          )}
        </div>
      </div>

      {errorPaymentMethods ? (
        <UnknownErrorInfoBar />
      ) : (
        <div className="lg:w-2/3 pt-28">
          <div className="w-[320px] px-6 pb-24 mx-auto sm:w-full lg:px-0 xl:w-[1010px] xl:mx-0">
            <div className="sm:w-[450px] sm:mx-auto">
              <FlightCheckoutForm
                payCarbonOffset={payCarbonOffset}
                setPayCarbonOffset={setPayCarbonOffset}
                carbonOffset={charterTripPrice && charterTripPrice.carbonOffset}
                loadingPrice={loadingCharterTripPrice}
                setCheckoutStatus={setCheckoutStatus}
                loadingPaymentMethods={loadingPaymentMethods}
                paymentMethods={paymentMethods}
                reloadPaymentMethods={reloadPaymentMethods}
                onSubmit={submitFlightBooking}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default JetCharterCheckoutView;
