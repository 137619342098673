import React from 'react';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';

import ErrorView from '@/components/ErrorView';

const UnknownErrorView = () => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <ErrorView
      title={intl.formatMessage({ id: translations.application.common.unknownError.title })}
      subtitle={intl.formatMessage({ id: translations.application.common.unknownError.subtitle })}
      tryAgain={() => history.go(0)}
    />
  );
};

export default UnknownErrorView;
