export enum ResultScope {
  Success = 'success',
  Error = 'error'
}

export enum SuccessResultType {
  CharterBookingRequestHandled = 'charter-booking-request-handled'
}

export enum ErrorResultType {
  Unknown = 'unknown',
  CharterBookingRequestFail = 'charter-booking-request-fail'
}
