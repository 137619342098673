import React from 'react';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';

import { LegalPageType } from '@/constants/legal-page-type';

import Typography from '@flyblack/common/components/Typography';

import NavColumn from './NavColumn';

interface Props {
  type: LegalPageType;
}

const LegalPage: React.FC<Props> = ({ type }) => {
  const [document, { state, error }] = usePrismicDocumentByUID('legal-page', type);
  return (
    <div className="flex flex-col sm:flex-row w-full p-6 gap-6 md:justify-center overflow-y-auto relative">
      <NavColumn />

      <div className="flex flex-col w-full max-w-[540px] text-justify">
        {document && (
          <PrismicRichText
            field={document.data.body as any}
            externalLinkComponent={(data) => (
              <a className="cursor-pointer underline" target="_blank" rel="noopener noreferrer" href={data.href}>
                {data.children}
              </a>
            )}
            components={{
              heading1: ({ children }) => (
                <Typography is="span" type="swan" weight="bold" className="mt-8 mb-2">
                  {children}
                </Typography>
              ),
              heading2: ({ children }) => (
                <Typography is="span" type="swan" weight="bold" className="mt-6 mb-2">
                  {children}
                </Typography>
              ),
              heading3: ({ children }) => (
                <Typography is="span" type="swan" weight="bold" className="mt-4 mb-2">
                  {children}
                </Typography>
              ),
              listItem: ({ children }) => (
                <Typography is="li" type="hummingbird" className="mb-2 list-disc list-inside ml-4">
                  {children}
                </Typography>
              ),
              oListItem: ({ children }) => (
                <Typography is="li" type="hummingbird" className="mb-2 list-decimal list-inside ml-4">
                  {children}
                </Typography>
              ),
              paragraph: ({ children }) => (
                <Typography is="span" type="hummingbird" className="mb-4">
                  {children}
                </Typography>
              )
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LegalPage;
