import React from 'react';

import Typography from '@flyblack/common/components/Typography';

export interface Props {
  topText: string;
  bottomText: string;
}

const DetailBarItem: React.FC<Props> = ({ topText, bottomText }) => {
  return (
    <div className="w-1/3 flex flex-col items-center even:border-solid even:border-r even:border-l  border-white border-opacity-10">
      <Typography is="span" type="hummingbird" className="text-white text-opacity-[64%] font-light">
        {topText}
      </Typography>

      <Typography is="span" type="halcyon">
        {bottomText}
      </Typography>
    </div>
  );
};

export default DetailBarItem;
