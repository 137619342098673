import React from 'react';
import SendBird from 'sendbird';
import SendBirdDesk from 'sendbird-desk';

import { ConciergeUserResponse } from '@/domains/me';

import { getConciergeCredentials } from '@/services/api/me';

interface SendBirdState {
  data: {
    chatUser;
    deskUser;
  };
  loading: boolean;
  error;
  sendBird: SendBird.SendBirdInstance;
}

export const SendBirdContext = React.createContext<SendBirdState>({} as any);

interface Props {
  appId: string;
  children?: any;
}

interface State extends Readonly<SendBirdState> {}

export const SendBirdConsumer = SendBirdContext.Consumer;

const initialState: State = {
  data: null,
  loading: false,
  error: null,
  sendBird: null
};

const reducer = (state: State, action): State => {
  switch (action.type) {
    case 'setData':
      return { ...state, data: action.payload, error: null, loading: false };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setError':
      return { ...state, error: action.payload, loading: false };
    case 'setSendBird':
      return { ...state, sendBird: action.payload };
    case 'reset':
      return { ...initialState };
    default:
      return { ...state };
  }
};

export const SendBirdProvider = ({ appId, children }: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const connect = (userId: string, accessToken: string) => {
    dispatch({ type: 'setLoading', payload: true });
    try {
      state.sendBird.connect(userId, accessToken, (chatUser, error) => {
        if (error) {
          return dispatch({ type: 'setError', payload: error });
        }

        SendBirdDesk.init(SendBird);
        SendBirdDesk.authenticate(userId, accessToken, (deskUser, error) => {
          if (error) {
            return dispatch({ type: 'setError', payload: error });
          }

          dispatch({ type: 'setData', payload: { chatUser, deskUser } });
        });
      });
    } catch (error) {
      dispatch({ type: 'setError', payload: error });
    }
  };

  const disconnect = () => {
    if (state.sendBird) {
      state.sendBird.disconnect(() => {
        dispatch({ type: 'reset' });
      });
    }
  };

  React.useEffect(() => {
    if (appId) {
      dispatch({ type: 'setSendBird', payload: new SendBird({ appId }) });
    }

    return disconnect;
  }, []);

  React.useEffect(() => {
    if (state.sendBird) {
      getConciergeCredentials()
        .then(({ error, id, token }: ConciergeUserResponse) => {
          if (error) {
            dispatch({ type: 'setError', payload: 'sendbirdError' });
          } else {
            connect(id, token);
          }
        })
        .catch((error) => dispatch({ type: 'setError', payload: error }));
    }
  }, [state.sendBird]);

  return <SendBirdContext.Provider value={state}>{children}</SendBirdContext.Provider>;
};

export default {
  SendBirdProvider,
  SendBirdConsumer,
  SendBirdContext
};
