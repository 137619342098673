import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getCredits } from '@/services/api/me';

import useLoad from '@flyblack/common/hooks/useLoad';
import Typography from '@flyblack/common/components/Typography';
import Button from '@flyblack/common/components/Button';
import Loading from '@flyblack/common/components/Loading';
import SectionTitle from '@/components/SectionTitle';

interface Props {
  onUseCreditsClick: () => any;
  className?: string;
}

const CreditsSection = ({ onUseCreditsClick, className }: Props) => {
  const { value: clientCreditResponse, loading } = useLoad({ load: getCredits });

  return loading ? (
    <Loading visible={loading} center className="mt-40 w-full">
      <Loading.Indicator size={120} borderWidth={2} />
    </Loading>
  ) : (
    clientCreditResponse && clientCreditResponse.credit && clientCreditResponse.credit.value > 0 && (
      <div className={className}>
        <SectionTitle className="pb-3 text-black">
          <FormattedMessage id={translations.pages.checkout.creditsSection.title} />
        </SectionTitle>

        <div className="flex justify-between items-center">
          <div className="flex flex-col justify-center">
            <Typography is="div" type="halcyon" color="black">
              {clientCreditResponse.credit.value}
            </Typography>
            <Typography is="div" type="halcyon" color="darkerGray">
              <FormattedMessage
                id={translations.pages.checkout.creditsSection.creditsWorth}
                values={{ value: clientCreditResponse.credit.value, symbol: clientCreditResponse.credit.symbol }}
              />
            </Typography>
          </div>

          <Button appearance="black" className="w-[140px]" onClick={onUseCreditsClick} type="submit" fat>
            <Typography is="span" type="halcyon">
              <FormattedMessage id={translations.inputs.buttons.applyCredits} />
            </Typography>
          </Button>
        </div>
      </div>
    )
  );
};

export default CreditsSection;
