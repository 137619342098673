import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { CheckoutStatus, FlightCodeType, PaymentMethodType, TypeOfProduct } from '@flyblack/common/domains';
import { getPaymentMethods, payWithACH } from '@/services/api/payment';
import { getShuttle, getShuttlePrice } from '@/services/api/shuttle';
import { getScheduleDeparture } from '@flyblack/common/util';

import { useStripePayment } from '@/hooks/useStripePayment';

import useLoad from '@flyblack/common/hooks/useLoad';
import Loading from '@flyblack/common/components/Loading';
import Typography from '@flyblack/common/components/Typography';
import Icon from '@flyblack/common/components/Icon';
import { isValidError, SubmitError } from '@flyblack/common/components/Error';

import FlightInfoSection from '@/components/Checkout/FlightInfoSection';
import FlightQuoteSection from '@/components/Checkout/FlightQuoteSection';
import FlightCheckoutForm from '@/components/Checkout/FlightCheckoutForm';
import SpecialCodeSection from '@/components/Checkout/SpecialCodeSection';
import CreditsSection from '@/components/Checkout/CreditsSection';
import UnknownErrorInfoBar from '@/components/UnknownErrorInfoBar';

interface Props {
  jetShuttleId: number;
  seats: number;
  setCheckoutStatus: React.Dispatch<React.SetStateAction<CheckoutStatus>>;
}

const JetShuttleCheckoutView = ({ jetShuttleId, seats, setCheckoutStatus }: Props) => {
  const intl = useIntl();

  const { submitPayment } = useStripePayment();

  const [payCarbonOffset, setPayCarbonOffset] = React.useState(true);
  const [specialCode, setSpecialCode] = React.useState('');
  const [useCredits, setUseCredits] = React.useState(false);

  const { value: jetShuttleDetail, loading: loadingJetShuttleDetail, error: errorJetShuttleDetail } = useLoad({
    load: getShuttle,
    id: jetShuttleId
  });

  const {
    value: jetShuttlePrice,
    loading: loadingJetShuttlePrice,
    reload: reloadJetShuttlePrice,
    error: errorJetShuttlePrice
  } = useLoad({
    load: () =>
      getShuttlePrice(jetShuttleId, seats, {
        carbonOffset: payCarbonOffset ? 'INCLUDED' : 'NOT_INCLUDED',
        ...(useCredits
          ? { discountType: FlightCodeType.Credit }
          : specialCode && { code: specialCode, discountType: FlightCodeType.PromoCode })
      }),
    keepData: true
  });

  const {
    value: paymentMethods,
    loading: loadingPaymentMethods,
    reload: reloadPaymentMethods,
    error: errorPaymentMethods
  } = useLoad({
    load: () =>
      getPaymentMethods().then((paymentMethodResponse) => [
        ...paymentMethodResponse.achs,
        ...paymentMethodResponse.cards
      ])
  });

  React.useEffect(() => {
    if (errorJetShuttleDetail) {
      setCheckoutStatus(CheckoutStatus.UnknownError);
    }
  }, [errorJetShuttleDetail]);

  React.useEffect(() => {
    if (errorJetShuttlePrice && !jetShuttlePrice) {
      setCheckoutStatus(CheckoutStatus.UnknownError);
    }
  }, [errorJetShuttlePrice]);

  React.useEffect(() => {
    if (!loadingJetShuttlePrice) {
      if (errorJetShuttlePrice) {
        setSpecialCode('');
      } else {
        reloadJetShuttlePrice();
      }
    }
  }, [payCarbonOffset]);

  React.useEffect(() => {
    if (!loadingJetShuttlePrice) {
      reloadJetShuttlePrice();
    }
  }, [specialCode, useCredits]);

  const handleSubmitError = (error) => {
    error.isAxiosError && error.response && error.response.status === 422
      ? setCheckoutStatus(CheckoutStatus.BookingNotAllowed)
      : setCheckoutStatus(CheckoutStatus.PaymentFailed);
  };

  const submitFlightBooking = (values) => {
    const selectedPaymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === values.selectedPaymentMethodId
    );

    if (selectedPaymentMethod.type === PaymentMethodType.Card) {
      return submitPayment({
        productId: jetShuttleDetail.id,
        typeOfProduct: TypeOfProduct.JetShuttle,
        payCarbonOffset,
        seats: jetShuttlePrice.purchasedSeats,
        paymentMethodId: selectedPaymentMethod.id,
        ...(useCredits
          ? { discountType: FlightCodeType.Credit }
          : specialCode && !errorJetShuttlePrice && { code: specialCode, discountType: FlightCodeType.PromoCode })
      })
        .then((response) => setCheckoutStatus(CheckoutStatus.PaymentSucceeded))
        .catch(handleSubmitError);
    } else {
      // ACH
      return payWithACH({
        bankAccountId: selectedPaymentMethod.id,
        productId: jetShuttleDetail.id,
        typeOfProduct: TypeOfProduct.JetShuttle,
        payCarbonOffset,
        seats: jetShuttlePrice.purchasedSeats,
        ...(useCredits
          ? { discountType: FlightCodeType.Credit }
          : specialCode && !errorJetShuttlePrice && { code: specialCode, discountType: FlightCodeType.PromoCode })
      })
        .then((response) => setCheckoutStatus(CheckoutStatus.PaymentSucceeded))
        .catch(handleSubmitError);
    }
  };

  return loadingJetShuttleDetail ? (
    <Loading visible={loadingJetShuttleDetail} center className="mt-40 w-full">
      <Loading.Indicator size={120} borderWidth={2} />
    </Loading>
  ) : (
    <React.Fragment>
      <div className="bg-white pt-28 lg:w-1/3 lg:flex lg:justify-end">
        <div className="w-[320px] px-6 pb-24 mx-auto sm:w-[430px] lg:px-10 lg:mx-0">
          <Typography is="div" type="flamingo" color="black" className="pb-10">
            <FormattedMessage id={translations.pages.checkout.flightInfoSection.title} />
          </Typography>

          <FlightInfoSection
            sourceAirport={jetShuttleDetail.sourceAirport}
            destinationAirport={jetShuttleDetail.destinationAirport}
            departureStartDate={getScheduleDeparture(jetShuttleDetail.schedule)}
            className="pt-10 pb-[100px]"
          />

          {loadingJetShuttlePrice ? (
            <Loading visible={loadingJetShuttlePrice} center className="h-8">
              <Loading.Indicator size={32} borderWidth={2} color="#000000" />
            </Loading>
          ) : (
            <React.Fragment>
              <FlightQuoteSection
                payCarbonOffset={payCarbonOffset}
                price={jetShuttlePrice.price}
                carbonOffset={jetShuttlePrice.carbonOffset}
                subtotal={jetShuttlePrice.subtotal}
                fetTax={jetShuttlePrice.fetTax}
                total={jetShuttlePrice.total}
                discountPrice={jetShuttlePrice.discountType ? jetShuttlePrice.discountPrice : null}
                removeDiscount={() => {
                  setSpecialCode('');
                  setUseCredits(false);
                }}
                titles={{
                  discount: jetShuttlePrice.discountType
                    ? intl.formatMessage({
                        id:
                          translations.pages.checkout.flightQuoteSection[
                            jetShuttlePrice.discountType === FlightCodeType.PromoCode
                              ? 'codeDiscount'
                              : 'creditsDiscount'
                          ]
                      })
                    : null,
                  price: intl.formatMessage(
                    { id: translations.pages.checkout.flightQuoteSection.fullFareForSeats },
                    { seats: jetShuttlePrice.purchasedSeats }
                  )
                }}
              />

              {jetShuttlePrice.discountType ? (
                jetShuttlePrice.discountType === FlightCodeType.PromoCode && (
                  <Typography is="div" type="hummingbird" color="darkerGray" className="pt-5 flex">
                    <Icon type="check" className="mr-2" />
                    <FormattedMessage id={translations.pages.checkout.specialCodeSection.promoCodeAdded} />
                  </Typography>
                )
              ) : (
                <SpecialCodeSection specialCode={specialCode} setSpecialCode={setSpecialCode} className="pt-8" />
              )}

              {errorJetShuttlePrice &&
                (!isValidError(errorJetShuttlePrice) ? (
                  <UnknownErrorInfoBar />
                ) : (
                  <SubmitError error={errorJetShuttlePrice} color="black" className="pt-8" />
                ))}

              {jetShuttlePrice.discountType ? (
                jetShuttlePrice.discountType === FlightCodeType.Credit && (
                  <Typography is="div" type="hummingbird" color="darkerGray" className="pt-5 flex">
                    <Icon type="check" className="mr-2" />
                    <FormattedMessage id={translations.pages.checkout.creditsSection.creditsAdded} />
                  </Typography>
                )
              ) : (
                <CreditsSection className="pt-12" onUseCreditsClick={() => setUseCredits(true)} />
              )}
            </React.Fragment>
          )}
        </div>
      </div>

      {errorPaymentMethods ? (
        <UnknownErrorInfoBar />
      ) : (
        <div className="lg:w-2/3 pt-28">
          <div className="w-[320px] px-6 pb-24 mx-auto sm:w-full lg:px-0 xl:w-[1010px] xl:mx-0">
            <div className="sm:w-[450px] sm:mx-auto">
              <FlightCheckoutForm
                payCarbonOffset={payCarbonOffset}
                setPayCarbonOffset={setPayCarbonOffset}
                carbonOffset={jetShuttlePrice && jetShuttlePrice.carbonOffset}
                loadingPrice={loadingJetShuttlePrice}
                setCheckoutStatus={setCheckoutStatus}
                loadingPaymentMethods={loadingPaymentMethods}
                paymentMethods={paymentMethods}
                reloadPaymentMethods={reloadPaymentMethods}
                onSubmit={submitFlightBooking}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default JetShuttleCheckoutView;
