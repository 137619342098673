import React from 'react';

import { MembershipStatus } from '@/domains/membership';

import { SessionContext } from '@/components/Context/Session';

import Apply from './Apply';
import Detail from './Detail';
import Pending from './Pending';
import WaitingList from './WaitingList';
import AwaitingPayment from './AwaitingPayment';

const membershipStatusToContentMap: Record<MembershipStatus, React.FC> = {
  [MembershipStatus.Active]: Detail,
  [MembershipStatus.ActiveAndRenewable]: Detail,
  [MembershipStatus.Expired]: Detail,
  [MembershipStatus.Pending]: Pending,
  [MembershipStatus.WaitingList]: WaitingList,
  [MembershipStatus.AwaitingPayment]: AwaitingPayment
};

const Membership: React.FC = () => {
  const { me, loading } = React.useContext(SessionContext);

  const Content = me.membership ? membershipStatusToContentMap[me.membership.status] : Apply;

  return !loading && <Content />;
};

export default Membership;
