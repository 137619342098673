import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { urls } from '@/constants';
import { translations } from '@/locale';

import UnderlinedButton from '@/components/UnderlinedButton';

export enum ConciergeTab {
  Opened = 'opened',
  Closed = 'closed'
}

export const SELECTED_CONCIERGE_TAB_QUERY_PARAM = 'tickets';

const ConciergeTabs = ({ selectedTab }) => {
  const intl = useIntl();
  const history = useHistory();

  const goToTab = (tab: ConciergeTab) =>
    history.push(
      queryString.stringifyUrl({
        url: urls.concierge.base,
        query: { [SELECTED_CONCIERGE_TAB_QUERY_PARAM]: tab }
      })
    );

  const handleOpenedClick = () => goToTab(ConciergeTab.Opened);

  const handleClosedClick = () => goToTab(ConciergeTab.Closed);

  return (
    <div className="flex justify-center gap-8 py-4 border-b border-solid border-flyblack-dark-gray">
      <UnderlinedButton
        active={selectedTab === ConciergeTab.Opened}
        onClick={handleOpenedClick}
        text={intl.formatMessage({ id: translations.pages.concierge.tab.opened })}
      />
      <UnderlinedButton
        active={selectedTab === ConciergeTab.Closed}
        onClick={handleClosedClick}
        text={intl.formatMessage({ id: translations.pages.concierge.tab.closed })}
      />
    </div>
  );
};

export default ConciergeTabs;
