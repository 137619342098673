import React from 'react';
import SendBirdDesk from 'sendbird-desk';
import { GroupChannel } from 'sendbird';

import OpenedTicketList from './OpenedTicketList';
import MessageList from '../MessageList';
import MessageInput from '../MessageInput';
import NewTicket from '../NewTicket';
import ConciergeTabs, { ConciergeTab } from '../ConciergeTabs';

const OpenedTicketContent = () => {
  const [openedTicketList, setOpenedTicketList] = React.useState<SendBirdDesk.Ticket[]>(null);
  const [selectedTicket, setSelectedTicket] = React.useState<SendBirdDesk.Ticket>(null);

  const selectTicket = (ticket: SendBirdDesk.Ticket) => {
    setSelectedTicket(ticket);
    (ticket.channel as GroupChannel).markAsRead();
  };

  const onMessageSent = () => {
    if (selectedTicket.status === SendBirdDesk.Ticket.Status.INITIALIZED) {
      const unassignedTicket = { ...selectedTicket, status: SendBirdDesk.Ticket.Status.UNASSIGNED };
      setOpenedTicketList((ticketList) =>
        ticketList.map((ticket) => {
          if (ticket.id === selectedTicket.id) {
            return unassignedTicket;
          }
          return ticket;
        })
      );
      setSelectedTicket(unassignedTicket);
    }
  };

  const onNewTicketCreate = (ticket: SendBirdDesk.Ticket) => {
    setOpenedTicketList((currentOpenedTicketList) => [ticket, ...currentOpenedTicketList]);
    setSelectedTicket(ticket);
  };

  return (
    <div className="flex h-full">
      <div className="flex flex-col border-r border-solid border-flyblack-dark-gray">
        <ConciergeTabs selectedTab={ConciergeTab.Opened} />

        <NewTicket
          disabled={
            !openedTicketList ||
            openedTicketList.length === 0 ||
            (selectedTicket && selectedTicket.status === SendBirdDesk.Ticket.Status.INITIALIZED)
          }
          onCreate={onNewTicketCreate}
        >
          {({ loadingCreateTicket, createTicket }) => (
            <OpenedTicketList
              ticketList={openedTicketList}
              setTicketList={setOpenedTicketList}
              selectedTicket={selectedTicket}
              selectTicket={selectTicket}
              loadingCreateTicket={loadingCreateTicket}
              createTicket={createTicket}
            />
          )}
        </NewTicket>
      </div>

      {selectedTicket && (
        <div className="flex flex-1 w-full flex-col">
          <MessageList ticket={selectedTicket} className="flex-auto">
            {({ appendMessage }) => (
              <MessageInput
                ticket={selectedTicket}
                appendMessage={appendMessage}
                onMessageSent={onMessageSent}
                className="h-[72px] my-8 flex-shrink-0"
              />
            )}
          </MessageList>
        </div>
      )}
    </div>
  );
};

export default OpenedTicketContent;
