import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { CheckoutStatus } from '@flyblack/common/domains';

import { translations } from '@/locale';

import LogoLink from '@flyblack/common/components/LogoLink';

import ErrorView from '@/components/ErrorView';
import UnknownErrorView from '@/components/UnknownErrorView';
import StripeElementsProvider from '@/components/StripeElementsProvider';
import FlightPaymentSuccessView from '@/components/Checkout/FlightPaymentSuccessView';

import JetDealCheckoutView from './JetDealCheckoutView';

interface Props {}

interface Params {
  id: string;
}

const JetDealCheckout: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const intl = useIntl();

  const [checkoutStatus, setCheckoutStatus] = React.useState(CheckoutStatus.Default);

  const resetCheckoutStatus = () => setCheckoutStatus(CheckoutStatus.Default);

  const checkoutViewByStatus: Record<Exclude<CheckoutStatus, CheckoutStatus.BookingNotAllowed>, React.FC> = {
    [CheckoutStatus.Default]: () => (
      <StripeElementsProvider>
        <JetDealCheckoutView emptyLegId={parseInt(match.params.id)} setCheckoutStatus={setCheckoutStatus} />
      </StripeElementsProvider>
    ),
    [CheckoutStatus.PaymentSucceeded]: () => <FlightPaymentSuccessView />,
    [CheckoutStatus.PaymentFailed]: () => (
      <ErrorView
        title={intl.formatMessage({ id: translations.application.common.paymentFail.title })}
        subtitle={intl.formatMessage({ id: translations.application.common.paymentFail.subtitle })}
        tryAgain={resetCheckoutStatus}
      />
    ),
    [CheckoutStatus.ACHSetupFailed]: () => (
      <ErrorView
        title={intl.formatMessage({ id: translations.application.common.achSetupFail.title })}
        subtitle={intl.formatMessage({ id: translations.application.common.achSetupFail.subtitle })}
        tryAgain={resetCheckoutStatus}
      />
    ),
    [CheckoutStatus.UnknownError]: () => <UnknownErrorView />
  };

  const CheckoutViewByStatus = checkoutViewByStatus[checkoutStatus];

  return (
    <div className={'flex flex-col relative text-white lg:flex-row'}>
      <div className="absolute top-7 left-10">
        <LogoLink className="h-4 w-auto" />
      </div>
      <CheckoutViewByStatus />
    </div>
  );
};

export default JetDealCheckout;
