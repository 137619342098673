import React from 'react';

import { LegOption } from '@/domains/charter';

import Checkbox from '@flyblack/common/components/Checkbox';
import Form from '@flyblack/common/components/Form';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  formId: string;
  legOptions: LegOption[];
  submitting: boolean;
}

const LegCheckboxes = ({ formId, legOptions, submitting }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      {legOptions.map((legOption) => (
        <Form.Field
          key={legOption.flightId}
          is={Checkbox}
          id={`${formId}-${legOption.flightId}`}
          name="jetCharterFlightIds"
          value={legOption.flightId}
          appearance="square"
          type="checkbox"
          readOnly={submitting}
          disabled={legOption.disabled}
        >
          <div className="flex flex-col">
            <Typography is="span" type="hummingbird" color="darkerGray">
              {legOption.name}
            </Typography>
            <Typography is="span" type="halcyon">
              {legOption.detail}
            </Typography>
          </div>
        </Form.Field>
      ))}
    </div>
  );
};

export default LegCheckboxes;
