import React from 'react';
import classnames from 'classnames';

import Spacer from '@flyblack/common/components/Spacer';

import { CharterLegListItem } from '@/domains/charter';
import TripSectionLeg from './TripSectionLeg';

interface Props {
  legs: CharterLegListItem[];
  className?: string;
}

const TripSection = ({ legs, className }: Props) => {
  return (
    <div className={classnames('flex flex-col max-w-[480px]', className)}>
      <Spacer xs={4} />
      {legs.map((leg, index) => (
        <TripSectionLeg
          key={index}
          sourceAirport={leg.source}
          destinationAirport={leg.destination}
          date={leg.date}
          durationInMinutes={leg.durationInMinutes}
        />
      ))}
    </div>
  );
};

export default TripSection;
