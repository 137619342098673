import { authorizedApi } from '../network/authorized-api';

import { CharterAirport, Page } from '@flyblack/common/domains';
import { CharterInquiry, CharterLeg, CharterQuoteListItem } from '@/domains/charter';
import { api } from '../network/api';

export const searchCharters = (passengers: number, legs: CharterLeg[]) =>
  authorizedApi.post<Page<CharterQuoteListItem>>(`/v1/jet-charters/search`, { paxNum: passengers, legs });

export const getCharterAirports = (text) =>
  api.get<Page<CharterAirport>>(`api/v1/jet-charters/airports/search`, { text });

export const inquireCharter = (data: CharterInquiry) =>
  authorizedApi.post<Page<CharterQuoteListItem>>(`/v1/jet-charters/inquire`, data);
