import React from 'react';

import { SessionContext } from '@/components/Context/Session';

import FriendReferral from './FriendReferral';
import SettingsSection from './SettingsSection';
import ProfileCardSection from './ProfileCardSection';
import FrequentLocations from './FrequentLocations';

const UserProfile: React.FC = () => {
  const { me, loading } = React.useContext(SessionContext);

  const [friendReferralOpen, setFriendReferralOpen] = React.useState(false);
  const [frequentLocationsOpen, setFrequentLocationsOpen] = React.useState(false);

  return (
    !loading && (
      <div className="bg-black min-h-full flex">
        <div className="flex flex-col w-full items-center">
          <FriendReferral
            open={friendReferralOpen}
            setOpen={setFriendReferralOpen}
            credits={me.credit ? me.credit.value : 0}
            promoCode={me.referralCode}
          />

          <FrequentLocations open={frequentLocationsOpen} setOpen={setFrequentLocationsOpen} />

          <ProfileCardSection data={me} />

          <SettingsSection
            setFriendReferralOpen={setFriendReferralOpen}
            setFrequentLocationsOpen={setFrequentLocationsOpen}
          />
        </div>
      </div>
    )
  );
};

export default UserProfile;
