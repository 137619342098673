import React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { ErrorResultType, STRING_LONG_MAX_LENGTH, SuccessResultType, urls } from '@/constants';
import { CharterLegListItem } from '@/domains/charter';
import { inquireCharter } from '@/services/api/charter';

import Icon from '@flyblack/common/components/Icon';
import Card from '@flyblack/common/components/Card';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';

import { SessionContext } from '@/components/Context/Session';
import { SubmitError } from '@flyblack/common/components/Error';
import { InstanceProps } from '@flyblack/common/components/Modal';
import RowContainer from '@flyblack/common/components/RowContainer';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

interface Props extends InstanceProps {
  charterData: {
    paxNum: string;
    id: string;
    aircraftCategoryId: string;
    legs: CharterLegListItem[];
    price: number;
  };
  onSuccess?: () => any;
}

const schema = yup.object().shape({
  firstName: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.firstName.label)
    .required(),
  lastName: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.lastName.label)
    .required(),
  email: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.email.label)
    .required(),
  phoneNumber: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.phoneNumber.label)
    .required()
});

const TextInputWithValidation = withValidation(TextInput);

const UserDataModal = ({ charterData, onSuccess, close }: Props) => {
  const intl = useIntl();
  const history = useHistory();

  const { me } = React.useContext(SessionContext);

  return (
    <Card bg="white" className="flex flex-col min-w-[320px] sm:min-w-[640px] max-w-[672px] w-full px-6">
      <Button type="button" className=" z-[1] h-[24px] w-[48px] cursor-pointer -mx-6 ml-auto" onClick={close}>
        <Icon type="close" className="text-black" />
      </Button>

      <Form
        schema={schema}
        initialValues={{
          firstName: me.firstName,
          lastName: me.lastName,
          email: me.email,
          phoneNumber: me.phoneNumber
        }}
        subscription={{ submitError: true, submitting: true, valid: true, dirty: true }}
        onSubmit={(values) =>
          inquireCharter({
            inquire: {
              ...values,
              seats: charterData.paxNum,
              quoteId: charterData.id
            },
            quote: {
              aircraftCategoryId: charterData.aircraftCategoryId,
              price: charterData.price,
              legs: charterData.legs
            }
          })
            .then(() => history.push(urls.result.getSuccessResult(SuccessResultType.CharterBookingRequestHandled)))
            .catch(() => history.push(urls.result.getErrorResult(ErrorResultType.CharterBookingRequestFail)))
        }
        className="flex flex-col w-full max-w-[480px] mx-auto"
      >
        {({ submitError, submitting, valid, dirty, form }) => (
          <React.Fragment>
            <div className="text-2xl text-black">
              <FormattedMessage id={translations.modals.userDataModal.title} />
            </div>

            <Spacer xs={5} />

            <RowContainer>
              <RowContainer.Item col={6}>
                <Form.Field
                  is={TextInputWithValidation}
                  appearance="light"
                  id="first-name"
                  name="firstName"
                  type="text"
                  leadingIcon="navigationAccount"
                  label={intl.formatMessage({ id: translations.inputs.firstName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.firstName.placeholder })}
                />
              </RowContainer.Item>

              <RowContainer.Item col={6}>
                <Form.Field
                  is={TextInputWithValidation}
                  appearance="light"
                  id="last-name"
                  name="lastName"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.lastName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.lastName.placeholder })}
                />
              </RowContainer.Item>
            </RowContainer>

            <div className="mt-5 max-w-[480px]">
              <Form.Field
                is={TextInputWithValidation}
                appearance="light"
                id="email"
                leadingIcon="email"
                name="email"
                type="email"
                className="w-full"
                disabled
                label={intl.formatMessage({ id: translations.inputs.email.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
              />
            </div>

            <div className="mt-5 max-w-[480px]">
              <Form.Field
                is={TextInputWithValidation}
                appearance="light"
                id="phone-number"
                name="phoneNumber"
                leadingIcon="phone"
                type="text"
                label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.phoneNumber.placeholder })}
              />
            </div>

            {submitError ? <SubmitError error={submitError} className="h-10" /> : <Spacer xs={5} />}

            <div className="flex justify-between gap-6">
              <Button
                fat
                type="submit"
                className="font-medium w-full max-w-[90px] sm:max-w-[163px]"
                appearance="lightGhost"
                onClick={close}
              >
                <FormattedMessage id={translations.inputs.buttons.cancel} />
              </Button>

              <Button fat type="submit" className="font-medium w-full" appearance="black" disabled={submitting}>
                <FormattedMessage id={translations.inputs.buttons.confirmContactInfo} />
              </Button>
            </div>

            <Spacer xs={3} sm={5} />
          </React.Fragment>
        )}
      </Form>
    </Card>
  );
};

export default UserDataModal;
