import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import { FlightCodeType, Pricing } from '@flyblack/common/domains';

import QuoteItem from '@/components/QuoteItem';
import SectionTitle from '@/components/SectionTitle';
import Icon from '@flyblack/common/components/Icon';

interface TitleOptions {
  price?: string;
  carbonOffset?: string;
  subtotal?: string;
  fetTax?: string;
  total?: string;
  discount: string;
}

interface Props {
  payCarbonOffset: boolean;
  price: Pricing;
  carbonOffset: Pricing;
  subtotal: Pricing;
  fetTax: Pricing;
  total: Pricing;
  discountPrice: Pricing;
  removeDiscount: () => any;
  titles: TitleOptions;
}

const FlightInfoSection: React.FC<Props> = ({
  payCarbonOffset,
  price,
  carbonOffset,
  subtotal,
  fetTax,
  total,
  discountPrice,
  removeDiscount,
  titles
}) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <SectionTitle className="pb-8 text-black">
        <FormattedMessage id={translations.pages.checkout.flightQuoteSection.title} />
      </SectionTitle>

      <QuoteItem
        className="pb-6"
        name={titles.price || intl.formatMessage({ id: translations.pages.checkout.flightQuoteSection.fullFlightFare })}
        value={intl.formatMessage(
          { id: translations.pages.checkout.flightQuoteSection.pricing },
          { value: price.value.toFixed(2), symbol: price.symbol }
        )}
      />

      {payCarbonOffset && (
        <QuoteItem
          className="pb-6"
          name={
            titles.carbonOffset ||
            intl.formatMessage({ id: translations.pages.checkout.flightQuoteSection.carbonOffset })
          }
          value={intl.formatMessage(
            { id: translations.pages.checkout.flightQuoteSection.pricing },
            { value: carbonOffset.value.toFixed(2), symbol: carbonOffset.symbol }
          )}
        />
      )}

      <QuoteItem
        className="pb-6"
        name={titles.subtotal || intl.formatMessage({ id: translations.pages.checkout.flightQuoteSection.subtotal })}
        value={intl.formatMessage(
          { id: translations.pages.checkout.flightQuoteSection.pricing },
          {
            value: subtotal.value.toFixed(2),
            symbol: subtotal.symbol
          }
        )}
      />

      {discountPrice && (
        <div className="relative">
          <QuoteItem
            className="pb-6"
            name={titles.discount}
            value={`-${intl.formatMessage(
              { id: translations.pages.membership.checkout.quoteSection.pricing },
              { value: discountPrice.value.toFixed(2), symbol: discountPrice.symbol }
            )}`}
          />

          <div className="absolute right-[-32px] top-0 w-[20px] hover:cursor-pointer" onClick={removeDiscount}>
            <Icon type="close" appearance="black" className="block" sizeClassName="text-[16px] leading-[20px]" />
          </div>
        </div>
      )}

      <QuoteItem
        className="pb-6"
        name={titles.fetTax || intl.formatMessage({ id: translations.pages.checkout.flightQuoteSection.fet })}
        value={intl.formatMessage(
          { id: translations.pages.checkout.flightQuoteSection.pricing },
          { value: fetTax.value.toFixed(2), symbol: fetTax.symbol }
        )}
      />

      <QuoteItem
        className="pb-6"
        name={titles.total || intl.formatMessage({ id: translations.pages.checkout.flightQuoteSection.total })}
        value={intl.formatMessage(
          { id: translations.pages.checkout.flightQuoteSection.pricing },
          {
            value: total.value.toFixed(2),
            symbol: total.symbol
          }
        )}
        fat
      />
    </React.Fragment>
  );
};

export default FlightInfoSection;
