import React from 'react';
import { Transition } from '@headlessui/react';

export interface Props {
  open: boolean;
  children: any;
}

const InfoBar = ({ open, children }: Props) => {
  return (
    <Transition.Root show={open} as={React.Fragment}>
      <div className="fixed inset-x-0 top-0 max-w-full flex z-30">
        <Transition.Child
          as={React.Fragment}
          enter="transform transition ease-in-out duration-300"
          enterFrom="-translate-y-full"
          enterTo="-translate-y-0"
          leave="transform transition ease-in-out duration-300"
          leaveFrom="-translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className="w-screen bg-white text-black shadow-xl">{children}</div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

export default InfoBar;
