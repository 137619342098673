import React from 'react';
import classnames from 'classnames';
import { AdminMessage, GroupChannel, PreviousMessageListQuery } from 'sendbird';
import SendBirdDesk from 'sendbird-desk';

import Loading from '@flyblack/common/components/Loading';
import ScrollTrigger from '@flyblack/common/components/ScrollTrigger';
import { SendBirdContext } from '@/components/Context/SendBird';

import MessageBubble, { SendBirdMessage } from './MessageBubble';

const MESSAGE_LIMIT = 10;
const MESSAGE_LIST_CHANNEL_HANDLER_ID = 'message-list-channel-handler';

interface Props {
  ticket: SendBirdDesk.Ticket;
  className?: string;
  children?: any;
}

const MessageList = ({ ticket, className, children }: Props) => {
  const { sendBird } = React.useContext(SendBirdContext);

  const ref = React.useRef<HTMLDivElement>();

  const [query, setQuery] = React.useState<PreviousMessageListQuery>();
  const [messageList, setMessageList] = React.useState<SendBirdMessage[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = React.useState(false);
  const [previousScrollHeight, setPreviousScrollHeight] = React.useState(0);

  const setInitialState = () => {
    setMessageList([]);
    setLoading(false);
    setShouldScrollToBottom(false);
    setPreviousScrollHeight(0);
  };

  const loadMessages = () => {
    // TODO: handle error
    if (query && !loading) {
      setLoading(true);
      query.load((res, err) => {
        setMessageList((currentMessageList) => [...res, ...currentMessageList]);
        setLoading(false);
      });
    }
  };

  const appendMessage = (message: SendBirdMessage) => {
    setShouldScrollToBottom(true);
    setMessageList((currentMessageList) => [...currentMessageList, message]);
  };

  const scrollToBottom = () => {
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  const isSendbirdDeskCustomMessage = (message: AdminMessage) =>
    message.customType === 'SENDBIRD_DESK_ADMIN_MESSAGE_CUSTOM_TYPE';

  React.useEffect(() => {
    setInitialState();

    const listQuery = (ticket.channel as GroupChannel).createPreviousMessageListQuery();
    listQuery.limit = MESSAGE_LIMIT;
    listQuery.reverse = false;
    setQuery(listQuery);
  }, [ticket]);

  React.useEffect(() => {
    setShouldScrollToBottom(true);
    loadMessages();
  }, [query]);

  React.useEffect(() => {
    if (messageList.length > 0) {
      if (shouldScrollToBottom) {
        scrollToBottom();
        setShouldScrollToBottom(false);
      } else {
        ref.current.scrollTop = ref.current.scrollHeight - previousScrollHeight;
      }
      setPreviousScrollHeight(ref.current.scrollHeight);
    }
  }, [messageList]);

  React.useEffect(() => {
    const channelHandler = new sendBird.ChannelHandler();

    channelHandler.onMessageReceived = (channel, message) => {
      if (message.isAdminMessage()) {
        // prevent displaying sendbird desk messages (assign to agent, change status, etc.)
        if (isSendbirdDeskCustomMessage(message)) {
          return;
        }
        // prevent displaying the welcome message twice
        const messageData = JSON.parse(message.data);
        if (messageData && messageData.type === 'NOTIFICATION_WELCOME') {
          return;
        }
      }

      const ticketChannel = ticket.channel as GroupChannel;
      if (channel.url === ticketChannel.url) {
        appendMessage(message);
        ticketChannel.markAsRead();
      }
    };

    sendBird.addChannelHandler(MESSAGE_LIST_CHANNEL_HANDLER_ID, channelHandler);

    return () => {
      sendBird.removeChannelHandler(MESSAGE_LIST_CHANNEL_HANDLER_ID);
    };
  }, [ticket]);

  return (
    <React.Fragment>
      <ScrollTrigger
        className={classnames('flex flex-col items-center w-full', className)}
        ref={ref}
        onTopReached={loadMessages}
      >
        <div className="max-w-[720px] w-full mt-auto">
          <Loading visible={loading} center className="h-16">
            <Loading.Indicator size={32} borderWidth={2} center />
          </Loading>
          {messageList.map(
            (messageObj) =>
              (!messageObj.isAdminMessage() || !isSendbirdDeskCustomMessage(messageObj)) && (
                <MessageBubble
                  key={messageObj.messageId}
                  message={messageObj}
                  showCreatedAt={true}
                  isMyMessage={
                    messageObj.isAdminMessage() ? false : sendBird.currentUser.userId === messageObj.sender.userId
                  }
                />
              )
          )}
        </div>
      </ScrollTrigger>
      {children && children({ appendMessage })}
    </React.Fragment>
  );
};

export default MessageList;
