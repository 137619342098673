import React from 'react';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { searchCharters } from '@/services/api/charter';
import { CharterQuoteListItem } from '@/domains/charter';

import NoSearchResults from '@/components/NoSearchResults';
import Spacer from '@flyblack/common/components/Spacer';
import Loading from '@flyblack/common/components/Loading';
import Typography from '@flyblack/common/components/Typography';
import InfiniteScroll from '@flyblack/common/components/InfiniteScroll';

import ResultsSearchRow from './ResultsSearchRow';
import ResultCardItem from './ResultCardItem';

const searchStringToFilters = (searchString: string) => {
  const query = queryString.parse(searchString);
  const legs = JSON.parse(query.legs as string);

  return { paxNum: parseInt(query.paxNum as string), legs };
};

export interface Props {}

const MultiLegResults: React.FC<Props> = () => {
  const history = useHistory();

  const searchString = useLocation().search;

  const [filters, setFilters] = React.useState(searchStringToFilters(searchString));

  React.useEffect(() => {
    setFilters(searchStringToFilters(searchString));
  }, [searchString]);

  const updateUrl = (newFilters) => {
    if (!isEqual(searchStringToFilters(searchString), newFilters)) {
      history.push(
        queryString.stringifyUrl({
          url: urls.jetCharters.multiLeg,
          query: {
            paxNum: newFilters.paxNum,
            legs: JSON.stringify(newFilters.legs)
          }
        })
      );
    }
  };

  return (
    <div className="bg-black min-h-full w-full">
      <div className="flex flex-col w-full mx-auto px-6 max-w-[866px]">
        <Typography is="div" type="flamingo" className="pt-10 pb-6 md:pt-14">
          <FormattedMessage id={translations.pages.jetCharters.results.multiLeg} />
        </Typography>

        <Spacer xs={0} sm={4} />

        {filters.legs.map((item, index) => (
          <ResultsSearchRow
            key={index}
            values={{ paxNum: filters.paxNum, ...item }}
            onChange={(name: string, value: any) => {
              if (name === 'paxNum') {
                updateUrl({ ...filters, [name]: value });
              } else {
                const newLegs = [...filters.legs];
                newLegs[index] = { ...newLegs[index], [name]: value };
                updateUrl({ ...filters, legs: newLegs });
              }
            }}
            multiLeg={index > 0}
          />
        ))}

        <InfiniteScroll
          key={JSON.stringify(filters)}
          source={() => searchCharters(filters.paxNum, [...filters.legs])}
          className="grid grid-cols-1 sm:grid-cols-2 gap-8 min-h-[600px] h-[600px]"
        >
          {({ data, loading }) =>
            !loading && data.length === 0 ? (
              <div className="flex w-full col-span-2">
                <NoSearchResults />
              </div>
            ) : (
              <React.Fragment>
                {data.map((data: CharterQuoteListItem) => (
                  <ResultCardItem paxNum={filters.paxNum} data={data} key={data.id} />
                ))}

                <Loading visible={loading} center className="flex w-full col-span-2">
                  <Loading.Indicator size={100} borderWidth={2} />
                </Loading>
              </React.Fragment>
            )
          }
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default MultiLegResults;
