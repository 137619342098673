import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { CharterType } from '@flyblack/common/domains';

import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

import UnderlinedButton from '@/components/UnderlinedButton';

import OneWaySearch from './OneWaySearch';
import RoundTripSearch from './RoundTripSearch';
import MultiLegSearch from './MultiLegSearch';

export interface Props {}

const charterFilterToSearchComponentMap: Record<CharterType, React.FC> = {
  [CharterType.OneWay]: OneWaySearch,
  [CharterType.RoundTrip]: RoundTripSearch,
  [CharterType.MultiLeg]: MultiLegSearch
};

const JetChartersSearch: React.FC<Props> = () => {
  const [selectedFilter, setSelectedFilter] = React.useState(CharterType.OneWay);

  const intl = useIntl();

  const SearchSection = charterFilterToSearchComponentMap[selectedFilter];

  return (
    <div className="bg-black min-h-full w-full">
      <div className="flex flex-col w-full mx-auto px-6 max-w-[866px]">
        <div className="flex flex-row flex-wrap items-center justify-between pt-10 pb-6 md:pt-14">
          <Typography is="div" type="flamingo">
            <FormattedMessage id={translations.pages.jetCharters.search.title} />
          </Typography>

          <div className="flex flex-row gap-8 py-4 sm:py-0">
            <UnderlinedButton
              active={selectedFilter === CharterType.OneWay}
              onClick={() => setSelectedFilter(CharterType.OneWay)}
              text={intl.formatMessage({ id: translations.pages.jetCharters.search.filters.ONE_WAY })}
            />

            <UnderlinedButton
              active={selectedFilter === CharterType.RoundTrip}
              onClick={() => setSelectedFilter(CharterType.RoundTrip)}
              text={intl.formatMessage({ id: translations.pages.jetCharters.search.filters.ROUND_TRIP })}
            />

            <UnderlinedButton
              active={selectedFilter === CharterType.MultiLeg}
              onClick={() => setSelectedFilter(CharterType.MultiLeg)}
              text={intl.formatMessage({ id: translations.pages.jetCharters.search.filters.MULTI_LEG })}
            />
          </div>
        </div>

        <Spacer xs={0} sm={4} />

        <SearchSection />
      </div>
    </div>
  );
};

export default JetChartersSearch;
