import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import * as yup from 'yup';

import { translations } from '@/locale';

import { createClientSecret } from '@/services/api/payment';

import { InstanceProps } from '@flyblack/common/components/Modal';
import { SubmitError } from '@flyblack/common/components/Error';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import Card from '@flyblack/common/components/Card';
import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';
import Spacer from '@flyblack/common/components/Spacer';
import Form from '@flyblack/common/components/Form';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import CountrySelect from '@flyblack/common/components/CountrySelect';

import SectionTitle from '@/components/SectionTitle';

import StripeElement from './StripeElement';
import { useCreditCardValidation } from './useCreditCardValidation';

const TextInputWithValidation = withValidation(TextInput);
const CountrySelectWithValidation = withValidation(CountrySelect);

const schema = yup.object({
  cardHolder: yup
    .string()
    .nullable()
    .notRequired(),
  country: yup
    .string()
    .label(translations.inputs.country.label)
    .required(),
  address: yup
    .string()
    .label(translations.inputs.address.label)
    .required(),
  city: yup
    .string()
    .label(translations.inputs.city.label)
    .required(),
  state: yup
    .string()
    .label(translations.inputs.state.label)
    .required(),
  zip: yup
    .string()
    .label(translations.inputs.zip.label)
    .required()
});

interface Props extends InstanceProps {
  onSuccess: () => any;
}

const AddCreditCardModal = ({ close, onSuccess }: Props) => {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();

  const { isCardComplete, handleCardFieldChange } = useCreditCardValidation();

  const onSubmit = ({ cardHolder, country, address, city, state, zip }) => {
    if (!stripe || !elements) {
      throw { message: intl.formatMessage({ id: translations.errors.api.credit_card_could_not_be_added }) };
    }

    return createClientSecret()
      .then(({ clientSecret }) =>
        stripe
          .confirmCardSetup(
            clientSecret,
            /* eslint-disable @typescript-eslint/camelcase */
            {
              payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                  ...(cardHolder && { name: cardHolder }),
                  ...(country && {
                    address: {
                      city: city || '',
                      country: country || '',
                      line1: address || '',
                      line2: '',
                      postal_code: zip || '',
                      state: state || ''
                    }
                  })
                }
              }
            }
            /* eslint-enable @typescript-eslint/camelcase */
          )
          .then((result) => {
            if (result.error) {
              throw result.error;
            }

            close();
            onSuccess();
          })
      )
      .catch(() => {
        throw { message: intl.formatMessage({ id: translations.errors.api.credit_card_could_not_be_added }) };
      });
  };

  return (
    <Card
      bg="white"
      className="rounded-none sm:rounded-md relative min-w-[320px] max-w-[542px] overflow-visible block text-black"
    >
      <Card.Row className="flex flex-row justify-end items-center">
        <Button type="button" appearance="white" className="z-[1] w-12 cursor-pointer" onClick={close}>
          <Icon type="close" className="text-flyblack-dark-gray" />
        </Button>
      </Card.Row>

      <Card.Row className="px-6 sm:px-14 md:px-24">
        <Card.Column padded={false} className="w-full">
          <Typography is="div" type="owl" className="pb-12">
            <FormattedMessage id={translations.modals.addCreditCardModal.title} />
          </Typography>

          <Form
            id="add-credit-card"
            schema={schema}
            subscription={{ submitError: true, submitting: true, values: true, hasValidationErrors: true, dirty: true }}
            onSubmit={onSubmit}
          >
            {({ submitError, submitting, hasValidationErrors }, formId) => (
              <React.Fragment>
                <SectionTitle className="text-black pb-4">
                  <FormattedMessage id={translations.modals.addCreditCardModal.cardInfo} />
                </SectionTitle>

                <Card.Row className="justify-between flex-wrap">
                  <StripeElement
                    is={CardNumberElement}
                    label={intl.formatMessage({ id: translations.inputs.cardNumber.label })}
                    className="w-full sm:w-[140px]"
                    onChange={handleCardFieldChange}
                  />

                  <StripeElement
                    is={CardExpiryElement}
                    label={intl.formatMessage({ id: translations.inputs.expirationDate.label })}
                    className="mt-3 sm:mt-0 w-[80px]"
                    onChange={handleCardFieldChange}
                  />
                  <StripeElement
                    is={CardCvcElement}
                    label={intl.formatMessage({ id: translations.inputs.cvc.label })}
                    className="mt-3 sm:mt-0 w-[80px]"
                    onChange={handleCardFieldChange}
                  />
                </Card.Row>

                <Spacer xs={2} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${formId}-card-holder`}
                  name="cardHolder"
                  type="text"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.cardholderName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.cardholderName.placeholder })}
                  inputClassName="w-full"
                />

                <Spacer xs={5} />

                <SectionTitle className="text-black pb-4">
                  <FormattedMessage id={translations.modals.addCreditCardModal.address} />
                </SectionTitle>

                <Form.Field
                  is={CountrySelectWithValidation}
                  id={`${formId}-country`}
                  name="country"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.country.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.country.placeholder })}
                  inputClassName="w-full"
                />

                <Spacer xs={2} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${formId}-address`}
                  name="address"
                  type="text"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.address.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.address.placeholder })}
                  inputClassName="w-full"
                />

                <Spacer xs={2} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${formId}-city`}
                  name="city"
                  type="text"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.city.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.city.placeholder })}
                  inputClassName="w-full"
                />

                <Spacer xs={2} />

                <Card.Row className="justify-between">
                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${formId}-state`}
                    name="state"
                    type="text"
                    appearance="light"
                    label={intl.formatMessage({ id: translations.inputs.state.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.state.placeholder })}
                    inputClassName="w-[110px] sm:w-[225px]"
                  />

                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${formId}-zip`}
                    name="zip"
                    type="text"
                    appearance="light"
                    label={intl.formatMessage({ id: translations.inputs.zip.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.zip.placeholder })}
                    inputClassName="w-[50px] sm:w-[100px]"
                  />
                </Card.Row>

                <Spacer xs={2} />

                {!!submitError && <SubmitError error={submitError} />}

                <Spacer xs={3} />

                <Card.Row className="justify-between">
                  <Button appearance="lightGhost" fat className="w-[100px] sm:w-[152px]" onClick={close}>
                    <FormattedMessage id={translations.inputs.buttons.cancel} />
                  </Button>

                  <Button
                    fat
                    form={formId}
                    type="submit"
                    appearance="black"
                    className="w-[100px] sm:w-[152px]"
                    loading={submitting}
                    disabled={!isCardComplete || !stripe || !elements || hasValidationErrors || submitting}
                  >
                    <FormattedMessage id={translations.inputs.buttons.addCard} />
                  </Button>
                </Card.Row>

                <Spacer xs={6} />
              </React.Fragment>
            )}
          </Form>
        </Card.Column>
      </Card.Row>
    </Card>
  );
};

export default AddCreditCardModal;
