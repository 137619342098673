import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { Card, CheckoutStatus, PaymentMethod, PaymentMethodType } from '@flyblack/common/domains';

import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';
import Modal from '@flyblack/common/components/Modal';
import Form from '@flyblack/common/components/Form';
import Toggle from '@flyblack/common/components/Toggle';
import Loading from '@flyblack/common/components/Loading';

import PaymentMethodListItem from '@/components/PaymentMethodListItem';
import SectionTitle from '@/components/SectionTitle';
import ACHSetup from '@/components/ACHSetup';
import AddCreditCardModal from '@/components/AddCreditCardModal';

interface Props {
  title: string;
  loadingPaymentMethods: boolean;
  paymentMethods: PaymentMethod[];
  reloadPaymentMethods: () => void;
  setCheckoutStatus: React.Dispatch<React.SetStateAction<CheckoutStatus>>;
  change: (name: string, value: any) => void;
}

const PaymentMethodSection: React.FC<Props> = ({
  title,
  loadingPaymentMethods,
  paymentMethods,
  reloadPaymentMethods,
  setCheckoutStatus,
  change
}) => {
  const onACHSetupFail = () => setCheckoutStatus(CheckoutStatus.ACHSetupFailed);

  return (
    <React.Fragment>
      <SectionTitle className="pb-8">{title}</SectionTitle>

      {loadingPaymentMethods ? (
        <Loading visible={loadingPaymentMethods} center className="h-8">
          <Loading.Indicator size={32} borderWidth={2} />
        </Loading>
      ) : (
        paymentMethods.map((paymentMethod: PaymentMethod) => (
          <Form.Field
            key={paymentMethod.id}
            is={Toggle}
            id={`payment-method-${paymentMethod.id}`}
            name="selectedPaymentMethodId"
            type="radio"
            value={paymentMethod.id}
            renderToggle={(checked) => (
              <PaymentMethodListItem
                type={paymentMethod.type}
                brand={paymentMethod.type === PaymentMethodType.Card ? (paymentMethod as Card).brand : null}
                last4={paymentMethod.last4}
                isDefault={checked}
              />
            )}
            onChange={() => {
              if (paymentMethod.type === PaymentMethodType.Card) {
                change('country', (paymentMethod as Card).address.country);
                change('city', (paymentMethod as Card).address.city);
                change('state', (paymentMethod as Card).address.state);
                change('zipCode', (paymentMethod as Card).address.zipCode);
              }
            }}
            block
            className="my-4"
            toggleContainerClass="w-full"
          />
        ))
      )}

      <div className="flex justify-end">
        <Modal modal={AddCreditCardModal} onSuccess={reloadPaymentMethods}>
          {({ open }) => (
            <Button appearance="transparent" onClick={open}>
              <Icon type="add" className="pr-4" />
              <FormattedMessage id={translations.application.common.addNewCardButton} />
            </Button>
          )}
        </Modal>
      </div>
      <div className="flex justify-end">
        <ACHSetup onSuccess={reloadPaymentMethods} onFail={onACHSetupFail}>
          {({ setupACHPayment }) => (
            <Button appearance="transparent" onClick={setupACHPayment}>
              <Icon type="add" className="pr-4" />
              <FormattedMessage id={translations.application.common.achSetupButton} />
            </Button>
          )}
        </ACHSetup>
      </div>
    </React.Fragment>
  );
};

export default PaymentMethodSection;
