import { NewPet, Pet } from '@flyblack/common/domains';

import { authorizedApi } from '../network/authorized-api';

export const createPet = (data: NewPet) => authorizedApi.post<Promise<number>>(`/v1/pets`, data);

export const updatePet = ({ id, ...petData }: Pet) => authorizedApi.put<Promise<number>>(`/v1/pets/${id}`, petData);

export const deletePet = (petId: number) => authorizedApi.delete<Promise<number>>(`/v1/pets/${petId}`);

export const deletePetFromFlight = (petId: number, jetCharterFlightId: number) =>
  authorizedApi.delete<Promise<number>>(`/v1/pets/${petId}/jet-charter-flight`, { jetCharterFlightId });
