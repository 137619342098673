import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { deletePet, deletePetFromFlight } from '@/services/api/pets';
import { Pet } from '@flyblack/common/domains';
import { LegOption } from '@/domains/charter';

import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

import PetForm from './PetForm';
import ItemMenu from './ItemMenu';

interface Props {
  index: number;
  tripId: number;
  pet: Pet;
  legOptions?: LegOption[];
  flightId?: number;
  onAction: () => any;
}

const PetItem: React.FC<Props> = ({ pet, index, onAction, tripId, legOptions, flightId }) => {
  const [editable, setEditable] = React.useState(false);

  return editable ? (
    <PetForm
      petId={pet.id}
      initialData={{
        breed: pet.breed,
        type: pet.type,
        ...(pet.jetCharterFlightIds && { jetCharterFlightIds: pet.jetCharterFlightIds })
      }}
      onCancel={() => setEditable(false)}
      onSuccess={onAction}
      tripId={tripId}
      legOptions={legOptions}
    />
  ) : (
    <div className="flex flex-row items-center w-full mb-6">
      <Icon type="navigationAccount" className="mr-6" />

      <div className="flex flex-col">
        <Typography is="span" type="hummingbird" color="lightGray">
          <FormattedMessage
            id={translations.pages.myTrips.detail.passengersSection.petItemTitle}
            values={{ petCount: index + 1 }}
          />
        </Typography>

        <Typography is="span" type="halcyon">
          {`${pet.type} - ${pet.breed}`}
        </Typography>
      </div>

      <ItemMenu
        type="pet"
        onDelete={() => deletePet(pet.id).then(onAction)}
        onDeleteFlight={
          legOptions &&
          legOptions.length > 1 &&
          flightId &&
          (() => deletePetFromFlight(pet.id, flightId).then(onAction))
        }
        onEdit={() => setEditable(true)}
      />
    </div>
  );
};

export default PetItem;
