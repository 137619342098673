import { format, isToday, isAfter, subDays, isThisYear } from 'date-fns';

enum DateFormats {
  ShortMonthDayAndYear = 'MMM d, yyyy',
  Time = 'h:mm a',
  ShortWeekDayAndTime = 'EEE, h:mm a',
  ShortMonthAndDay = 'MMM d'
}

export const isInTheLast7Days = (date: Date | number) => isAfter(date, subDays(new Date(), 7));

export const getDateString = (date: Date | number) => {
  if (date) {
    let dateFormat = DateFormats.ShortMonthDayAndYear;
    if (isToday(date)) {
      dateFormat = DateFormats.Time;
    } else if (isInTheLast7Days(date)) {
      dateFormat = DateFormats.ShortWeekDayAndTime;
    } else if (isThisYear(date)) {
      dateFormat = DateFormats.ShortMonthAndDay;
    }
    return format(date, dateFormat);
  }
  return '';
};
