import { FlightPrice, Page, TripDetailItem, TripListItem } from '@flyblack/common/domains';

import { authorizedApi } from '../network/authorized-api';

export const getMyTrips = (
  pageNumber: number,
  pageSize: number,
  options?: {
    [x: string]: any;
  }
) => authorizedApi.get<Page<TripListItem>>(`/v1/trips`, { pageNumber, pageSize, ...options });

export const getMyTripDetail = (id) => authorizedApi.get<TripDetailItem>(`/v1/trips/${id}`);

export const generateMyTripDetail = (id: number) => authorizedApi.post<{ imageUrl: string }>(`/v1/trips/${id}/share`);

export const getJetCharterTripPrice = (tripId: number, options?: { [x: string]: any }) =>
  authorizedApi.get<FlightPrice>(`/v1/trips/${tripId}/price/jet-charter`, options);
