import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { Card, CheckoutStatus, MembershipCodeType, PaymentMethodType, TypeOfProduct } from '@flyblack/common/domains';

import { translations } from '@/locale';

import { getPaymentMethods, payWithACH } from '@/services/api/payment';
import { getMembershipPrice } from '@/services/api/membership';

import { useStripePayment } from '@/hooks/useStripePayment';

import useLoad from '@flyblack/common/hooks/useLoad';
import Loading from '@flyblack/common/components/Loading';
import Typography from '@flyblack/common/components/Typography';
import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';
import { isValidError, SubmitError } from '@flyblack/common/components/Error';

import QuoteItem from '@/components/QuoteItem';
import BillingAddressSection from '@/components/Checkout/BillingAddressSection';
import TOSSection from '@/components/Checkout/TOSSection';
import PaymentMethodSection from '@/components/Checkout/PaymentMethodSection';
import SpecialCodeSection from '@/components/Checkout/SpecialCodeSection';
import UnknownErrorInfoBar from '@/components/UnknownErrorInfoBar';

const schema = yup.object({
  selectedPaymentMethodId: yup.string().required(),
  tos: yup
    .boolean()
    .oneOf([true], translations.validation.custom.acceptAgreement)
    .label(translations.inputs.termsAndConditions)
    .required()
});

interface Props {
  membershipId: number;
  setCheckoutStatus: React.Dispatch<React.SetStateAction<CheckoutStatus>>;
}

const MembershipCheckoutView = ({ membershipId, setCheckoutStatus }: Props) => {
  const intl = useIntl();

  const { submitPayment } = useStripePayment();

  const [specialCode, setSpecialCode] = React.useState('');

  const {
    value: membershipPrice,
    loading: loadingMembershipPrice,
    reload: reloadMembershipPrice,
    error: errorMembershipPrice
  } = useLoad({
    load: () => getMembershipPrice(specialCode),
    keepData: true
  });

  const {
    value: paymentMethods,
    loading: loadingPaymentMethods,
    reload: reloadPaymentMethods,
    error: errorPaymentMethods
  } = useLoad({
    load: () =>
      getPaymentMethods().then((paymentMethodResponse) => [
        ...paymentMethodResponse.achs,
        ...paymentMethodResponse.cards
      ])
  });

  const initialValues = {
    ...(paymentMethods &&
      paymentMethods.length && {
        selectedPaymentMethodId: paymentMethods[0].id,
        ...(paymentMethods[0].type === PaymentMethodType.Card && (paymentMethods[0] as Card).address)
      }),
    tos: false
  };

  React.useEffect(() => {
    if (errorMembershipPrice && !membershipPrice) {
      setCheckoutStatus(CheckoutStatus.UnknownError);
    }
  }, [errorMembershipPrice]);

  React.useEffect(() => {
    if (!loadingMembershipPrice) {
      reloadMembershipPrice();
    }
  }, [specialCode]);

  const submitMembership = (values) => {
    const selectedPaymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === values.selectedPaymentMethodId
    );

    if (selectedPaymentMethod.type === PaymentMethodType.Card) {
      return submitPayment({
        productId: membershipId,
        typeOfProduct: TypeOfProduct.Membership,
        paymentMethodId: selectedPaymentMethod.id,
        code: specialCode && !errorMembershipPrice ? specialCode : null
      })
        .then((response) => setCheckoutStatus(CheckoutStatus.PaymentSucceeded))
        .catch((error) => setCheckoutStatus(CheckoutStatus.PaymentFailed));
    } else {
      // ACH
      return payWithACH({
        bankAccountId: selectedPaymentMethod.id,
        productId: membershipId,
        typeOfProduct: TypeOfProduct.Membership,
        code: specialCode && !errorMembershipPrice ? specialCode : null
      })
        .then((response) => setCheckoutStatus(CheckoutStatus.PaymentSucceeded))
        .catch((error) => setCheckoutStatus(CheckoutStatus.PaymentFailed));
    }
  };

  return (
    <React.Fragment>
      <div className="bg-white pt-28 lg:w-1/3 lg:flex lg:justify-end">
        <div className="w-[320px] px-6 pb-24 mx-auto sm:w-[430px] lg:px-10 lg:mx-0">
          <Typography is="div" type="flamingo" color="black" className="flex items-center pb-10">
            <FormattedMessage id={translations.pages.membership.checkout.quoteSection.title} />
          </Typography>

          {loadingMembershipPrice ? (
            <Loading visible={loadingMembershipPrice} center className="h-8">
              <Loading.Indicator size={32} borderWidth={2} color="#000000" />
            </Loading>
          ) : (
            <React.Fragment>
              <QuoteItem
                className="pb-6"
                name={intl.formatMessage({ id: translations.pages.membership.checkout.quoteSection.subtotal })}
                value={intl.formatMessage(
                  { id: translations.pages.membership.checkout.quoteSection.pricing },
                  { value: membershipPrice.subTotal.value.toFixed(2), symbol: membershipPrice.subTotal.symbol }
                )}
              />

              {membershipPrice.codeType && membershipPrice.codeType === MembershipCodeType.PromoCode && (
                <div className="relative">
                  <QuoteItem
                    className="pb-6"
                    name={intl.formatMessage({ id: translations.pages.membership.checkout.quoteSection.codeDiscount })}
                    value={`-${intl.formatMessage(
                      { id: translations.pages.membership.checkout.quoteSection.pricing },
                      { value: membershipPrice.codePrice.value.toFixed(2), symbol: membershipPrice.codePrice.symbol }
                    )}`}
                  />

                  <div
                    className="absolute right-[-32px] top-0 w-[20px] hover:cursor-pointer"
                    onClick={() => setSpecialCode('')}
                  >
                    <Icon
                      type="close"
                      appearance="black"
                      className="block"
                      sizeClassName="text-[16px] leading-[20px]"
                    />
                  </div>
                </div>
              )}

              <QuoteItem
                className="pb-6"
                name={intl.formatMessage({ id: translations.pages.membership.checkout.quoteSection.total })}
                value={intl.formatMessage(
                  { id: translations.pages.membership.checkout.quoteSection.pricing },
                  { value: membershipPrice.total.value.toFixed(2), symbol: membershipPrice.total.symbol }
                )}
                fat
              />

              {membershipPrice.codeType ? (
                <Typography is="div" type="hummingbird" color="darkerGray" className="pt-5 flex">
                  <Icon type="check" className="mr-2" />
                  {membershipPrice.codeType === MembershipCodeType.PromoCode ? (
                    <FormattedMessage id={translations.pages.checkout.specialCodeSection.promoCodeAdded} />
                  ) : (
                    <FormattedMessage
                      id={translations.pages.checkout.specialCodeSection.referralCodeAdded}
                      values={{ creditValue: membershipPrice.codePrice.value }}
                    />
                  )}
                </Typography>
              ) : (
                <SpecialCodeSection specialCode={specialCode} setSpecialCode={setSpecialCode} className="pt-8" />
              )}

              {errorMembershipPrice &&
                (!isValidError(errorMembershipPrice) ? (
                  <UnknownErrorInfoBar />
                ) : (
                  <SubmitError error={errorMembershipPrice} color="black" className="pt-8" />
                ))}
            </React.Fragment>
          )}
        </div>
      </div>

      {errorPaymentMethods ? (
        <UnknownErrorInfoBar />
      ) : (
        <div className="lg:w-2/3 pt-28">
          <div className="w-[320px] px-6 pb-24 mx-auto sm:w-full lg:px-0 xl:w-[1010px] xl:mx-0">
            <div className="sm:w-[450px] sm:mx-auto">
              <Form
                id="checkout"
                schema={schema}
                subscription={{
                  errors: true,
                  submitting: true,
                  submitError: true,
                  dirty: true,
                  values: true,
                  valid: true
                }}
                initialValues={initialValues}
                onSubmit={submitMembership}
              >
                {({ submitError, submitting, values, valid, form, errors, dirty }, formId) => (
                  <React.Fragment>
                    <Typography is="div" type="flamingo" className="pb-4">
                      <FormattedMessage id={translations.pages.membership.checkout.paymentSection.title} />
                    </Typography>

                    <Typography is="div" type="halcyon" className="pb-14" color="lightGray">
                      <FormattedMessage id={translations.pages.membership.checkout.paymentSection.subTitle} />
                    </Typography>

                    <PaymentMethodSection
                      title={intl.formatMessage({
                        id: translations.pages.membership.checkout.paymentSection.paymentMethodsTitle
                      })}
                      loadingPaymentMethods={loadingPaymentMethods}
                      paymentMethods={paymentMethods}
                      reloadPaymentMethods={reloadPaymentMethods}
                      setCheckoutStatus={setCheckoutStatus}
                      change={form.change}
                    />

                    {paymentMethods &&
                      paymentMethods.find(
                        (paymentMethod) =>
                          paymentMethod.id === values.selectedPaymentMethodId &&
                          paymentMethod.type === PaymentMethodType.Card
                      ) && <BillingAddressSection formId={formId} submitting={submitting} />}

                    <Spacer xs={2} />

                    <TOSSection
                      formId={formId}
                      submitting={submitting}
                      values={values}
                      change={form.change}
                      paragraphs={translations.pages.membership.checkout.tosSection.paragraphs}
                    />

                    <Spacer xs={2} />

                    <Button
                      form={formId}
                      type="submit"
                      appearance="white"
                      loading={submitting}
                      disabled={!dirty || !valid}
                      className="w-full"
                    >
                      <FormattedMessage id={translations.pages.membership.checkout.confirmPaymentButton} />
                    </Button>
                  </React.Fragment>
                )}
              </Form>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MembershipCheckoutView;
