import React from 'react';
import classnames from 'classnames';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { Airport, FixedSchedule, FlexibleSchedule, ScheduleType } from '@flyblack/common/domains';
import { formatDuration, getScheduleArrival, getScheduleDeparture } from '@flyblack/common/util';

import Icon from '@flyblack/common/components/Icon';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  sourceAirport: Airport;
  destinationAirport: Airport;
  duration: number;
  schedule: FixedSchedule | FlexibleSchedule;
  className?: string;
}

const TripSection = ({ sourceAirport, destinationAirport, duration, schedule, className }: Props) => {
  const intl = useIntl();

  return (
    <div className={classnames('flex flex-col p-6 sm:px-0 w-full max-w-[480px]', className)}>
      <Spacer xs={2} sm={4} />

      <div className="flex flex-row justify-between items-center">
        <div className="text-2xl">{sourceAirport.displayCode}</div>

        <div className="h-[1px] flex justify-center items-center  bg-flyblack-gray w-full mx-6 relative">
          <Icon type="plane" className="p-1 bg-black" />
        </div>

        <div className="text-2xl">{destinationAirport.displayCode}</div>
      </div>

      <div className="flex flex-row justify-between">
        <Typography is="span" type="hummingbird" className="flex-1" color="lightGray">
          {sourceAirport.name}
        </Typography>

        <Typography is="span" type="hummingbird" className="flex-1 flex-grow-[0.5] text-center" color="lightGray">
          {formatDuration(duration)}
        </Typography>

        <Typography is="span" type="hummingbird" className="flex-1 text-right" color="lightGray">
          {destinationAirport.name}
        </Typography>
      </div>

      <Spacer xs={2} />

      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <Typography is="span" type="hummingbird" className="flex-1">
            <FormattedDate value={getScheduleDeparture(schedule)} month="long" day="numeric" />
          </Typography>

          {schedule.type === ScheduleType.Fixed && (
            <Typography is="span" type="hummingbird" className="flex-1">
              <FormattedDate value={schedule.departsAt} hour="numeric" minute="numeric" />
            </Typography>
          )}
        </div>

        <div className="flex flex-col text-right">
          <Typography is="span" type="hummingbird" className="flex-1">
            <FormattedDate value={getScheduleArrival(schedule)} month="long" day="numeric" />
          </Typography>

          {schedule.type === ScheduleType.Fixed && (
            <Typography is="span" type="hummingbird" className="flex-1">
              <FormattedDate value={schedule.arrivesAt} hour="numeric" minute="numeric" />
            </Typography>
          )}
        </div>
      </div>

      <Spacer xs={4} />

      {schedule.type === ScheduleType.Flexible && (
        <React.Fragment>
          <Typography is="span" type="halcyon">
            <FormattedMessage id={translations.pages.jetDealDetail.departureWindow.title} />
          </Typography>

          <Spacer xs={1} />

          <Typography is="span" type="hummingbird" className=" text-[#a3a3a3]">
            <FormattedMessage
              id={translations.pages.jetDealDetail.departureWindow.subtitle}
              values={{
                start: intl.formatDate(schedule.departureInterval.start, {
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                }),
                end: intl.formatDate(schedule.departureInterval.end, {
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })
              }}
            />
          </Typography>
        </React.Fragment>
      )}

      <Spacer xs={2} sm={4} />
    </div>
  );
};

export default TripSection;
