import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { CONTACT_US_URL, ABOUT_US_URL } from '@/config';

import Modal from '@flyblack/common/components/Modal';
import { SessionContext } from '@/components/Context/Session';
import PaymentMethodModal from '@/components/PaymentMethodModal';

import SettingItem from './SettingItem';

export interface Props {
  setFriendReferralOpen: (boolean) => any;
  setFrequentLocationsOpen: (boolean) => any;
}

const SettingsSection: React.FC<Props> = ({ setFriendReferralOpen, setFrequentLocationsOpen }) => {
  const { signOut, me, loading } = React.useContext(SessionContext);

  const intl = useIntl();
  const history = useHistory();

  const settingItems = translations.pages.profile.settings;

  return (
    <div className="flex flex-col sm:flex-row md:justify-between w-full max-w-4xl mt-8 mb-12 md:my-20">
      <div className="sm:w-1/2 flex flex-col space-y-6 px-6 md:px-10">
        <SettingItem
          icon="subscribe"
          url={urls.membership.base}
          title={intl.formatMessage({
            id: me.membership ? settingItems.membershipDetail.title : settingItems.becomeMember.title
          })}
          subtitle={intl.formatMessage({
            id: me.membership ? settingItems.membershipDetail.subtitle : settingItems.becomeMember.subtitle
          })}
        />

        <SettingItem
          icon="location"
          url={urls.profile.locations}
          title={intl.formatMessage({ id: settingItems.frequentLocations.title })}
          subtitle={intl.formatMessage({ id: settingItems.frequentLocations.subtitle })}
          onClick={() => setFrequentLocationsOpen(true)}
        />

        {me.membership && (
          <SettingItem
            icon="gift"
            url={urls.profile.refferals}
            title={intl.formatMessage({ id: settingItems.friendReferral.title })}
            subtitle={intl.formatMessage({ id: settingItems.friendReferral.subtitle })}
            onClick={() => setFriendReferralOpen(true)}
          />
        )}
      </div>

      <div className="sm:w-1/3 flex flex-col space-y-6 md:space-y-8 px-6 mt-4 sm:mt-0">
        <Modal modal={PaymentMethodModal}>
          {({ open }) => (
            <SettingItem
              is="div"
              className="cursor-pointer"
              icon="payment"
              title={intl.formatMessage({ id: settingItems.paymentMethod.title })}
              onClick={open}
            />
          )}
        </Modal>

        <SettingItem
          icon="attention"
          //@ts-ignore
          external
          url={ABOUT_US_URL}
          title={intl.formatMessage({ id: settingItems.about.title })}
        />

        <SettingItem
          icon="navigationConcierce"
          url={CONTACT_US_URL}
          //@ts-ignore
          external
          title={intl.formatMessage({ id: settingItems.support.title })}
        />

        <SettingItem
          icon="document"
          url={urls.legal.base}
          title={intl.formatMessage({ id: settingItems.legal.title })}
        />

        <SettingItem
          withArrow={false}
          is="div"
          className="cursor-pointer pt-8"
          icon="logOut"
          title={intl.formatMessage({ id: settingItems.logOut.title })}
          onClick={() => signOut().then(() => history.push(urls.home))}
        />
      </div>
    </div>
  );
};

export default SettingsSection;
