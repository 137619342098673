import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import SendBirdDesk from 'sendbird-desk';
import { GroupChannel } from 'sendbird';

import { translations } from '@/locale';
import { getDateString } from '@/util/chat';

import Typography from '@flyblack/common/components/Typography';

interface Props {
  ticket: SendBirdDesk.Ticket;
  isSelected?: boolean;
  onClick: () => any;
}

const Ticket = ({ ticket, isSelected = false, onClick }: Props) => {
  const intl = useIntl();

  const channel = ticket.channel as GroupChannel;

  return (
    <div
      className={classnames('px-6 pt-6 cursor-pointer', isSelected ? 'bg-white' : 'hover:bg-flyblack-white-10')}
      onClick={onClick}
    >
      <div className="flex justify-between">
        <Typography is="div" type="hummingbird" color={isSelected ? 'black' : 'white'}>
          {intl.formatMessage({ id: translations.pages.concierge.list.newInquiry })}
        </Typography>
        {channel.lastMessage && channel.lastMessage.createdAt && (
          <Typography is="div" type="hummingbird" color={isSelected ? 'darkerGray' : 'white'}>
            {getDateString(channel.lastMessage.createdAt)}
          </Typography>
        )}
      </div>
      <div className="flex justify-between items-center pt-2 pb-6">
        <Typography is="div" type="hummingbird" className="truncate mr-3" color={isSelected ? 'black' : 'lightGray'}>
          {channel.lastMessage
            ? channel.lastMessage.isFileMessage()
              ? intl.formatMessage({ id: translations.pages.concierge.list.fileMessage })
              : channel.lastMessage.message || intl.formatMessage({ id: translations.pages.concierge.list.noMessage })
            : intl.formatMessage({ id: translations.pages.concierge.list.noMessage })}
        </Typography>
        {channel.unreadMessageCount > 0 && !isSelected && (
          <Typography
            is="div"
            type="hummingbird"
            color="black"
            className="flex justify-center items-center flex-none w-6 h-6 bg-white rounded-full"
          >
            {channel.unreadMessageCount}
          </Typography>
        )}
      </div>
      {!isSelected && <div className="h-[1px] bg-flyblack-gray" />}
    </div>
  );
};

export default Ticket;
