import React from 'react';

import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

export interface Props {
  topText: string;
  bottomText: string;
}

const BenefitItem: React.FC<Props> = ({ topText, bottomText }) => {
  return (
    <React.Fragment>
      <Typography is="span" type="halcyon">
        {topText}
      </Typography>

      <Spacer xs={1} />

      <Typography is="span" type="hummingbird" color="lightGray">
        {bottomText}
      </Typography>
    </React.Fragment>
  );
};

export default BenefitItem;
