import React from 'react';
import { debounce } from 'lodash';
import classnames from 'classnames';

import useScrollablePagination, {
  Options as UsePaginationOptions,
  Return as UsePaginationReturn
} from '@flyblack/common/hooks/useScrollablePagination';

interface RenderProps<T> extends Pick<UsePaginationReturn<T>, 'data' | 'loading' | 'totalNumberOfElements'> {}

export interface Props<K, T> extends UsePaginationOptions<K, T> {
  children: (props: RenderProps<T>) => React.ReactElement;
  className?: string;
}

const InfiniteScroll = <K, T>({ children, className, ...props }: React.PropsWithChildren<Props<K, T>>) => {
  const { data, page, loading, totalNumberOfElements, more, loadPage } = useScrollablePagination(props);
  const ref = React.useRef<HTMLDivElement>();

  const handleScroll = debounce(() => {
    if (loading || !more) return;

    const list = ref.current;

    if (list.scrollHeight - (list.scrollTop + list.offsetHeight) < 10) {
      loadPage(page + 1);
    }
  }, 100);

  return (
    <div className={classnames('overflow-y-auto', className)} ref={ref} onScroll={handleScroll}>
      {children({ data, loading, totalNumberOfElements })}
    </div>
  );
};

export default InfiniteScroll;
