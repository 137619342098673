import React from 'react';
import { FormattedMessage } from 'react-intl';
import SendBirdDesk from 'sendbird-desk';
import format from 'date-fns/format';

import { translations } from '@/locale';

import { SendBirdContext } from '@/components/Context/SendBird';

import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';

interface Props {
  disabled: boolean;
  onCreate: (ticket: SendBirdDesk.Ticket) => void;
  children: any;
}

const NewTicket = ({ disabled, onCreate, children }: Props) => {
  const { sendBird } = React.useContext(SendBirdContext);

  const [loadingCreateTicket, setLoadingCreateTicket] = React.useState(false);

  const createTicket = () => {
    setLoadingCreateTicket(true);
    SendBirdDesk.Ticket.create(
      `${format(new Date(), 'MM/dd/yyyy')}_Chat_${sendBird.currentUser.nickname.replace(/ /g, '')}`,
      sendBird.currentUser.nickname,
      (ticket, err) => {
        // TODO: handle error
        if (!err) {
          onCreate(ticket);
        }
        setLoadingCreateTicket(false);
      }
    );
  };

  return (
    <React.Fragment>
      <div className="p-6 border-b border-solid border-flyblack-dark-gray">
        <Button appearance="white" className="w-full" onClick={createTicket} disabled={disabled || loadingCreateTicket}>
          <Icon type="add" />
          <FormattedMessage id={translations.pages.concierge.newTicketButton} />
        </Button>
      </div>
      {children({ loadingCreateTicket, createTicket })}
    </React.Fragment>
  );
};

export default NewTicket;
