import { generatePath } from 'react-router';

import { ErrorResultType, ResultScope, SuccessResultType } from '.';

export const signIn = `/login`;
export const signUp = `/signup`;

export const forgotPassword = {
  main: `/forgot-password`,
  sent: `/forgot-password/sent`
};

export const resetPassword = {
  main: `/reset-password`,
  expired: `/reset-password/expired`
};

export const legal = {
  base: '/legal',
  charter: '/legal/charter-terms',
  cookies: '/legal/cookies-policy',
  credit: '/legal/credit-policy',
  dataSubjects: '/legal/data-subjects-access-rights',
  privacy: '/legal/privacy-policy',
  terms: '/legal/terms-of-use'
};

export const home = `/`;

export const profile = {
  base: `/profile`,
  edit: `/profile/edit`,
  locations: `/profile/locations`,
  refferals: `/profile/refferals`,
  about: `/profile/about`,
  support: `/profile/support`
};

export const membership = {
  base: `/membership`,
  apply: '/membership/application',
  sent: '/membership/application-sent',
  checkout: {
    base: `/membership/checkout`,
    get: (membershipId: number) => generatePath(`/membership/checkout/?membershipId=:membershipId`, { membershipId })
  }
};

export const jetDeals = {
  base: `/jet-deals`,
  detail: {
    base: `/jet-deals/:id`,
    get: (id: number) => generatePath(`/jet-deals/:id`, { id })
  },
  checkout: {
    base: `/jet-deals/:id/checkout/`,
    get: (id: number) => generatePath(`/jet-deals/:id/checkout/`, { id })
  }
};

export const jetShuttles = {
  base: `/jet-shuttles`,
  detail: {
    base: `/jet-shuttles/:id`,
    get: (id: number) => generatePath(`/jet-shuttles/:id`, { id })
  },
  checkout: {
    base: `/jet-shuttles/:id/checkout`,
    get: (id: number, seats: number) => generatePath(`/jet-shuttles/:id/checkout?seats=:seats`, { id, seats })
  }
};

export const jetCharters = {
  base: `/jet-charters`,
  detail: `/jet-charters/detail`,
  oneWay: `/jet-charters/one-way`,
  roundTrip: `/jet-charters/round-trip`,
  multiLeg: `/jet-charters/multi-leg`,
  checkout: {
    base: `/jet-charters/:id/checkout/`,
    get: (id: number) => generatePath(`/jet-charters/:id/checkout/`, { id })
  }
};

export const myTrips = {
  base: `/my-trips`,
  detail: {
    base: `/my-trips/:id`,
    get: (id: number) => generatePath(`/my-trips/:id`, { id })
  }
};

export const concierge = {
  base: `/concierge`
};

export const planeDetail = {
  type: `/fleet/type/:id`,
  category: `/fleet/category/:id`,
  getType: (id: number) => generatePath(`/fleet/type/:id`, { id }),
  getCategory: (id: number) => generatePath(`/fleet/category/:id`, { id })
};

export const result = {
  base: `/result/:scope/:type`,
  getSuccessResult: (type: SuccessResultType) =>
    generatePath(`/result/:scope/:type`, { scope: ResultScope.Success, type }),
  getErrorResult: (type: ErrorResultType) => generatePath(`/result/:scope/:type`, { scope: ResultScope.Error, type })
};
