import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { WaitingListStatus } from '@flyblack/common/domains';
import { getWaitingListStatus } from '@/services/api/waiting-list';

import Icon from '@flyblack/common/components/Icon';
import Link from '@flyblack/common/components/Link';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Container from '@flyblack/common/components/Container';
import Typography from '@flyblack/common/components/Typography';

import useLoad from '@flyblack/common/hooks/useLoad';

import BenefitItem from './BenefitItem';

import logoImg from '@flyblack/common/assets/logo.png';

const Apply: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const { value, loading } = useLoad({ load: getWaitingListStatus });

  return (
    !loading && (
      <React.Fragment>
        <div className="flex min-h-full w-full justify-center mb-14 sm:mb-0 flex-col sm:flex-row">
          <div className="flex flex-col px-5 mx-auto lg:mx-[70px] w-full max-w-[540px]">
            <Spacer xs={8} />

            <Typography is="span" type="flamingo">
              <FormattedMessage id={translations.pages.membership.information.apply.title} />
            </Typography>

            <Spacer xs={7} />

            <div className="bg-[#171717] flex flex-col rounded-2xl p-5 w-full max-w-[420px]">
              <img className="h-3 opacity-50 ml-auto" src={logoImg} alt="FlyBlack" />

              <Spacer xs={2} sm={3} />

              <div className="flex flex-col max-w-[220px]">
                <Typography is="span" type="halcyon">
                  <FormattedMessage id={translations.pages.membership.information.apply.card.title} />
                </Typography>

                <Spacer xs={1} />

                <Typography is="span" type="hummingbird" color="darkerGray">
                  <FormattedMessage id={translations.pages.membership.information.apply.card.subtitle} />
                </Typography>
              </div>

              <Spacer xs={3} sm={4} />

              <div className="flex justify-between">
                {/* TODO update this when the client clarifies where it should lead */}
                <Link to={urls.profile.base}>
                  <FormattedMessage id={translations.pages.membership.information.apply.details} />
                </Link>

                {value.status === WaitingListStatus.Enabled ? (
                  <div className="flex items-center">
                    <Typography is="span" type="halcyon" color="lightGray">
                      <FormattedMessage id={translations.inputs.buttons.applyForMembership} />
                      <Icon type="chevronRight" />
                    </Typography>
                  </div>
                ) : (
                  <Link to={urls.membership.apply} className="ml-auto flex items-center">
                    <FormattedMessage id={translations.inputs.buttons.applyForMembership} />
                    <Icon type="chevronRight" />
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col px-5 mx-auto lg:mx-[70px] max-w-[340px]">
            <Spacer xs={8} />

            <Typography is="span" type="flamingo">
              <FormattedMessage id={translations.pages.membership.information.apply.benefits} />
            </Typography>

            <Spacer xs={6} />

            <BenefitItem
              topText={intl.formatMessage({
                id: translations.pages.membership.detail.benefits.discountedPrices.title
              })}
              bottomText={intl.formatMessage({
                id: translations.pages.membership.detail.benefits.discountedPrices.description
              })}
            />

            <Spacer xs={5} />

            <BenefitItem
              topText={intl.formatMessage({ id: translations.pages.membership.detail.benefits.otherBenefits.title })}
              bottomText={intl.formatMessage({
                id: translations.pages.membership.detail.benefits.otherBenefits.description
              })}
            />

            <Spacer xs={5} />

            <BenefitItem
              topText={intl.formatMessage({ id: translations.pages.membership.detail.benefits.pricing.title })}
              bottomText={intl.formatMessage({
                id: translations.pages.membership.detail.benefits.pricing.description
              })}
            />

            <Spacer xs={4} />
          </div>
        </div>

        {value.status === WaitingListStatus.Enabled && (
          <div className="h-40 md:h-24">
            <div className="fixed inset-x-0 flex items-center bottom-0 h-40 md:h-24 text-black bg-white w-full">
              <Container max className="flex flex-col md:flex-row justify-between items-center">
                <div className="flex items-center">
                  <Icon type="attention" className="mr-8" />

                  <Typography is="span" type="hummingbird" className="max-w-[302px] md:max-w-[450px] md:mr-6">
                    <FormattedMessage id={translations.pages.membership.information.apply.waitingList} />
                  </Typography>
                </div>

                <Spacer xs={2} md={0} />

                <Button
                  onClick={() => history.push(urls.membership.apply)}
                  appearance="lightGhost"
                  fat
                  className="w-full max-w-[320px]"
                >
                  <FormattedMessage id={translations.inputs.buttons.applyForWaitingList} />
                </Button>
              </Container>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  );
};

export default Apply;
