import React from 'react';
import { debounce } from 'lodash';
import classnames from 'classnames';

const SCROLL_TRIGGER_OFFSET = 10; // px
const SCROLL_HANDLER_DEBOUNCE_DELAY = 100; // milliseconds

interface Props {
  onBottomReached?: () => any;
  onTopReached?: () => any;
  ref?: any;
  className?: string;
  children: any;
}

// TODO: could we use this component within InfiniteScroll?
const ScrollTrigger = React.forwardRef(
  (
    { onBottomReached, onTopReached, className, children }: Props,
    forwardedRef: React.MutableRefObject<HTMLDivElement>
  ) => {
    const ref = forwardedRef || React.useRef<HTMLDivElement>();

    const handleScroll = debounce(() => {
      if (
        onBottomReached &&
        ref.current.scrollHeight - (ref.current.scrollTop + ref.current.offsetHeight) < SCROLL_TRIGGER_OFFSET
      ) {
        onBottomReached();
      }

      if (onTopReached && ref.current.scrollTop < SCROLL_TRIGGER_OFFSET) {
        onTopReached();
      }
    }, SCROLL_HANDLER_DEBOUNCE_DELAY);

    React.useEffect(() => {
      ref.current && ref.current.addEventListener('scroll', handleScroll);
      return () => ref.current && ref.current.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
      <div className={classnames('overflow-y-auto', className)} ref={ref}>
        {children}
      </div>
    );
  }
);

export default ScrollTrigger;
