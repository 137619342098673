import React from 'react';
import classnames from 'classnames';

import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';

interface Props {
  selected?: boolean;
  disabled: boolean;
  onClick: () => any;
}

const ChairSelect: React.FC<Props> = ({ selected = false, disabled, onClick }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={classnames(
        selected ? 'bg-black border-black' : 'bg-white border-flyblack-lighter-gray',
        'relative flex justify-center border border-solid items-center transition-colors rounded-sm w-8 h-8 sm:w-12 sm:h-12'
      )}
    >
      <Icon
        type="chair"
        className={classnames(
          disabled ? ' text-flyblack-lighter-gray' : 'text-white',
          'text-[24.1px]  leading-[24.1px] sm:text-[36px] sm:leading-[36px] md:text-[36px] md:leading-[36px]'
        )}
        appearance={selected ? 'white' : 'black'}
      />
    </Button>
  );
};

export default ChairSelect;
