import React from 'react';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Container from '@flyblack/common/components/Container';
import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import InfoBar from '@/components/InfoBar';

const UnknownErrorInfoBar = () => {
  const history = useHistory();

  return (
    <InfoBar open={true}>
      <Container>
        <div className="max-w-[800px] mx-auto py-6 flex justify-between">
          <div className="flex items-center">
            <Icon type="attention" appearance="red" />
            <div className="pl-8">
              <Typography is="div" type="sparrow">
                <FormattedMessage id={translations.application.common.unknownError.title} />
              </Typography>
              <Typography is="div" type="hummingbird" color="darkerGray">
                <FormattedMessage id={translations.application.common.unknownError.subtitle} />
              </Typography>
            </div>
          </div>
          <Button onClick={() => history.go(0)} appearance="black" fat className="sm:w-[240px]">
            <FormattedMessage id={translations.errors.tryAgainButton} />
          </Button>
        </div>
      </Container>
    </InfoBar>
  );
};

export default UnknownErrorInfoBar;
