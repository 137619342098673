import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { CharterLegSummary, FlightType, TripStatus } from '@flyblack/common/domains';
import { openInNewTab } from '@flyblack/common/util';
import { generateMyTripDetail } from '@/services/api/trips';

import Link from '@flyblack/common/components/Link';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import DoubleContentWithIcon from '@flyblack/common/components/DoubleContentWithIcon';
import RowContainer from '@flyblack/common/components/RowContainer';
import Icon from '@flyblack/common/components/Icon';
import { SubmitError } from '@flyblack/common/components/Error';

import TripSectionItinerary from '@/components/TripDetailSection/TripSectionItinerary';
import TripSectionSeats from '@/components/TripDetailSection/TripSectionSeats';

interface Props {
  tripId: number;
  status: TripStatus;
  receiptUrl: string;
  charterLeg: CharterLegSummary;
  className?: string;
}

const TripDetailSection: React.FC<Props> = ({
  tripId,
  status,
  receiptUrl,
  charterLeg: {
    sourceAirport,
    destinationAirport,
    schedule,
    durationInMinutes,
    pilots,
    aircraftCategory,
    tailNumber,
    flightId
  },
  className
}) => {
  const intl = useIntl();

  const [loadingDownloadTripDetail, setLoadingDownloadTripDetail] = React.useState(false);
  const [errorDownloadTripDetail, setErrorDownloadTripDetail] = React.useState<any>();

  const downloadItinerary = () => {
    setLoadingDownloadTripDetail(true);
    generateMyTripDetail(tripId)
      .then(({ imageUrl }) => openInNewTab(imageUrl))
      .catch(() =>
        setErrorDownloadTripDetail({
          message: intl.formatMessage({ id: translations.errors.custom.somethingWentWrong })
        })
      )
      .finally(() => setLoadingDownloadTripDetail(false));
  };

  return (
    <div className={classnames('flex flex-col p-6 sm:px-0 w-full max-w-[480px]', className)}>
      <TripSectionItinerary
        sourceAirport={sourceAirport}
        durationInMinutes={durationInMinutes}
        destinationAirport={destinationAirport}
        schedule={schedule}
        type={FlightType.Charter}
      />

      <div className="border-b border-solid border-flyblack-gray my-10" />

      <TripSectionSeats
        type={FlightType.Charter}
        pilots={pilots}
        numberOfSeats={aircraftCategory.seats}
        showMoreSeats={aircraftCategory.name === 'Heavy Jet'}
      />

      {status === TripStatus.Booked && (
        <React.Fragment>
          <div className="border-b border-solid border-flyblack-gray my-10" />

          <div className="flex justify-between">
            <DoubleContentWithIcon
              className="w-1/2"
              icon="tailNumber"
              noSpacer
              top={
                <Typography is="span" type="hummingbird" color="lightGray">
                  <FormattedMessage id={translations.pages.myTrips.detail.tripSection.tailNumber} />
                </Typography>
              }
              bottom={
                <Typography is="span" type="halcyon">
                  {tailNumber || '-'}
                </Typography>
              }
            />

            <DoubleContentWithIcon
              className="w-1/2"
              icon="plane"
              noSpacer
              top={
                <Typography is="span" type="hummingbird" color="lightGray">
                  <FormattedMessage id={translations.pages.myTrips.detail.tripSection.flightId} />
                </Typography>
              }
              bottom={
                <Typography is="span" type="halcyon">
                  {flightId}
                </Typography>
              }
            />
          </div>

          <div className="border-b border-solid border-flyblack-gray my-10" />

          <Typography is="span" type="hummingbird" weight="medium" className="uppercase tracking-[2px]">
            <FormattedMessage id={translations.pages.myTrips.detail.tripSection.options} />
          </Typography>

          <Spacer xs={3} />

          <RowContainer>
            <RowContainer.Item col={6}>
              <Link
                className="px-3 text-white flex items-center h-[56px] justify-center bg-black hover:bg-flyblack-gray hover:transition-colors border border-solid border-white"
                to={receiptUrl}
                external
              >
                <Icon type="download" className="mr-2" />
                <FormattedMessage id={translations.inputs.buttons.seeReceipt} />
              </Link>
            </RowContainer.Item>

            <RowContainer.Item col={6}>
              <Button
                type="button"
                fat
                appearance="ghost"
                className="w-full"
                onClick={downloadItinerary}
                loading={loadingDownloadTripDetail}
              >
                {!loadingDownloadTripDetail && <Icon type="download" className="mr-2" />}
                <FormattedMessage id={translations.inputs.buttons.downloadItinerary} />
              </Button>
            </RowContainer.Item>
          </RowContainer>

          {errorDownloadTripDetail && <SubmitError error={errorDownloadTripDetail} className="pt-4" />}
        </React.Fragment>
      )}

      <Spacer xs={3} />
    </div>
  );
};

export default TripDetailSection;
