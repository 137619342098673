import React from 'react';
import classnames from 'classnames';

import Typography from '@flyblack/common/components/Typography';
import Month from '@flyblack/common/components/Calendar/Month';
import MonthNavigation from '@flyblack/common/components/Calendar/MonthNavigation';
import { useMonthNavigation } from '@flyblack/common/components/Calendar/useMonthNavigation';

export interface Props {
  date?: Date;
  selectDate: (date: Date) => any;
  enabledDates?: Date[];
  className?: string;
}

const RouteDatePicker = ({ date, selectDate, enabledDates, className }: Props) => {
  const { currentDate, goToPreviousMonth, goToNextMonth } = useMonthNavigation(date);

  return (
    <div className={classnames('flex justify-center bg-white', className)}>
      <div className="py-9 flex flex-col justify-between">
        <div className="w-[308px] flex justify-between items-center pb-3">
          <MonthNavigation
            currentDate={currentDate}
            onGoToPreviousMonth={goToPreviousMonth}
            onGoToNextMonth={goToNextMonth}
          />
        </div>

        <div className="w-[308px]">
          <Month
            year={currentDate.getFullYear()}
            month={currentDate.getMonth()}
            selectedDate={date}
            onSelectDate={selectDate}
            enabledDates={enabledDates}
            renderDay={({ dayNumber, disabled, isSelected }) => (
              <div className="flex flex-col items-center">
                <Typography is="span" type="hummingbird">
                  {dayNumber}
                </Typography>
                <div
                  className={classnames('w-1 h-1 rounded-full', !disabled && (isSelected ? 'bg-white' : 'bg-black'))}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default RouteDatePicker;
