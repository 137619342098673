import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { LegOption } from '@/domains/charter';
import { createPet, updatePet } from '@/services/api/pets';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import LegCheckboxes from './LegCheckboxes';

interface InitialPetData {
  breed?: string;
  type?: string;
  jetCharterFlightIds?: number[];
}

export interface Props {
  petId?: number;
  initialData?: InitialPetData;
  tripId: number;
  onSuccess: () => any;
  onCancel: () => any;
  legOptions?: LegOption[];
}

const schema = yup.object().shape({
  breed: yup
    .string()
    .label(translations.inputs.petBreed.label)
    .required(),
  type: yup
    .string()
    .label(translations.inputs.petType.label)
    .required()
});

const TextInputWithValidation = withValidation(TextInput);

const PetForm = ({ petId, initialData, onSuccess, onCancel, tripId, legOptions }: Props) => {
  const intl = useIntl();

  const handleSubmit = (values) =>
    (petId ? updatePet({ ...values, id: petId }) : createPet({ ...values, tripId })).then(onSuccess);

  return (
    <Form
      id={`pet-${petId || 'new'}`}
      subscription={{ errors: true, submitting: true, submitError: true, dirty: true, values: true, valid: true }}
      initialValues={initialData}
      schema={schema}
      onSubmit={handleSubmit}
    >
      {({ submitError, submitting, values, valid, form, errors, dirty }, formId) => (
        <React.Fragment>
          <Form.Field
            is={TextInputWithValidation}
            id={`${formId}-type`}
            name="type"
            type="text"
            label={intl.formatMessage({ id: translations.inputs.petType.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.petType.placeholder })}
            readOnly={submitting}
            appearance="light"
          />

          <Spacer xs={2} />

          <Form.Field
            is={TextInputWithValidation}
            id={`${formId}-breed`}
            name="breed"
            type="text"
            label={intl.formatMessage({ id: translations.inputs.petBreed.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.petBreed.placeholder })}
            readOnly={submitting}
            appearance="light"
          />

          <Spacer xs={2} />

          {legOptions && legOptions.length > 1 && (
            <React.Fragment>
              <LegCheckboxes formId={formId} legOptions={legOptions} submitting={submitting} />
              <Spacer xs={2} />
            </React.Fragment>
          )}

          <div className="flex flex-row justify-end">
            <Button appearance="underlined" form={formId} onClick={onCancel} type="button" className="w-1/4">
              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.inputs.buttons.cancel} />
              </Typography>
            </Button>

            <Button
              appearance="black"
              className="w-2/3 ml-8"
              form={formId}
              loading={submitting}
              disabled={!dirty}
              type="submit"
            >
              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.inputs.buttons.save} />
              </Typography>
            </Button>
          </div>

          <Spacer xs={2} />
        </React.Fragment>
      )}
    </Form>
  );
};

export default PetForm;
