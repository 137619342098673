import React from 'react';
import queryString from 'query-string';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { sortPhotos } from '@flyblack/common/util';
import { CharterLegListItem } from '@/domains/charter';
import { getAircraftCategories } from '@/services/api/aircraft-type';

import Spacer from '@flyblack/common/components/Spacer';
import ClientPhotoGallery from '@/components/ClientPhotoGallery';

import useLoad from '@flyblack/common/hooks/useLoad';

import TripSection from './TripSection';
import ChairSection from './ChairSection';

export interface Props {}

interface Params {
  id: string;
}

const JetCharterDetail: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const location = useLocation();
  const data = JSON.parse(queryString.parse(location.search).data as string) as {
    paxNum: string;
    id: string;
    aircraftCategoryId: string;
    legs: CharterLegListItem[];
    price: number;
  };

  //By doing this call here, the URL gets shorter by about 65%
  const { value: aircraftCategory, loading } = useLoad({
    load: () =>
      getAircraftCategories().then((response) =>
        response.categories.find((item) => item.id === parseInt(data.aircraftCategoryId))
      )
  });

  return (
    !loading && (
      <div className="bg-black min-h-full w-full flex flex-col items-center">
        <Spacer xs={0} sm={9} />

        <div className="flex flex-wrap w-full justify-around md:justify-between min-h-full max-w-5xl">
          <ChairSection
            charterData={data}
            seats={aircraftCategory.seats}
            showMoreSeats={aircraftCategory.name === 'Heavy Jet'}
            className="order-1 mx-auto lg:order-2 lg:max-w-[380px]"
          />

          <div className="flex flex-col w-full max-w-[480px] mx-auto order-2 lg:order-1">
            <TripSection legs={data.legs} className="order-1 lg:order-2" />

            <ClientPhotoGallery
              images={sortPhotos(aircraftCategory.photos)}
              airplaneCategory={aircraftCategory}
              className="order-2 lg:order-1"
            />
          </div>
        </div>

        <Spacer xs={10} />
      </div>
    )
  );
};

export default JetCharterDetail;
