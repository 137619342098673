import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Popup from '@flyblack/common/components/Popup';
import Typography from '@flyblack/common/components/Typography';
import Icon from '@flyblack/common/components/Icon';

const TerminalPopupIcon = () => (
  <Icon type="questionMark" appearance="white" sizeClassName="text-[16px] leading-[16px]" />
);

export interface Props {
  className?: string;
}

const TerminalPopup = ({ className }: Props) => {
  return (
    <Popup button={TerminalPopupIcon} className={className}>
      <div className="p-8 text-left">
        <Typography is="div" type="hummingbird" color="black" className="mb-8">
          <FormattedMessage id={translations.pages.myTrips.detail.tripSection.terminalDetails.mainInfo} />
        </Typography>

        <Typography is="div" type="hummingbird" color="black" weight="medium" className="mb-8">
          <FormattedMessage id={translations.pages.myTrips.detail.tripSection.terminalDetails.warning} />
        </Typography>

        <Typography is="div" type="hummingbird" color="black">
          <FormattedMessage id={translations.pages.myTrips.detail.tripSection.terminalDetails.contactInfo} />
        </Typography>
      </div>
    </Popup>
  );
};

export default TerminalPopup;
