import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  title: string;
  subtitle: string;
  tryAgain: () => unknown;
}

const ErrorView: React.FC<Props> = ({ title, subtitle, tryAgain }) => {
  const history = useHistory();

  const goToHome = () => history.push(urls.home);

  return (
    <div className="h-screen mx-auto flex flex-col justify-center items-center text-center">
      <Typography is="div" type="flamingo" className="pb-4">
        {title}
      </Typography>

      <div className="w-[400px] flex flex-col">
        <Typography is="div" type="halcyon" className="pb-10">
          {subtitle}
        </Typography>

        <Button appearance="white" fat onClick={tryAgain} className="mb-10">
          <FormattedMessage id={translations.errors.tryAgainButton} />
        </Button>

        <Button onClick={goToHome}>
          <FormattedMessage id={translations.errors.goToHomeButton} />
        </Button>
      </div>
    </div>
  );
};

export default ErrorView;
