import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Typography from '@flyblack/common/components/Typography';

export interface Props {
  stepNumber: number;
  stepNumberThreshold: number;
  textId: string;
}

const StepItem: React.FC<Props> = ({ stepNumber, stepNumberThreshold, textId }) => {
  return (
    <div
      className={classnames(
        'relative flex py-4 ',
        stepNumber > stepNumberThreshold - 1 ? 'text-white' : 'text-flyblack-light-gray'
      )}
    >
      <Typography is="span" type="hummingbird">
        <FormattedMessage id={textId} />
      </Typography>
      {stepNumber === stepNumberThreshold && (
        <div className="h-2 w-2 rounded-full -mb-1 bg-white absolute bottom-0 left-1/2 -ml-1" />
      )}
    </div>
  );
};

export default StepItem;
