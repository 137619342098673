import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { urls } from '@/constants';
import { LegalPageType } from '@/constants/legal-page-type';

import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import LegalPage from './LegalPage';

const LegalPages: React.FC = () => {
  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex flex-col items-center h-full w-full">
        <Switch>
          <Route exact path={urls.legal.terms} component={() => <LegalPage type={LegalPageType.Terms} />} />

          <Route exact path={urls.legal.charter} component={() => <LegalPage type={LegalPageType.Charter} />} />

          <Route exact path={urls.legal.cookies} component={() => <LegalPage type={LegalPageType.Cookies} />} />

          <Route exact path={urls.legal.credit} component={() => <LegalPage type={LegalPageType.Credit} />} />

          <Route
            exact
            path={urls.legal.dataSubjects}
            component={() => <LegalPage type={LegalPageType.DataSubjects} />}
          />

          <Route exact path={urls.legal.privacy} component={() => <LegalPage type={LegalPageType.Privacy} />} />

          <ConsumeRedirectRoute
            render={() => <ConsumeRedirectRoute render={() => <Redirect to={urls.legal.terms} />} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default LegalPages;
