import React from 'react';
import classnames from 'classnames';

import { translations } from '@/locale';

import Typography from '@flyblack/common/components/Typography';

import { StatItem } from './StatItem';

export interface Props {
  title: string;
  manufacturer: string;
  numberOfSeats: number;
  crewNumber: string;
  className?: string;
}

const TitleSection: React.FC<Props> = ({ title, manufacturer, numberOfSeats, crewNumber, className }) => {
  const text = translations.pages.aircraftDetail;

  return (
    <div
      className={classnames(
        'flex flex-col w-full max-w-[480px] lg:max-w-[380px] lg:justify-between mb-10 lg:mb-12',
        className
      )}
    >
      <Typography is="span" type="flamingo" className="px-6 sm:px-0 mb-16">
        {title}
      </Typography>

      <div className="flex flex-row items-center px-6 sm:px-0 gap-4">
        <StatItem titleId={text.manufacturer} value={manufacturer} icon="navigationPlane" className="w-1/2" />

        <StatItem titleId={text.seats} value={numberOfSeats} icon="seat" className="w-1/4" />

        <StatItem titleId={text.crew} value={crewNumber} icon="crew" className="w-1/4" />
      </div>
    </div>
  );
};

export default TitleSection;
