import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getScheduleDeparture } from '@flyblack/common/util';
import {
  AircraftCategory,
  AircraftType,
  FixedSchedule,
  FlexibleSchedule,
  FlightType,
  TripStatus
} from '@flyblack/common/domains';

import Link from '@flyblack/common/components/Link';
import Icon, { Type } from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';
import BoxedContent, { Appearance } from '@flyblack/common/components/BoxedContent';

export interface Props {
  id: number;
  aircraftCategory: AircraftCategory;
  aircraftType?: AircraftType;
  eventName?: string;
  schedule: FixedSchedule | FlexibleSchedule;
  showInterval?: boolean;
  sourceCity: string;
  destinationCity: string;
  purchasedSeats?: number;
  type: FlightType;
  status: TripStatus;
}

const flightTypeToIconTypeMap: Record<FlightType, Type> = {
  [FlightType.Deal]: 'navigationPlane',
  [FlightType.Shuttle]: 'chair',
  [FlightType.Charter]: 'navigationPlane'
};

const flightStatusToAppearanceMap: Record<TripStatus, Appearance> = {
  [TripStatus.Booked]: 'green',
  [TripStatus.Canceled]: 'red',
  [TripStatus.Delayed]: 'gray',
  [TripStatus.Expired]: 'gray',
  [TripStatus.Open]: 'gray',
  [TripStatus.PartiallyBooked]: 'gray',
  [TripStatus.Pending]: 'gray',
  [TripStatus.WaitingForApproval]: 'gray',
  [TripStatus.WaitingForPayment]: 'gray'
};

const MyTripsCardItem: React.FC<Props> = ({
  id,
  aircraftCategory,
  aircraftType,
  eventName,
  schedule,
  showInterval,
  sourceCity,
  destinationCity,
  purchasedSeats,
  type,
  status
}) => {
  return (
    <Link
      to={urls.myTrips.detail.get(id)}
      className="flex flex-col sm:flex-row sm:min-h-[132px] bg-white rounded mb-2 sm:mb-6 group"
    >
      <div
        className="hidden sm:flex rounded-l min-w-[156px] bg-cover bg-center"
        style={{
          backgroundImage: `url(${
            aircraftType && aircraftType.photos.length > 0
              ? aircraftType.photos[0].url
              : aircraftCategory.photos.find((photo) => photo.type === 'MAIN_PHOTO').url ||
                aircraftCategory.photos[0].url
          })`
        }}
      />

      <div className="flex flex-col justify-center h-full w-full px-4 md:px-8 py-4 sm:py-0">
        <Typography is="div" type="hummingbird" color="lightGray" className="pb-2">
          <FormattedMessage id={translations.domains.flightType[type]} />
          {eventName && ` • ${eventName}`}
        </Typography>

        <div className="flex items-center">
          <div className="w-1/4">
            {showInterval ? (
              <React.Fragment>
                <Typography is="div" type="halcyon" color="black" weight="medium" className="hidden lg:block pb-1">
                  <FormattedDate value={(schedule as FixedSchedule).departsAt} weekday="short" />
                  {' - '}
                  <FormattedDate value={(schedule as FixedSchedule).arrivesAt} weekday="short" />
                </Typography>

                <div className="pr-2">
                  <Typography is="span" type="hummingbird" color="darkerGray" className="whitespace-nowrap">
                    <FormattedDate value={(schedule as FixedSchedule).departsAt} month="short" day="numeric" />
                  </Typography>

                  <Typography is="span" type="hummingbird" color="darkerGray">
                    {' - '}
                  </Typography>

                  <Typography is="span" type="hummingbird" color="darkerGray" className="whitespace-nowrap">
                    <FormattedDate
                      value={(schedule as FixedSchedule).arrivesAt}
                      day="numeric"
                      {...(new Date((schedule as FixedSchedule).departsAt).getMonth() !==
                        new Date((schedule as FixedSchedule).arrivesAt).getMonth() && { month: 'short' })}
                    />
                  </Typography>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography is="div" type="halcyon" color="black" weight="medium" className="hidden lg:block pb-1">
                  <FormattedDate value={getScheduleDeparture(schedule)} weekday="long" />
                </Typography>

                <Typography is="div" type="halcyon" color="black" weight="medium" className="block lg:hidden pb-1">
                  <FormattedDate value={getScheduleDeparture(schedule)} weekday="short" />
                </Typography>

                <Typography is="div" type="hummingbird" color="darkerGray">
                  <FormattedDate value={getScheduleDeparture(schedule)} month="short" day="numeric" />
                </Typography>
              </React.Fragment>
            )}
          </div>

          <div className="flex flex-col w-3/4">
            <div className="flex items-center pb-1">
              <Typography is="div" type="halcyon" color="black" weight="medium">
                {sourceCity}
              </Typography>

              <Icon type="plane" appearance="black" className="px-3" />

              <Typography is="div" type="halcyon" color="black" weight="medium">
                {destinationCity}
              </Typography>
            </div>

            <Typography is="div" type="hummingbird" color="darkerGray">
              <Icon type={flightTypeToIconTypeMap[type]} sizeClassName="text-[16px] mr-2 leading-[16px]" />

              <FormattedMessage
                id={translations.pages.myTrips.seats[type]}
                values={{
                  aircraftType: aircraftType ? aircraftType.name : false,
                  aircraftCategory: aircraftCategory.name,
                  seats: purchasedSeats || aircraftCategory.seats,
                  plusSign: type === FlightType.Charter && aircraftCategory.name === 'Heavy Jet'
                }}
              />
            </Typography>
          </div>
        </div>
      </div>

      <div className="flex items-center rounded-b-sm sm:pr-4 md:pr-8">
        <BoxedContent appearance={flightStatusToAppearanceMap[status]} className="w-full sm:w-auto py-2 text-center">
          <Typography is="span" type="hummingbird">
            <FormattedMessage id={translations.domains.tripStatus[status]} />
          </Typography>
        </BoxedContent>
      </div>
    </Link>
  );
};

export default MyTripsCardItem;
