import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { urls } from '@/constants';

import LogoLink from '@flyblack/common/components/LogoLink';
import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import LegalPages from './LegalPages';
import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordLinkSent from './ForgotPasswordLinkSent';
import ResetPasswordLinkExpired from './ResetPasswordLinkExpired';

const Unauthorized: React.FC = () => (
  <div className="flex flex-col h-full w-full">
    <LogoLink className="mt-8 ml-8 mb-8 h-5 w-auto" />

    <div className="flex flex-col items-center h-full w-full">
      <Switch>
        <Route exact path={urls.signUp} component={SignUpPage} />

        <Route exact path={urls.signIn} component={SignInPage} />

        <Route exact path={urls.forgotPassword.main} component={ForgotPassword} />

        <Route exact path={urls.forgotPassword.sent} component={ForgotPasswordLinkSent} />

        <Route exact path={urls.resetPassword.main} component={ResetPassword} />

        <Route exact path={urls.resetPassword.expired} component={ResetPasswordLinkExpired} />

        <Route path={urls.legal.base} component={LegalPages} />

        <ConsumeRedirectRoute render={() => <ConsumeRedirectRoute render={() => <Redirect to={urls.signIn} />} />} />
      </Switch>
    </div>
  </div>
);

export default Unauthorized;
