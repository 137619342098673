import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

const FlightPaymentSuccessView: React.FC = () => {
  const history = useHistory();

  const goToMyTrips = () => history.push(urls.myTrips.base);

  return (
    <div className="h-screen mx-auto flex flex-col justify-center items-center text-center">
      <Icon type="bookingCompleted" className="pb-8" sizeClassName="text-[80px]" />

      <Typography is="div" type="flamingo" className="pb-4">
        <FormattedMessage id={translations.pages.checkout.paymentSuccess.title} />
      </Typography>

      <div className="w-[400px] flex flex-col">
        <Typography is="div" type="halcyon" className="pb-12">
          <FormattedMessage id={translations.pages.checkout.paymentSuccess.subtitle} />
        </Typography>

        <Typography is="div" type="halcyon" color="lightGray" className="pb-8">
          <FormattedMessage id={translations.pages.checkout.paymentSuccess.details} />
        </Typography>

        <Button appearance="white" fat onClick={goToMyTrips}>
          <FormattedMessage id={translations.pages.checkout.paymentSuccess.myTripsButton} />
        </Button>

        <Typography is="div" type="halcyon" color="lightGray" className="pt-12">
          <FormattedMessage id={translations.pages.checkout.paymentSuccess.conciergeDetails} />
        </Typography>
      </div>
    </div>
  );
};

export default FlightPaymentSuccessView;
