import React from 'react';
import range from 'lodash/range';

import Select, { Props as SelectProps } from '@flyblack/common/components/Select';

export interface Props extends Omit<SelectProps<number>, 'items'> {}

const PassengerNumberSelect: React.FC<Props> = ({ ...props }) => {
  const items = range(1, 12).map((number) => ({
    value: number,
    name: number <= 10 ? `${number}` : `${number - 1}+`
  }));

  return <Select {...props} items={items} noChevron />;
};

export default PassengerNumberSelect;
