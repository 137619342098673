import React from 'react';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Icon from '@flyblack/common/components/Icon';
import Modal from '@flyblack/common/components/Modal';
import Button from '@flyblack/common/components/Button';
import ConfirmationModal from '@flyblack/common/components/ConfirmationModal';
import { useStateWithCallback } from '@flyblack/common/hooks/useStateWithCallback';

interface ModalPropsState {
  title: string;
  onConfirm: (event: React.MouseEvent) => Promise<any>;
}

interface Props {
  type: 'pet' | 'passenger';
  onEdit: () => any;
  onDelete: () => any;
  onDeleteFlight?: () => any;
}

const ItemMenu = ({ onEdit, onDelete, onDeleteFlight, type }: Props) => {
  const intl = useIntl();

  const [modalProps, setModalPropsWithCallback] = useStateWithCallback<ModalPropsState>();

  return (
    <Modal
      modal={ConfirmationModal}
      confirmMessage={intl.formatMessage({ id: translations.modals.confirmationModals.yes })}
      cancelMessage={intl.formatMessage({ id: translations.modals.confirmationModals.no })}
      {...modalProps}
    >
      {({ open: openModal }) => (
        <Menu as="div" className="ml-auto pl-6 relative">
          {({ open }) => (
            <>
              <Menu.Button className="px-2 -mx-2">
                <Icon type="more" />
              </Menu.Button>

              <Transition
                show={open}
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 rounded-sm overflow-hidden shadow-lg z-10 focus:outline-none"
                >
                  <Menu.Item>
                    {({ active }) => (
                      <Button
                        onClick={onEdit}
                        appearance="black"
                        className={classNames(
                          active && 'bg-flyblack-gray',
                          'block rounded-none px-4 py-2 w-full whitespace-nowrap'
                        )}
                      >
                        <FormattedMessage id={translations.inputs.buttons.edit} />
                      </Button>
                    )}
                  </Menu.Item>

                  {onDeleteFlight ? (
                    <React.Fragment>
                      <Menu.Item>
                        {({ active }) => (
                          <Button
                            onClick={() =>
                              setModalPropsWithCallback(
                                {
                                  title: intl.formatMessage({
                                    id:
                                      translations.modals.confirmationModals[
                                        type === 'pet' ? 'deletePetFromFlight' : 'deletePassengerFromFlight'
                                      ]
                                  }),
                                  onConfirm: onDeleteFlight
                                },
                                openModal
                              )
                            }
                            appearance="black"
                            className={classNames(
                              active && 'bg-flyblack-gray',
                              'block rounded-none px-4 py-2 w-full whitespace-nowrap'
                            )}
                          >
                            <FormattedMessage id={translations.inputs.buttons.removeFromFlight} />
                          </Button>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <Button
                            onClick={() =>
                              setModalPropsWithCallback(
                                {
                                  title: intl.formatMessage({
                                    id:
                                      translations.modals.confirmationModals[
                                        type === 'pet' ? 'deletePetFromTrip' : 'deletePassengerFromTrip'
                                      ]
                                  }),
                                  onConfirm: onDelete
                                },
                                openModal
                              )
                            }
                            appearance="black"
                            className={classNames(
                              active && 'bg-flyblack-gray',
                              'block rounded-none px-4 py-2 w-full whitespace-nowrap'
                            )}
                          >
                            <FormattedMessage id={translations.inputs.buttons.removeFromTrip} />
                          </Button>
                        )}
                      </Menu.Item>
                    </React.Fragment>
                  ) : (
                    <Menu.Item>
                      {({ active }) => (
                        <Button
                          onClick={() =>
                            setModalPropsWithCallback(
                              {
                                title: intl.formatMessage({
                                  id:
                                    translations.modals.confirmationModals[
                                      type === 'pet' ? 'deletePet' : 'deletePassenger'
                                    ]
                                }),
                                onConfirm: onDelete
                              },
                              openModal
                            )
                          }
                          appearance="black"
                          className={classNames(
                            active && 'bg-flyblack-gray',
                            'block rounded-none px-4 py-2 w-full whitespace-nowrap'
                          )}
                        >
                          <FormattedMessage id={translations.inputs.buttons.remove} />
                        </Button>
                      )}
                    </Menu.Item>
                  )}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </Modal>
  );
};

export default ItemMenu;
