import React from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getNow } from '@flyblack/common/util';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import { SubmitError } from '@flyblack/common/components/Error';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';

import JetCharterSearchRow from './SearchRow';
import { roundTripSchema } from './Schema';

export interface Props {}

const RoundTripSearch: React.FC<Props> = () => {
  const history = useHistory();
  return (
    <Form
      id="charter-round-trip-search"
      schema={roundTripSchema}
      subscription={{ errors: true, submitting: true, submitError: true, dirty: true, values: true, valid: true }}
      initialValues={{
        paxNum: 2,
        fromDateStart: getNow(dateFormat),
        fromDateEnd: getNow(dateFormat)
      }}
      onSubmit={(values) =>
        history.push(
          queryString.stringifyUrl({
            url: urls.jetCharters.roundTrip,
            query: {
              paxNum: values.paxNum,
              legs: JSON.stringify([
                { fromDate: values.fromDateStart, fromICAO: values.fromICAO, toICAO: values.toICAO },
                { fromDate: values.fromDateEnd, fromICAO: values.toICAO, toICAO: values.fromICAO }
              ])
            }
          })
        ) as any
      }
      className="w-full flex flex-col"
    >
      {({ submitError, submitting, values, valid, form, errors, dirty }, formId) => (
        <React.Fragment>
          <JetCharterSearchRow submitting={submitting} form={form} values={values} withPassengers withDateRange />

          <Spacer xs={4} />

          {submitError && <SubmitError error={submitError} />}

          <Button type="submit" className="w-full md:w-[220px] font-normal ml-auto" appearance="white">
            <FormattedMessage id={translations.inputs.buttons.getQuotes} />
          </Button>

          <Spacer xs={10} />
        </React.Fragment>
      )}
    </Form>
  );
};

export default RoundTripSearch;
