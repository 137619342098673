import React from 'react';
import { FormattedDate } from 'react-intl';

import { CharterAirport } from '@flyblack/common/domains';
import { formatDuration } from '@flyblack/common/util';

import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';
import Spacer from '@flyblack/common/components/Spacer';

interface Props {
  sourceAirport: CharterAirport;
  destinationAirport: CharterAirport;
  date: string;
  durationInMinutes: number;
}

const TripSectionLeg = ({ sourceAirport, destinationAirport, date, durationInMinutes }: Props) => (
  <div className={'flex flex-col px-6 py-5 sm:px-0 w-full max-w-[480px]'}>
    <div className="flex flex-row justify-between items-center">
      <div className="text-2xl">{sourceAirport.icao}</div>

      <div className="h-[1px] flex justify-center items-center bg-flyblack-gray w-full mx-6 relative">
        <Icon type="plane" className="p-1 bg-black" />
      </div>

      <div className="text-2xl">{destinationAirport.icao}</div>
    </div>

    <div className="flex flex-row justify-between items-center">
      <Typography is="span" type="hummingbird" className="flex-1" color="lightGray">
        {sourceAirport.city}
      </Typography>

      <Typography is="span" type="hummingbird" className="flex-1 text-center" color="lightGray">
        {formatDuration(durationInMinutes)}
      </Typography>

      <Typography is="span" type="hummingbird" className="flex-1 text-right" color="lightGray">
        {destinationAirport.city}
      </Typography>
    </div>

    <Spacer xs={2} />

    <div className="flex flex-row justify-between items-center">
      <Typography is="span" type="hummingbird" className="flex-1" color="white">
        <FormattedDate value={date} month="long" year="numeric" day="numeric" />
      </Typography>

      <Typography is="span" type="hummingbird" className="flex-1 text-right" color="white">
        <FormattedDate value={date} month="long" year="numeric" day="numeric" />
      </Typography>
    </div>
  </div>
);
export default TripSectionLeg;
