import React from 'react';
import * as yup from 'yup';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { sendResetPasswordLink } from '@/services/api/session';

import Link from '@flyblack/common/components/Link';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SubmitError } from '@flyblack/common/components/Error';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import { emailSchema } from '../UnauthorizedSchemas';

import bgLoginImg from '@/assets/bg-login.jpg';

const TextInputWithValidation = withValidation(TextInput);

const schema = yup.object().shape({
  email: emailSchema
});

const ResetPassword: React.FC = () => {
  const intl = useIntl();

  const history = useHistory();

  return (
    <div className="flex flex-col h-full w-full">
      <Helmet bodyAttributes={{ style: `background-image: url(${bgLoginImg})` }} />

      <div className="flex flex-col bg-white md:rounded-lg my-auto sm:max-w-[800px] mx-auto items-center w-full">
        <Spacer xs={7} />

        <div className="flex flex-col sm:max-w-[400px] w-[320px] sm:w-full px-5">
          <div className="text-2xl text-black text-center">
            <FormattedMessage id={translations.pages.forgotPassword.title} />
          </div>

          <Form
            schema={schema}
            subscription={{ submitError: true, submitting: true, values: true, valid: true, dirty: true }}
            onSubmit={({ email }) => sendResetPasswordLink(email).then(() => history.push(urls.forgotPassword.sent))}
          >
            {({ submitError, submitting, values, valid, dirty }) => (
              <div className="flex flex-col">
                <Spacer xs={6} />

                <div className="text-sm font-normal text-flyblack-light-gray">
                  <FormattedMessage id={translations.pages.forgotPassword.subtitle} />
                </div>

                <Spacer xs={5} />

                <Form.Field
                  is={TextInputWithValidation}
                  id="email"
                  name="email"
                  type="text"
                  leadingIcon="email"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.email.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                />

                <Spacer xs={6} />

                {!!submitError && (
                  <React.Fragment>
                    <SubmitError error={submitError} />

                    <Spacer xs={2} />
                  </React.Fragment>
                )}

                <Button fat type="submit" className="font-medium" appearance="black" disabled={submitting}>
                  <FormattedMessage id={translations.pages.forgotPassword.resetButton} />
                </Button>

                <Spacer xs={4} />

                <Link type="button" className="text-sm text-center text-black" to={urls.signIn}>
                  <FormattedMessage id={translations.pages.forgotPassword.backButton} />
                </Link>

                <Spacer xs={6} />
              </div>
            )}
          </Form>
        </div>
      </div>

      <Spacer xs={10} />
    </div>
  );
};

export default ResetPassword;
