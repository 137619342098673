import React from 'react';
import classnames from 'classnames';

export type Appearance = 'transparent' | 'green' | 'red' | 'gray' | 'grey';

const appearanceToClassNameMap: Record<Appearance, string> = {
  transparent: 'text-white hover:bg-flyblack-dark-gray hover:transition-colors',
  green: 'text-[#32AC6B] bg-[#32AC6B] bg-opacity-10',
  red: 'text-[#C23636] bg-[#C23636] bg-opacity-10',
  gray: 'text-black bg-black bg-opacity-10',
  grey: 'text-black bg-black bg-opacity-10'
};

export interface Props {
  appearance: Appearance;
  className?: string;
}

const BoxedContent: React.FC<Props> = ({ className, appearance, children, ...rest }) => (
  <div
    className={classnames(
      'flex justify-around items-center rounded-sm min-h-[2em] py-0 px-4',
      className,
      appearance && appearanceToClassNameMap[appearance]
    )}
    {...rest}
  >
    {children}
  </div>
);

export default BoxedContent;
