import React from 'react';
import classnames from 'classnames';
import { FormattedDate } from 'react-intl';

import { Airport } from '@flyblack/common/domains';
import { formatDuration } from '@flyblack/common/util';

import Icon from '@flyblack/common/components/Icon';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  sourceAirport: Airport;
  destinationAirport: Airport;
  duration: number;
  departureDate: string;
  arrivalDate: string;
  eventName?: string;
  className?: string;
}

const TripSection: React.FC<Props> = ({
  sourceAirport,
  destinationAirport,
  duration,
  departureDate,
  arrivalDate,
  eventName,
  className
}) => (
  <div className={classnames('flex flex-col p-6 sm:px-0 w-full max-w-[480px]', className)}>
    {eventName && (
      <React.Fragment>
        <Spacer xs={2} />

        <Typography is="span" type="halcyon" className="text-center">
          {eventName}
        </Typography>
      </React.Fragment>
    )}

    <Spacer xs={2} sm={4} />

    <div className="flex flex-row justify-between items-center">
      <div className="text-2xl">{sourceAirport.displayCode}</div>

      <div className="h-[1px] flex justify-center items-center  bg-flyblack-gray w-full mx-6 relative">
        <Icon type="plane" className="p-1 bg-black" />
      </div>

      <div className="text-2xl">{destinationAirport.displayCode}</div>
    </div>

    <div className="flex flex-row justify-between">
      <Typography is="span" type="hummingbird" className="flex-1" color="lightGray">
        {sourceAirport.name}
      </Typography>

      <Typography is="span" type="hummingbird" className="flex-1 flex-grow-[0.5] text-center" color="lightGray">
        {formatDuration(duration)}
      </Typography>

      <Typography is="span" type="hummingbird" className="flex-1 text-right" color="lightGray">
        {destinationAirport.name}
      </Typography>
    </div>

    <Spacer xs={2} />

    <div className="flex flex-row justify-between">
      <div className="flex flex-col">
        <Typography is="span" type="hummingbird" className="flex-1">
          <FormattedDate value={departureDate} month="long" day="numeric" />
        </Typography>

        <Typography is="span" type="hummingbird" className="flex-1">
          <FormattedDate value={departureDate} hour="numeric" minute="numeric" />
        </Typography>
      </div>

      <div className="flex flex-col text-right">
        <Typography is="span" type="hummingbird" className="flex-1">
          <FormattedDate value={arrivalDate} month="long" day="numeric" />
        </Typography>

        <Typography is="span" type="hummingbird" className="flex-1">
          <FormattedDate value={arrivalDate} hour="numeric" minute="numeric" />
        </Typography>
      </div>
    </div>

    <Spacer xs={2} sm={4} />
  </div>
);

export default TripSection;
