import React from 'react';

export const useStateWithCallback = <T>(initialState?: T) => {
  const [state, setState] = React.useState(initialState);
  const myCallbacksList = React.useRef([]);

  const setStateWithCallback = (newState: T, callback: () => any) => {
    setState(newState);
    if (callback) {
      myCallbacksList.current.push(callback);
    }
  };

  React.useEffect(() => {
    myCallbacksList.current.forEach((callback) => callback());
    myCallbacksList.current = [];
  }, [state]);

  return <[T, (newState: T, callback: () => any) => void]>[state, setStateWithCallback];
};
