import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Spacer from '@flyblack/common/components/Spacer';
import Icon, { Type } from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  titleId: string;
  value;
  className: string;
  icon?: Type;
}

export const StatItem: React.FC<Props> = ({ titleId, value, className, icon }) => (
  <div className={classnames('flex flex-col', className)}>
    {!!icon && (
      <React.Fragment>
        <Icon type={icon} className="text-2xl" />

        <Spacer xs={2} />
      </React.Fragment>
    )}

    <Typography is="span" type="hummingbird" color="lightGray">
      <FormattedMessage id={titleId} />
    </Typography>

    <Typography is="span" type="halcyon" color="white">
      {value}
    </Typography>
  </div>
);
