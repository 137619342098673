import { Me, MediaFileType, Pricing, UpdatePassword } from '@flyblack/common/domains';
import { authorizedApi } from '@/services/network/authorized-api';
import { ClientCreditResponse, ClientMe, ConciergeUserResponse } from '@/domains/me';
import { uploadImage } from './media';

export const getMe = (): Promise<Me> =>
  authorizedApi.get<{ user: ClientMe }>(`/v1/users/me`).then(({ user, ...rest }) => ({
    ...user,
    ...rest
  }));

export const updatePassword = (data: UpdatePassword): Promise<any> => authorizedApi.put(`/v1/users/me/password`, data);

export const updateMe: (data: ClientMe) => Promise<any> = ({ profilePictureUrl, identityDocumentUrl, ...rest }) => {
  const promises: Promise<string>[] = [];

  promises.push(uploadImage(profilePictureUrl, MediaFileType.ProfilePicture));
  promises.push(uploadImage(identityDocumentUrl, MediaFileType.Id));

  return Promise.all(promises).then((urls) =>
    authorizedApi.put<ClientMe>(`/v1/users/me`, { ...rest, profilePictureUrl: urls[0], identityDocumentUrl: urls[1] })
  );
};

export const getReferralBonus = () => authorizedApi.get<Pricing>(`/v1/users/referral-bonus`);

export const getCredits = () => authorizedApi.get<ClientCreditResponse>(`/v1/users/me/credit`);

export const getConciergeCredentials = () => authorizedApi.get<ConciergeUserResponse>(`/v1/users/me/concierge`);
