import { MembershipStatus } from '@/domains/membership';

export const hasValidMembership = (membershipStatus: MembershipStatus) =>
  [MembershipStatus.Active, MembershipStatus.ActiveAndRenewable].includes(membershipStatus);

export const shouldPayMembership = (membershipStatus: MembershipStatus) =>
  [MembershipStatus.AwaitingPayment, MembershipStatus.ActiveAndRenewable, MembershipStatus.Expired].includes(
    membershipStatus
  );

export const shouldRenewMembership = (membershipStatus: MembershipStatus) =>
  [MembershipStatus.ActiveAndRenewable, MembershipStatus.Expired].includes(membershipStatus);
