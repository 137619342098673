import React from 'react';
import { useIntl } from 'react-intl';

import { Gender } from '@flyblack/common/domains';

import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@flyblack/common/components/Select';

export interface Props extends Omit<SelectProps<Gender>, 'items'> {}

const GenderSelect: React.FC<Props> = ({ ...props }) => {
  const intl = useIntl();

  const items = Object.values(Gender).map((gender) => ({
    value: gender,
    name: intl.formatMessage({ id: translations.domains.gender[gender] })
  }));

  return <Select {...props} items={items} />;
};

export default GenderSelect;
