import React from 'react';
import classnames from 'classnames';
import { Popover } from '@headlessui/react';

import { getFlightRoutes } from '@/services/api/flight-route';

import useLoad from '@flyblack/common/hooks/useLoad';

import Loading from '@flyblack/common/components/Loading';
import Typography from '@flyblack/common/components/Typography';
import Icon from '@flyblack/common/components/Icon';

import FlightPickerPanel from './FlightPickerPanel';

const JetShuttleRoutes = () => {
  const { value: flightRoutes, loading } = useLoad({ load: getFlightRoutes });

  return loading ? (
    <Loading visible={loading} center className="pt-[100px] pb-10 md:pb-14">
      <Loading.Indicator size={100} borderWidth={2} />
    </Loading>
  ) : (
    !!flightRoutes.length && (
      <div className="pb-10 md:pb-14">
        {flightRoutes.map(({ id, source, destination }) => (
          <Popover key={id} className="relative">
            {({ open }) => (
              <React.Fragment>
                <Popover.Button
                  className={classnames(
                    `w-full cursor-pointer border border-solid border-white rounded-sm mb-4 py-3 px-4
                    transition-colors group hover:bg-white hover:text-black`,
                    open && 'bg-white text-black'
                  )}
                >
                  <div className="relative mx-auto flex justify-between items-center md:w-[382px]">
                    <Typography is="div" type="halcyon">
                      {source.city.name}
                    </Typography>
                    <div
                      className={classnames(
                        `flex-grow h-0 mx-4 border-b border-dashed transition-colors
                        group-hover:border-flyblack-lighter-gray md:mx-9`,
                        open ? 'border-flyblack-lighter-gray' : 'border-flyblack-gray'
                      )}
                    />
                    <Typography is="div" type="halcyon">
                      {destination.city.name}
                    </Typography>
                    <Icon type="plane" className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </div>
                </Popover.Button>

                <Popover.Panel
                  className="absolute bottom-4 translate-y-full border-t border-b border-solid border-black w-full z-10 bg-white text-black"
                  tabIndex={1}
                >
                  <FlightPickerPanel flightRouteId={id} />
                </Popover.Panel>
              </React.Fragment>
            )}
          </Popover>
        ))}
      </div>
    )
  );
};

export default JetShuttleRoutes;
