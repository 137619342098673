import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Popover } from '@headlessui/react';

export interface Props {
  button: React.ComponentType;
  className?: string;
}

const Popup = ({ button: ButtonComponent, className, children }: PropsWithChildren<Props>) => {
  return (
    <Popover className={classnames('hidden md:block relative', className)}>
      <Popover.Button className=" w-full flex items-center">
        <ButtonComponent />
      </Popover.Button>

      <Popover.Panel className="z-20 bg-white text-black md:w-[362px] absolute -bottom-4 translate-y-full left-1/2 -translate-x-1/2">
        {children}
      </Popover.Panel>
    </Popover>
  );
};

export default Popup;
