import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Typography from '@flyblack/common/components/Typography';

interface Props {
  last4: string;
}

const ACHContent: React.FC<Props> = ({ last4 }) => {
  return (
    <div className="flex flex-col">
      <Typography is="div" type="hummingbird">
        <FormattedMessage id={translations.pages.checkout.paymentSection.ach.title} values={{ last4 }} />
      </Typography>
      <Typography is="div" type="hummingbird" className="text-flyblack-darker-gray pt-1">
        <FormattedMessage id={translations.pages.checkout.paymentSection.ach.detail} />
      </Typography>
    </div>
  );
};

export default ACHContent;
