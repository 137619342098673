import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { PassengerListItemWithFlights, Pet } from '@flyblack/common/domains';
import { LegOption } from '@/domains/charter';

import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

import PetForm from './PetForm';
import PetItem from './PetItem';
import PassengerItem from './PassengerItem';
import PassengerForm from './PassengerForm';

interface Props {
  tripId: number;
  passengers: PassengerListItemWithFlights[];
  passengerLimit?: number;
  pets?: Pet[];
  legOptions?: LegOption[];
  flightId?: number;
  className?: string;
  onAction: () => any;
}

const PassengersSection: React.FC<Props> = ({
  passengers,
  className,
  pets,
  passengerLimit = 0,
  tripId,
  legOptions,
  flightId,
  onAction
}) => {
  const [showNewPassengerForm, setShowNewPassengerForm] = React.useState(false);

  const [showNewPetForm, setShowNewPetForm] = React.useState(false);

  return (
    <div
      className={classnames(
        'bg-white flex flex-col w-full p-6 mb-auto text-black max-w-[480px]',
        pets ? 'pb-4' : 'pb-6',
        className
      )}
    >
      <Typography is="span" type="hummingbird" weight="medium" className="uppercase tracking-[2px]">
        <FormattedMessage
          id={translations.pages.myTrips.detail.passengersSection.passengerTitle}
          values={{
            limit: passengerLimit,
            count: passengers.length
          }}
        />
      </Typography>

      <Spacer xs={2} />

      {passengers.map((passenger, index) => (
        <PassengerItem
          key={index}
          index={index}
          passenger={passenger}
          onAction={onAction}
          tripId={tripId}
          legOptions={legOptions}
          flightId={flightId}
        />
      ))}

      {pets && pets.length > 0 && (
        <React.Fragment>
          <Typography is="span" type="hummingbird" weight="medium" className="uppercase tracking-[2px] mt-4">
            <FormattedMessage
              id={translations.pages.myTrips.detail.passengersSection.petTitle}
              values={{ count: pets.length }}
            />
          </Typography>

          <Spacer xs={2} />

          {pets.map((pet, index) => (
            <PetItem
              key={index}
              index={index}
              pet={pet}
              onAction={onAction}
              tripId={tripId}
              legOptions={legOptions}
              flightId={flightId}
            />
          ))}
        </React.Fragment>
      )}

      <Spacer xs={4} />

      {showNewPassengerForm && (
        <PassengerForm
          initialData={legOptions && flightId ? { jetCharterFlightIds: [flightId] } : null}
          tripId={tripId}
          legOptions={legOptions}
          onSuccess={() => {
            setShowNewPassengerForm(false);
            onAction();
          }}
          onCancel={() => setShowNewPassengerForm(false)}
        />
      )}

      {showNewPetForm && (
        <PetForm
          initialData={legOptions && flightId ? { jetCharterFlightIds: [flightId] } : null}
          tripId={tripId}
          legOptions={legOptions}
          onSuccess={() => {
            setShowNewPetForm(false);
            onAction();
          }}
          onCancel={() => setShowNewPetForm(false)}
        />
      )}

      {!showNewPassengerForm && !showNewPetForm && (
        <React.Fragment>
          <Button
            appearance="black"
            fat
            className="w-full"
            onClick={() => setShowNewPassengerForm(true)}
            disabled={passengerLimit === 0 ? false : passengers.length >= passengerLimit}
          >
            <FormattedMessage id={translations.inputs.buttons.addPassengers} />
          </Button>

          {pets && (
            <React.Fragment>
              <Spacer xs={1} />

              <Button
                appearance="transparent"
                className="w-full hover:bg-transparent text-black"
                onClick={() => setShowNewPetForm(true)}
              >
                <FormattedMessage id={translations.inputs.buttons.flyingWithPets} />
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default PassengersSection;
