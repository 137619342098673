import React from 'react';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { ClientMe } from '@/domains/me';
import { translations } from '@/locale';

import Avatar from '@flyblack/common/components/Avatar';
import Button from '@flyblack/common/components/Button';
import Container from '@flyblack/common/components/Container';
import Typography from '@flyblack/common/components/Typography';
import { useHistory } from 'react-router-dom';

export interface Props {
  data: ClientMe;
}

const ProfileCardSection: React.FC<Props> = ({ data }) => {
  const history = useHistory();

  return (
    <div className="bg-white w-full min-h-[250px] py-8 flex flex-col items-center relative">
      <Container className="flex flex-col h-full sm:flex-row max-w-4xl items-center">
        <Avatar className="w-32 h-32" image={data.profilePictureUrl} />

        <div className="flex flex-col mt-8 mb-4 items-center sm:items-baseline sm:px-8 md:px-12">
          <Typography is="span" type="halcyon" className="text-black">
            {`${data.firstName} ${data.lastName}`}
          </Typography>

          <Typography is="span" type="hummingbird" color="lightGray">
            {`${data.email}`}
          </Typography>

          {data.phoneNumber && (
            <Typography is="span" type="hummingbird" color="lightGray">
              {`${data.phoneNumber}`}
            </Typography>
          )}
        </div>
      </Container>

      <Button
        type="button"
        onClick={() => history.push(urls.profile.edit)}
        fat
        appearance="lightGhost"
        className="w-40 sm:absolute sm:right-8 sm:top-14"
      >
        <FormattedMessage id={translations.pages.profile.edit} />
      </Button>
    </div>
  );
};

export default ProfileCardSection;
