import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import { Card, PaymentMethod, PaymentMethodType, CheckoutStatus, Pricing } from '@flyblack/common/domains';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';

import TOSSection from '@/components/Checkout/TOSSection';
import BillingAddressSection from '@/components/Checkout/BillingAddressSection';
import PaymentMethodSection from '@/components/Checkout/PaymentMethodSection';

import FlightCarbonOffsetSection from './FlightCarbonOffsetSection';

interface Props {
  payCarbonOffset: boolean;
  setPayCarbonOffset: React.Dispatch<React.SetStateAction<boolean>>;
  carbonOffset: Pricing;
  loadingPrice: boolean;
  setCheckoutStatus: React.Dispatch<React.SetStateAction<CheckoutStatus>>;
  loadingPaymentMethods: boolean;
  paymentMethods: PaymentMethod[];
  reloadPaymentMethods: () => void;
  onSubmit: (values: any) => any;
}

const schema = yup.object({
  selectedPaymentMethodId: yup.string().required(),
  tos: yup
    .boolean()
    .oneOf([true], translations.validation.custom.acceptAgreement)
    .label(translations.inputs.termsAndConditions)
    .required()
});

const FlightCheckoutForm: React.FC<Props> = ({
  payCarbonOffset,
  setPayCarbonOffset,
  carbonOffset,
  loadingPrice,
  setCheckoutStatus,
  loadingPaymentMethods,
  paymentMethods,
  reloadPaymentMethods,
  onSubmit
}) => {
  const intl = useIntl();

  const initialValues = {
    ...(paymentMethods &&
      paymentMethods.length && {
        selectedPaymentMethodId: paymentMethods[0].id,
        ...(paymentMethods[0].type === PaymentMethodType.Card && (paymentMethods[0] as Card).address)
      }),
    tos: false
  };

  return (
    <Form
      id="checkout"
      schema={schema}
      subscription={{
        errors: true,
        submitting: true,
        submitError: true,
        dirty: true,
        values: true,
        valid: true
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ submitError, submitting, values, valid, form, errors, dirty }, formId) => (
        <React.Fragment>
          <Typography is="div" type="flamingo" className="pb-10">
            <FormattedMessage id={translations.pages.checkout.paymentSection.title} />
          </Typography>

          <FlightCarbonOffsetSection
            loadingPrice={loadingPrice}
            payCarbonOffset={payCarbonOffset}
            setPayCarbonOffset={() => setPayCarbonOffset((state) => !state)}
            carbonOffset={carbonOffset}
          />

          <Spacer xs={5} />

          <PaymentMethodSection
            title={intl.formatMessage({ id: translations.pages.checkout.paymentSection.subTitle })}
            loadingPaymentMethods={loadingPaymentMethods}
            paymentMethods={paymentMethods}
            reloadPaymentMethods={reloadPaymentMethods}
            setCheckoutStatus={setCheckoutStatus}
            change={form.change}
          />

          {paymentMethods &&
            paymentMethods.find(
              (paymentMethod) =>
                paymentMethod.id === values.selectedPaymentMethodId && paymentMethod.type === PaymentMethodType.Card
            ) && <BillingAddressSection formId={formId} submitting={submitting} />}

          <Spacer xs={2} />

          <TOSSection
            formId={formId}
            submitting={submitting}
            values={values}
            change={form.change}
            paragraphs={translations.pages.checkout.tosSection.paragraphs}
          />

          <Spacer xs={2} />

          <Button
            form={formId}
            type="submit"
            appearance="white"
            loading={submitting}
            disabled={!dirty || !valid}
            className="w-full"
          >
            <FormattedMessage id={translations.pages.checkout.bookFlightButton} />
          </Button>
        </React.Fragment>
      )}
    </Form>
  );
};

export default FlightCheckoutForm;
