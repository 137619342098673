import React from 'react';
import classnames from 'classnames';
import { parse, format } from 'date-fns';

import TextInput, { Props as TextInputProps } from '@flyblack/common/components/Form/Input/TextInput';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';
import DateRangePicker from '@flyblack/common/components/Calendar/DateRangePicker';

const dateDisplayFormat = 'MM/dd/yyyy';

interface Props extends Omit<TextInputProps, 'value'> {
  startDateValue: string;
  endDateValue: string;
  changeStartDate: (name: string, value: any) => void;
  changeEndDate: (name: string, value: any) => void;
}

const DateRangePickerInput = ({
  changeStartDate,
  changeEndDate,
  inputClassName,
  className,
  startDateValue,
  endDateValue,
  name,
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [startName, endName] = name ? [`${name}Start`, `${name}End`] : [null, null];

  let timeoutID = null;

  const onFocus = (event) => {
    if (!rest.disabled) {
      clearTimeout(timeoutID);
      if (!isOpen) {
        setIsOpen(true);
      }
      rest.onFocus && rest.onFocus(event);
    }
  };

  const onBlur = (event) => {
    if (!rest.disabled) {
      timeoutID = setTimeout(() => {
        if (isOpen) {
          setIsOpen(false);
        }
      }, 0);
      rest.onBlur && rest.onBlur(event);
    }
  };

  const value =
    !startDateValue && !endDateValue
      ? ''
      : startDateValue === endDateValue
      ? format(new Date(startDateValue), dateDisplayFormat)
      : `${format(new Date(startDateValue), dateDisplayFormat)} - ${format(new Date(endDateValue), dateDisplayFormat)}`;

  return (
    <div className={classnames('relative', className)} onFocus={onFocus} onBlur={onBlur} tabIndex={-1}>
      <TextInput {...rest} value={value} name={name} leadingIcon="calendar" readOnly />

      {isOpen && (
        <DateRangePicker
          className="absolute top-[100%] left-0 z-[1]"
          firstDate={startDateValue ? parse(startDateValue, dateFormat, new Date()) : null}
          secondDate={endDateValue ? parse(endDateValue, dateFormat, new Date()) : null}
          selectDates={(startDate: Date, endDate: Date) => {
            if (startName && startDate) {
              changeStartDate(startName, format(startDate, dateFormat));
            }
            if (endName && endDate) {
              changeEndDate(endName, format(endDate, dateFormat));
            }
          }}
        />
      )}
    </div>
  );
};

export default DateRangePickerInput;
