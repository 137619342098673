import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import JetCharterDetail from '@/page/Home/JetCharterDetail';
import JetChartersSearch from './Search/JetChartersSearch';
import RoundTripResults from './Results/RoundTripResults';
import MultiLegResults from './Results/MultiLegResults';
import OneWayResults from './Results/OneWayResults';

export interface Props {}

const JetCharters: React.FC<Props> = () => {
  return (
    <Switch>
      <Route exact path={urls.jetCharters.oneWay} component={OneWayResults} />

      <Route exact path={urls.jetCharters.roundTrip} component={RoundTripResults} />

      <Route exact path={urls.jetCharters.multiLeg} component={MultiLegResults} />

      <Route exact path={urls.jetCharters.detail} component={JetCharterDetail} />

      <Route path={urls.jetCharters.base} component={JetChartersSearch} />

      <ConsumeRedirectRoute render={() => <Redirect to={urls.jetCharters.base} />} />
    </Switch>
  );
};

export default JetCharters;
