import React from 'react';
import SendBirdDesk from 'sendbird-desk';

import Loading from '@flyblack/common/components/Loading';
import ScrollTrigger from '@flyblack/common/components/ScrollTrigger';
import { SendBirdContext } from '@/components/Context/SendBird';

import Ticket from '../Ticket';

const OPENED_TICKET_LIST_CHANNEL_HANDLER_ID = 'opened-ticket-list-channel-handler';

interface Props {
  ticketList: SendBirdDesk.Ticket[];
  setTicketList: React.Dispatch<React.SetStateAction<SendBirdDesk.Ticket[]>>;
  selectedTicket: SendBirdDesk.Ticket;
  selectTicket: (ticket: SendBirdDesk.Ticket) => void;
  loadingCreateTicket: boolean;
  createTicket: () => void;
}

const OpenedTicketList = ({
  ticketList,
  setTicketList,
  selectedTicket,
  selectTicket,
  loadingCreateTicket,
  createTicket
}: Props) => {
  const { sendBird } = React.useContext(SendBirdContext);

  const [loading, setLoading] = React.useState(false);
  const [hasMoreTickets, setHasMoreTickets] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    SendBirdDesk.Ticket.getOpenedList(0, (openedTickets, err) => {
      // TODO: handle error
      if (!err) {
        setTicketList(openedTickets);
        // @ts-ignore: Property 'defaultLimit' does not exist
        setHasMoreTickets(openedTickets.length >= SendBirdDesk.Ticket.defaultLimit);

        if (openedTickets.length === 0) {
          createTicket();
        } else {
          selectTicket(openedTickets[0]);
        }
      }
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    const channelHandler = new sendBird.ChannelHandler();

    channelHandler.onChannelChanged = (channel) => {
      SendBirdDesk.Ticket.getByChannelUrl(channel.url, (ticket, err) => {
        // TODO: handle error
        if (!err) {
          setTicketList((ticketList) => {
            let newTicketList = [...ticketList];
            const ticketIndex = newTicketList.findIndex((currentTicket) => currentTicket.id === ticket.id);

            const isTicketInList = ticketIndex >= 0;
            const isInitial = ticket.status === SendBirdDesk.Ticket.Status.INITIALIZED;
            const isClosed = ticket.status === SendBirdDesk.Ticket.Status.CLOSED;
            const isNewest = ticketIndex === 0;

            // update ticket
            if (isTicketInList) {
              newTicketList[ticketIndex] = ticket;
            }

            // update ticket list
            if (!isNewest && !isClosed) {
              if (isTicketInList) {
                newTicketList = newTicketList.filter((currentTicket) => currentTicket.id !== ticket.id);
              }
              if (isTicketInList || !isInitial) {
                newTicketList = [ticket, ...newTicketList];
              }
            }

            return newTicketList;
          });
        }
      });
    };

    sendBird.addChannelHandler(OPENED_TICKET_LIST_CHANNEL_HANDLER_ID, channelHandler);

    return () => {
      sendBird.removeChannelHandler(OPENED_TICKET_LIST_CHANNEL_HANDLER_ID);
    };
  }, []);

  const loadTickets = () => {
    if (!loading && hasMoreTickets) {
      setLoading(true);
      SendBirdDesk.Ticket.getOpenedList(ticketList.length, (openedTickets, err) => {
        // TODO: handle error
        if (!err) {
          setTicketList((currentTicketList) => [...currentTicketList, ...openedTickets]);
          // @ts-ignore: Property 'defaultLimit' does not exist
          setHasMoreTickets(openedTickets.length >= SendBirdDesk.Ticket.defaultLimit);
        }
        setLoading(false);
      });
    }
  };

  const onTicketClick = (ticket: SendBirdDesk.Ticket) => {
    // if the selected ticket is new and no message was sent, we need to remove it from the list
    if (selectedTicket && selectedTicket.status === SendBirdDesk.Ticket.Status.INITIALIZED) {
      setTicketList((currentTicketList) =>
        currentTicketList.filter((currentTicket) => currentTicket.id !== selectedTicket.id)
      );
    }
    selectTicket(ticket);
  };

  return (
    <ScrollTrigger className="w-[368px] h-full" onBottomReached={loadTickets}>
      <Loading visible={loadingCreateTicket} center className="h-16">
        <Loading.Indicator size={32} borderWidth={2} center />
      </Loading>

      {ticketList &&
        ticketList.map((ticket) => (
          <Ticket
            key={ticket.id}
            ticket={ticket}
            isSelected={selectedTicket && selectedTicket.id === ticket.id}
            onClick={() => onTicketClick(ticket)}
          />
        ))}

      <Loading visible={loading} center className="h-16">
        <Loading.Indicator size={32} borderWidth={2} center />
      </Loading>
    </ScrollTrigger>
  );
};

export default OpenedTicketList;
