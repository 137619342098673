import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import { useImagePreview } from '@flyblack/common/hooks/useImagePreview';

import Form from '@flyblack/common/components/Form';
import Link from '@flyblack/common/components/Link';
import Spacer from '@flyblack/common/components/Spacer';
import Avatar from '@flyblack/common/components/Avatar';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import ToggleSwitch from '@flyblack/common/components/ToggleSwitch';
import TextWithLabel from '@flyblack/common/components/TextWithLabel';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

export interface Props {
  formId: string;
  submitting: boolean;
  valid: boolean;
  values: any;
}

const ToggleSwitchWithValidation = withValidation(ToggleSwitch);

const Summary: React.FC<Props> = ({ formId, submitting, valid, values }) => {
  const intl = useIntl();

  const { preview: profilePicturePreview } = useImagePreview(values.profilePictureUrl);
  const { preview: identityDocumentPreview } = useImagePreview(values.identityDocumentUrl);

  return (
    <div className="flex justify-center">
      <div className="flex flex-col p-5 w-full max-w-[420px]">
        <Avatar className="w-32 h-32 mx-auto" image={profilePicturePreview} />

        <Spacer xs={3} />

        <Typography is="span" type="halcyon" className="text-center">
          {`${values.firstName} ${values.lastName}`}
        </Typography>

        <Typography is="span" type="hummingbird" className="text-center" color="lightGray">
          {values.email}
        </Typography>

        <Typography is="span" type="hummingbird" className="text-center mb-28" color="lightGray">
          {values.phoneNumber}
        </Typography>

        <div className="h-px bg-flyblack-gray" />

        <Spacer xs={4} />

        <Typography is="span" type="hummingbird" className=" uppercase tracking-[2px]">
          <FormattedMessage id={translations.pages.membership.application.steps.thirdStep.personalInfo} />
        </Typography>

        <Spacer xs={4} />

        <div className="flex">
          <TextWithLabel
            label={intl.formatMessage({ id: translations.inputs.nationality.label })}
            text={values.nationality}
            className="w-1/2"
          />

          <TextWithLabel
            label={intl.formatMessage({ id: translations.inputs.gender.label })}
            text={intl.formatMessage({ id: translations.domains.gender[values.gender] })}
            className="w-1/2"
          />
        </div>

        <Spacer xs={4} />

        <div className="flex">
          <TextWithLabel
            label={intl.formatMessage({ id: translations.inputs.dateOfBirth.label })}
            text={intl.formatDate(values.birthDate, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              timeZone: 'UTC'
            })}
            className="w-1/2"
          />

          <TextWithLabel
            label={intl.formatMessage({ id: translations.inputs.weight.label })}
            text={values.weight}
            className="w-1/2"
          />
        </div>

        <Spacer xs={5} />

        <div className="h-px bg-flyblack-gray" />

        <Spacer xs={4} />

        <Typography is="span" type="hummingbird" className=" uppercase tracking-[2px]">
          <FormattedMessage id={translations.pages.membership.application.steps.thirdStep.idCard} />
        </Typography>

        <Spacer xs={4} />

        <Avatar image={identityDocumentPreview} className="w-full max-w-[420px] h-[200px] rounded-none" />

        <Spacer xs={4} />

        <div className="h-px bg-flyblack-gray" />

        <Spacer xs={4} />

        <Typography is="span" type="hummingbird" className=" uppercase tracking-[2px]">
          <FormattedMessage id={translations.pages.membership.application.steps.thirdStep.aboutYou} />
        </Typography>

        <Spacer xs={4} />

        <div className="flex">
          <TextWithLabel
            label={intl.formatMessage({ id: translations.inputs.workplace.label })}
            text={values.workplace}
            className="w-1/2"
          />

          <TextWithLabel
            label={intl.formatMessage({ id: translations.inputs.workRole.label })}
            text={values.workRole}
            className="w-1/2"
          />
        </div>

        <Spacer xs={4} />

        <TextWithLabel
          label={intl.formatMessage({ id: translations.inputs.applicationReason.label })}
          text={values.applicationReason}
        />

        <Spacer xs={5} />

        <div className="h-px bg-flyblack-gray" />

        <Spacer xs={5} />

        <div className="flex flex-row items-center justify-between">
          <Typography is="span" type="hummingbird" className="max-w-[200px]">
            <FormattedMessage
              id={translations.pages.membership.application.steps.thirdStep.termsAndConditions}
              values={{
                terms: (children) => (
                  <Link external underlined to={urls.legal.terms}>
                    {children}
                  </Link>
                )
              }}
            />
          </Typography>

          <Form.Field
            is={ToggleSwitchWithValidation}
            id={`${formId}-termsAndConditions`}
            name="termsAndConditions"
            type="checkbox"
            readOnly={submitting}
          />
        </div>

        <Spacer xs={6} />

        <Button type="submit" appearance="white" loading={submitting} disabled={!valid}>
          <FormattedMessage id={translations.pages.membership.application.steps.thirdStep.apply} />
        </Button>
      </div>
    </div>
  );
};

export default Summary;
