import React from 'react';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { searchCharters } from '@/services/api/charter';
import { CharterQuoteListItem } from '@/domains/charter';

import NoSearchResults from '@/components/NoSearchResults';
import Spacer from '@flyblack/common/components/Spacer';
import Loading from '@flyblack/common/components/Loading';
import Typography from '@flyblack/common/components/Typography';
import InfiniteScroll from '@flyblack/common/components/InfiniteScroll';

import ResultsSearchRow from './ResultsSearchRow';
import ResultCardItem from './ResultCardItem';

const searchStringToFilters = (searchString: string) => {
  const query = queryString.parse(searchString);
  const legs = JSON.parse(query.legs as string);

  return {
    paxNum: parseInt(query.paxNum as string),
    fromDate: legs[0].fromDate,
    fromICAO: legs[0].fromICAO,
    toICAO: legs[0].toICAO
  };
};

export interface Props {}

const OneWayResults: React.FC<Props> = () => {
  const history = useHistory();

  const searchString = useLocation().search;

  const [filters, setFilters] = React.useState(searchStringToFilters(searchString));

  React.useEffect(() => {
    setFilters(searchStringToFilters(searchString));
  }, [searchString]);

  const updateUrl = (newFilters) => {
    if (!isEqual(searchStringToFilters(searchString), newFilters)) {
      history.push(
        queryString.stringifyUrl({
          url: urls.jetCharters.oneWay,
          query: {
            paxNum: newFilters.paxNum,
            legs: JSON.stringify([
              { fromDate: newFilters.fromDate, fromICAO: newFilters.fromICAO, toICAO: newFilters.toICAO }
            ])
          }
        })
      );
    }
  };

  return (
    <div className="bg-black min-h-full w-full">
      <div className="flex flex-col w-full mx-auto px-6 max-w-[866px]">
        <Typography is="div" type="flamingo" className="pt-10 pb-6 md:pt-14">
          <FormattedMessage id={translations.pages.jetCharters.results.oneWay} />
        </Typography>

        <Spacer xs={0} sm={4} />

        <ResultsSearchRow
          values={filters}
          onChange={(name: string, value: any) => updateUrl({ ...filters, [name]: value })}
        />

        <InfiniteScroll
          key={JSON.stringify(filters)}
          source={() =>
            searchCharters(filters.paxNum, [
              { fromDate: filters.fromDate, fromICAO: filters.fromICAO, toICAO: filters.toICAO }
            ])
          }
          className="grid grid-cols-1 sm:grid-cols-2 gap-8 min-h-[600px] h-[600px]"
        >
          {({ data, loading }) =>
            !loading && data.length === 0 ? (
              <NoSearchResults />
            ) : (
              <React.Fragment>
                {data.map((item: CharterQuoteListItem) => (
                  <ResultCardItem paxNum={filters.paxNum} data={item} key={item.id} />
                ))}

                <Loading visible={loading} center className="mt-[250px]">
                  <Loading.Indicator size={100} borderWidth={2} />
                </Loading>
              </React.Fragment>
            )
          }
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default OneWayResults;
