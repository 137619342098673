import React from 'react';
import { range } from 'lodash';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { AircraftCategory, FlightType } from '@flyblack/common/domains';

import Icon from '@flyblack/common/components/Icon';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import DoubleContentWithIcon from '@flyblack/common/components/DoubleContentWithIcon';

interface Props {
  pilots: string[];
  numberOfSeats: number;
  type: FlightType;
  purchasedSeats?: number;
  showMoreSeats?: boolean;
}

const TripSectionSeats: React.FC<Props> = ({ pilots, numberOfSeats, type, purchasedSeats, showMoreSeats = false }) => {
  const intl = useIntl();

  const flightTypeToChairsSectionMap: Record<FlightType, React.ReactNode> = {
    [FlightType.Shuttle]: range(numberOfSeats).map((number) => (
      <Icon
        key={number}
        type="chair"
        sizeClassName="text-[24px] leading-[24px] md:text-[36px] md:leading-[36px]"
        className={classnames(number < purchasedSeats ? 'text-white' : 'text-flyblack-dark-gray')}
      />
    )),
    [FlightType.Deal]: range(numberOfSeats).map((number) => (
      <Icon
        key={number}
        type="chair"
        sizeClassName="text-[24px] leading-[24px] md:text-[36px] md:leading-[36px]"
        className="text-white"
      />
    )),
    [FlightType.Charter]: range(numberOfSeats).map((number) => (
      <Icon
        key={number}
        type="chair"
        sizeClassName="text-[24px] leading-[24px] md:text-[36px] md:leading-[36px]"
        className="text-white"
      />
    ))
  };

  return (
    <React.Fragment>
      <DoubleContentWithIcon
        icon="seat"
        noSpacer
        top={
          <Typography is="span" type="hummingbird" color="lightGray">
            <FormattedMessage id={translations.pages.myTrips.detail.tripSection.seats} />
          </Typography>
        }
        bottom={
          <Typography is="span" type="halcyon">
            <FormattedMessage
              id={translations.pages.myTrips.detail.tripSection.seatNumber}
              values={{
                seats: numberOfSeats,
                plusSign: showMoreSeats
              }}
            />
          </Typography>
        }
      />

      <Spacer xs={2} />

      <div className="flex">
        <div className="w-4  md:w-6 mr-5" />

        <div className="flex flex-wrap items-center gap-2 md:gap-4">{flightTypeToChairsSectionMap[type]}</div>
      </div>

      <Spacer xs={4} />

      <div className="flex items-center w-full">
        <DoubleContentWithIcon
          className="w-1/2"
          icon="crew"
          noSpacer
          top={
            <Typography is="span" type="hummingbird" color="lightGray">
              <FormattedMessage id={translations.pages.myTrips.detail.tripSection.firstPilot} />
            </Typography>
          }
          bottom={
            <Typography is="span" type="halcyon">
              {!pilots[0] ? intl.formatMessage({ id: translations.pages.myTrips.detail.tripSection.tba }) : pilots[0]}
            </Typography>
          }
        />

        <DoubleContentWithIcon
          className="w-1/2"
          noSpacer
          top={
            <Typography is="span" type="hummingbird" color="lightGray">
              <FormattedMessage id={translations.pages.myTrips.detail.tripSection.secondPilot} />
            </Typography>
          }
          bottom={
            <Typography is="span" type="halcyon">
              {!pilots[1] ? intl.formatMessage({ id: translations.pages.myTrips.detail.tripSection.tba }) : pilots[1]}
            </Typography>
          }
        />
      </div>
    </React.Fragment>
  );
};

export default TripSectionSeats;
