import {
  PaymentMethodResponse,
  PaymentIntentResponse,
  PaymentMethodType,
  CarbonOffsetState,
  PaymentChargeRequest
} from '@flyblack/common/domains';

import { authorizedApi } from '../network/authorized-api';

export const getPaymentMethods: () => Promise<PaymentMethodResponse> = () => authorizedApi.get(`/v1/payments/methods`);

export const createPaymentIntent = ({
  productId,
  typeOfProduct,
  payCarbonOffset,
  seats,
  code,
  discountType
}: PaymentChargeRequest) =>
  authorizedApi.post<PaymentIntentResponse>(`/v1/payments/card`, {
    productId,
    paymentForType: typeOfProduct,
    carbonOffset: payCarbonOffset ? CarbonOffsetState.Included : CarbonOffsetState.NotIncluded,
    seats,
    code,
    discountType
  });

export const getLinkToken: () => Promise<unknown> = () => authorizedApi.get(`/v1/payments/link-token`);

export const createACHPaymentMethod: (accountId: string, publicToken: string) => Promise<unknown> = (
  accountId,
  publicToken
) => authorizedApi.post(`/v1/payments/methods`, { paymentMethodType: PaymentMethodType.ACH, accountId, publicToken });

export const payWithACH = ({
  bankAccountId,
  productId,
  typeOfProduct,
  payCarbonOffset,
  seats,
  code,
  discountType
}: PaymentChargeRequest) =>
  authorizedApi.post(`/v1/payments/ach`, {
    bankAccountId,
    productId,
    paymentForType: typeOfProduct,
    carbonOffset: payCarbonOffset ? CarbonOffsetState.Included : CarbonOffsetState.NotIncluded,
    seats,
    code,
    discountType
  });

export const createClientSecret = () => authorizedApi.post(`/v1/payments/methods/secret`);

export const deletePaymentMethod = (id: string, type: PaymentMethodType) =>
  authorizedApi.delete(`/v1/payments/methods`, { paymentMethodId: id, type });
