import React from 'react';
import classnames from 'classnames';
import { AdminMessage, FileMessage, UserMessage } from 'sendbird';

import { getDateString } from '@/util/chat';

import Typography from '@flyblack/common/components/Typography';
import Link from '@flyblack/common/components/Link';
import Icon from '@flyblack/common/components/Icon';

export type SendBirdMessage = UserMessage | FileMessage | AdminMessage;

interface Props {
  message: SendBirdMessage;
  showCreatedAt?: boolean;
  isMyMessage?: boolean;
}

const MessageBubble = ({ message, showCreatedAt, isMyMessage }: Props) => {
  return (
    <div className={classnames('flex w-full', isMyMessage ? 'justify-end' : '')}>
      <div className={classnames('flex flex-col', { 'items-end': isMyMessage })}>
        <div
          className={classnames(
            'p-8 max-w-[520px]',
            isMyMessage ? 'bg-black border border-solid border-white' : 'bg-white',
            showCreatedAt ? 'mb-2' : 'mb-1'
          )}
        >
          {message.isFileMessage() ? (
            <Link to={message.url} external className="flex">
              <Icon type="document" appearance={isMyMessage ? 'white' : 'black'} />
              <Typography is="div" type="halcyon" color={isMyMessage ? 'white' : 'black'} className="pl-2">
                {message.name}
              </Typography>
            </Link>
          ) : (
            <Typography is="div" type="halcyon" color={isMyMessage ? 'white' : 'black'}>
              {message.message}
            </Typography>
          )}
        </div>
        {!!showCreatedAt && (
          <Typography is="div" type="hummingbird" color="lightGray" className="mb-8">
            {getDateString(message.createdAt)}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default MessageBubble;
