import React from 'react';
import classnames from 'classnames';

import Typography from '@flyblack/common/components/Typography';

interface Props {
  name: string;
  value: string;
  className?: string;
  fat?: boolean;
}

const QuoteItem = ({ name, value, className, fat }: Props) => {
  return (
    <div className={classnames('flex justify-between', className)}>
      <Typography
        is="div"
        type={fat ? 'halcyon' : 'hummingbird'}
        weight={fat ? 'bold' : 'regular'}
        className="text-flyblack-light-gray"
      >
        {name}
      </Typography>
      <Typography is="div" type={fat ? 'halcyon' : 'hummingbird'} weight={fat ? 'bold' : 'regular'} color="black">
        {value}
      </Typography>
    </div>
  );
};

export default QuoteItem;
