import React from 'react';
import classnames from 'classnames';
import { Disclosure } from '@headlessui/react';

import Typography from '@flyblack/common/components/Typography';
import Icon from '@flyblack/common/components/Icon';

import DisclosureContent from './DisclosureContent';

interface Props {
  title: string;
  children: any;
  defaultOpen?: boolean;
  isWhite?: boolean;
  showOverflow?: boolean;
}

const Accordion = ({ title, defaultOpen, isWhite, showOverflow, children }: Props) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <React.Fragment>
          <Disclosure.Button className="flex justify-between w-full py-2 uppercase">
            <Typography is="span" type="hummingbird" color={isWhite ? 'white' : 'black'}>
              {title}
            </Typography>
            <Icon
              type="chevronDown"
              appearance={isWhite ? 'white' : 'black'}
              className={classnames('transition-transform', { 'rotate-180': open })}
            />
          </Disclosure.Button>

          <DisclosureContent showOverflow={showOverflow} open={open}>
            {children}
          </DisclosureContent>
        </React.Fragment>
      )}
    </Disclosure>
  );
};

export default Accordion;
