import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { urls } from '@/constants';
import { MembershipStatus } from '@/domains/membership';
import { getNewMembershipId } from '@/services/api/membership';
import useLoad from '@flyblack/common/hooks/useLoad';

import Button from '@flyblack/common/components/Button';
import Container from '@flyblack/common/components/Container';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import { SessionContext } from '@/components/Context/Session';

const RenewMembershipBar = () => {
  const intl = useIntl();
  const history = useHistory();

  const { me, loading: loadingMe } = React.useContext(SessionContext);

  const { value, loading: loadingNewMembershipId } = useLoad({
    load: getNewMembershipId,
    id: me.membership.id
  });

  return (
    !loadingMe &&
    !loadingNewMembershipId && (
      <div className="h-52 md:h-36 lg:h-24">
        <div className="fixed inset-x-0 flex items-center bottom-0 h-52 md:h-36 lg:h-24 text-black bg-white w-full">
          <Container max className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex flex-col max-w-[320px] md:max-w-[380px] lg:max-w-[540px]">
              <Typography is="span" type="halcyon" className="pb-2">
                <FormattedMessage
                  id={
                    translations.pages.membership.information.apply.renewal[
                      me.membership.status === MembershipStatus.ActiveAndRenewable
                        ? 'activeAndRenewableTitle'
                        : 'expiredTitle'
                    ]
                  }
                  values={{
                    date: intl.formatDate(me.membership.endsAt, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })
                  }}
                />
              </Typography>
              <Typography is="span" type="hummingbird" color="darkerGray">
                <FormattedMessage id={translations.pages.membership.information.apply.renewal.description} />
              </Typography>
            </div>

            <Spacer xs={2} md={0} />

            <Button
              fat
              appearance="black"
              onClick={() => history.push(urls.membership.checkout.get(value.id))}
              className="w-full max-w-[320px]"
            >
              <FormattedMessage id={translations.inputs.buttons.renewMembership} />
            </Button>
          </Container>
        </div>
      </div>
    )
  );
};

export default RenewMembershipBar;
