import React from 'react';
import { useHistory } from 'react-router';
import { isEqual, startOfDay } from 'date-fns';

import { urls } from '@/constants';
import { getShuttlesOfRoute } from '@/services/api/flight-route';

import useLoad from '@flyblack/common/hooks/useLoad';

import RouteDatePicker from '@flyblack/common/components/Calendar/RouteDatePicker';
import Loading from '@flyblack/common/components/Loading';

interface Props {
  flightRouteId: number;
}

const FlightPickerPanel = ({ flightRouteId }: Props) => {
  const history = useHistory();

  const { value, loading } = useLoad({ load: getShuttlesOfRoute, id: flightRouteId });

  const [flightRoutes, setFlightRoutes] = React.useState([]);

  React.useEffect(() => {
    if (value) {
      setFlightRoutes(
        value.map((flight) => {
          return { id: flight.id, date: new Date(flight.schedule.departsAt) };
        })
      );
    }
  }, [value]);

  return loading ? (
    <Loading visible={loading} center className="pt-[100px] pb-10 md:pb-14">
      <Loading.Indicator size={100} borderWidth={2} />
    </Loading>
  ) : (
    <RouteDatePicker
      enabledDates={flightRoutes.map(({ date }) => date)}
      selectDate={(selectedDate) => {
        // TODO Give somehow to the Date picker the whole object and use the id of it, instead of comparing dates here
        const flight = flightRoutes.find(({ date }) => isEqual(startOfDay(date), startOfDay(selectedDate)));
        flight && history.push(urls.jetShuttles.detail.get(flight.id));
      }}
    />
  );
};

export default FlightPickerPanel;
