import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

import { StatItem } from './StatItem';

export interface Props {
  maximumPayload: string;
  cruiseSpeed: string;
  serviceCeiling: string;
  maximumRange: string;
  className?: string;
}

const StatsSection: React.FC<Props> = ({ maximumPayload, cruiseSpeed, serviceCeiling, maximumRange, className }) => {
  const text = translations.pages.aircraftDetail;

  return (
    <div
      className={classnames(
        'flex flex-col w-full max-w-[480px] lg:max-w-[380px] p-6 sm:px-0 lg:py-12 lg:border-solid lg:border-t lg:border-flyblack-gray',
        className
      )}
    >
      <Typography is="div" type="hummingbird" className="uppercase font-medium">
        <FormattedMessage id={text.stats} />
      </Typography>

      <Spacer xs={3} />

      <div className="flex flex-row">
        <StatItem titleId={text.maxPayload} value={maximumPayload} className="w-1/2" />

        <StatItem titleId={text.cruiseSpeed} value={cruiseSpeed} className="w-1/2" />
      </div>

      <Spacer xs={3} />

      <div className="flex flex-row">
        <StatItem titleId={text.propulsion} value={maximumRange} className="w-1/2" />

        <StatItem titleId={text.maxAltitude} value={serviceCeiling} className="w-1/2" />
      </div>
    </div>
  );
};

export default StatsSection;
