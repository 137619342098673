import { NewPassenger, Passenger } from '@flyblack/common/domains';

import { authorizedApi } from '../network/authorized-api';

export const createPassenger = (data: NewPassenger) => authorizedApi.post<Promise<number>>(`/v1/passengers`, data);

export const updatePassenger = ({ id, ...passengerData }: Passenger) =>
  authorizedApi.put<Promise<number>>(`/v1/passengers/${id}`, passengerData);

export const deletePassenger = (passengerId: number) =>
  authorizedApi.delete<Promise<number>>(`/v1/passengers/${passengerId}`);

export const deletePassengerFromFlight = (passengerId: number, jetCharterFlightId: number) =>
  authorizedApi.delete<Promise<number>>(`/v1/passengers/${passengerId}/jet-charter-flight`, { jetCharterFlightId });
