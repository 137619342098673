import React from 'react';
import queryString from 'query-string';
import arrayMutators from 'final-form-arrays';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getNow } from '@flyblack/common/util';

import Icon from '@flyblack/common/components/Icon';
import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import { SubmitError } from '@flyblack/common/components/Error';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';

import JetCharterSearchRow from './SearchRow';
import { multiLegSchema } from './Schema';

export interface Props {}

const MultiLegSearch: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <Form
      id="charter-multi-leg-search"
      subscription={{ errors: true, submitting: true, submitError: true, dirty: true, values: true, valid: true }}
      initialValues={{
        paxNum: 2,
        legs: [{ fromDate: getNow(dateFormat) }, { fromDate: getNow(dateFormat) }]
      }}
      schema={multiLegSchema}
      onSubmit={({ paxNum, legs }) =>
        history.push(
          queryString.stringifyUrl({
            url: urls.jetCharters.multiLeg,
            query: {
              paxNum: paxNum,
              legs: JSON.stringify([
                ...legs.map((leg) => ({ fromDate: leg.fromDate, fromICAO: leg.fromICAO, toICAO: leg.toICAO }))
              ])
            }
          })
        ) as any
      }
      className="w-full flex flex-col"
      mutators={{ ...arrayMutators }}
    >
      {({
        submitError,
        submitting,
        values,
        form,
        form: {
          mutators: { push }
        }
      }) => (
        <FieldArray name="legs">
          {({ fields }) => (
            <React.Fragment>
              {fields.map((name, legNumber) => (
                <JetCharterSearchRow
                  key={legNumber}
                  values={values}
                  submitting={submitting}
                  form={form}
                  withPassengers={legNumber === 0}
                  arrayName={name}
                  onDelete={legNumber > 0 ? () => fields.remove(legNumber) : null}
                />
              ))}

              <div className="flex flex-col sm:flex-row sm:justify-between">
                <Button
                  is="button"
                  type="button"
                  className="w-[120px] font-normal"
                  onClick={() => push('legs', { fromDate: getNow(dateFormat) })}
                >
                  <Icon type="add" />
                </Button>

                <Spacer xs={4} />

                {submitError && <SubmitError error={submitError} />}

                <Button type="submit" className="w-full md:w-[220px] font-normal ml-auto" appearance="white">
                  <FormattedMessage id={translations.inputs.buttons.getQuotes} />
                </Button>
              </div>

              <Spacer xs={10} />
            </React.Fragment>
          )}
        </FieldArray>
      )}
    </Form>
  );
};

export default MultiLegSearch;
