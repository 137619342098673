import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { urls } from '@/constants';

import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import Spacer from '@flyblack/common/components/Spacer';

interface Props {
  className?: string;
}

const BookingRequestSentSection = ({ className }: Props) => {
  const history = useHistory();

  return (
    <div className={classnames('bg-white flex flex-col w-full p-6 mb-auto text-black max-w-[480px]', className)}>
      <Typography is="span" type="halcyon" weight="medium">
        <FormattedMessage id={translations.pages.myTrips.jetCharter.bookingRequestSent.title} />
      </Typography>

      <Spacer xs={2} />

      <Typography is="span" type="hummingbird" color="lightGray">
        <FormattedMessage id={translations.pages.myTrips.jetCharter.bookingRequestSent.detail} />
      </Typography>

      <Spacer xs={4} />

      <Typography is="span" type="hummingbird" color="lightGray">
        <FormattedMessage id={translations.pages.myTrips.jetCharter.bookingRequestSent.info} />
      </Typography>

      <Spacer xs={4} />

      <Button onClick={() => history.push(urls.concierge.base)} appearance="black" fat className="w-full">
        <FormattedMessage id={translations.inputs.buttons.getInTouch} />
      </Button>
    </div>
  );
};

export default BookingRequestSentSection;
