import React from 'react';

import { Page } from '@flyblack/common/domains';

export interface Options<K, T> {
  id?: K;
  size?: number;
  source: (page: number, size: number) => Promise<Page<T>>;
}

interface State<T> {
  page: number;
  more: boolean;
  totalNumberOfElements: number;
  totalNumberOfPages: number;
  data: T[];
  loading: boolean;
}

export interface Return<T> extends State<T> {
  loadPage: (number: number) => void;
  reload: () => void;
}

const initialState: State<any> = {
  page: 1,
  data: [],
  more: true,
  totalNumberOfPages: 1,
  totalNumberOfElements: 0,
  loading: true
};

const useScrollablePagination = <T, K>({ size = 15, ...options }: Options<K, T>): Return<T> => {
  const [state, setState] = React.useState<State<T>>(initialState);

  const fetchPage = (pageNumber: number) =>
    options
      .source(pageNumber, size)
      .then((response: Page<T>) =>
        setState(({ data, page }) => ({
          data: [...data, ...response.content],
          more: (page - 1) * size + response.pageSize < response.totalNumberOfElements,
          totalNumberOfElements: response.totalNumberOfElements,
          totalNumberOfPages: Math.ceil(response.totalNumberOfElements / size),
          page,
          loading: false
        }))
      )
      .catch(() => setState((previous) => ({ ...previous, loading: false })));

  const loadPage = (page: number) => {
    if (!state.more || page > state.totalNumberOfPages) return;

    setState({ ...state, page, loading: true });
    fetchPage(page);
  };

  const reload = () => {
    setState(initialState);
    fetchPage(initialState.page);
  };

  React.useEffect(reload, [options.id]);

  return {
    page: state.page,
    data: state.data,
    loading: state.loading,
    totalNumberOfPages: state.totalNumberOfPages,
    totalNumberOfElements: state.totalNumberOfElements,
    more: state.more,
    loadPage,
    reload
  };
};

export default useScrollablePagination;
