import React from 'react';
import { FormattedMessage } from 'react-intl';
import SendBirdDesk from 'sendbird-desk';

import { translations } from '@/locale';

import Loading from '@flyblack/common/components/Loading';
import ScrollTrigger from '@flyblack/common/components/ScrollTrigger';
import Typography from '@flyblack/common/components/Typography';
import { SendBirdContext } from '@/components/Context/SendBird';

import Ticket from '../Ticket';

const CLOSED_TICKET_LIST_CHANNEL_HANDLER_ID = 'closed-ticket-list-channel-handler';

interface Props {
  ticketList: SendBirdDesk.Ticket[];
  setTicketList: React.Dispatch<React.SetStateAction<SendBirdDesk.Ticket[]>>;
  selectedTicket: SendBirdDesk.Ticket;
  selectTicket: (ticket: SendBirdDesk.Ticket) => void;
}

const ClosedTicketList = ({ ticketList, setTicketList, selectedTicket, selectTicket }: Props) => {
  const { sendBird } = React.useContext(SendBirdContext);

  const [loading, setLoading] = React.useState(false);
  const [hasMoreTickets, setHasMoreTickets] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    SendBirdDesk.Ticket.getClosedList(0, (closedTickets, err) => {
      // TODO: handle error
      if (!err) {
        setTicketList(closedTickets);
        // @ts-ignore: Property 'defaultLimit' does not exist
        setHasMoreTickets(closedTickets.length >= SendBirdDesk.Ticket.defaultLimit);

        if (closedTickets.length > 0) {
          selectTicket(closedTickets[0]);
        }
      }
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    const channelHandler = new sendBird.ChannelHandler();

    channelHandler.onChannelChanged = (channel) => {
      SendBirdDesk.Ticket.getByChannelUrl(channel.url, (ticket, err) => {
        // TODO: handle error
        if (!err) {
          setTicketList((ticketList) => {
            let newTicketList = [...ticketList];
            const ticketIndex = newTicketList.findIndex((currentTicket) => currentTicket.id === ticket.id);

            const isTicketInList = ticketIndex >= 0;

            // update ticket
            if (isTicketInList) {
              newTicketList[ticketIndex] = ticket;
            }

            return newTicketList;
          });
        }
      });
    };

    sendBird.addChannelHandler(CLOSED_TICKET_LIST_CHANNEL_HANDLER_ID, channelHandler);

    return () => {
      sendBird.removeChannelHandler(CLOSED_TICKET_LIST_CHANNEL_HANDLER_ID);
    };
  }, []);

  const loadTickets = () => {
    if (!loading && hasMoreTickets) {
      setLoading(true);
      SendBirdDesk.Ticket.getClosedList(ticketList.length, (closedTickets, err) => {
        // TODO: handle error
        if (!err) {
          setTicketList((currentTicketList) => [...currentTicketList, ...closedTickets]);
          // @ts-ignore: Property 'defaultLimit' does not exist
          setHasMoreTickets(closedTickets.length >= SendBirdDesk.Ticket.defaultLimit);
        }
        setLoading(false);
      });
    }
  };

  return (
    <ScrollTrigger className="w-[368px] h-full" onBottomReached={loadTickets}>
      {ticketList &&
        (ticketList.length === 0 ? (
          <Typography is="div" type="halcyon" className="flex justify-center items-center h-full text-center p-8">
            <FormattedMessage id={translations.pages.concierge.list.emptyClosedTicketList} />
          </Typography>
        ) : (
          ticketList.map((ticket) => (
            <Ticket
              key={ticket.id}
              ticket={ticket}
              isSelected={selectedTicket && selectedTicket.id === ticket.id}
              onClick={() => selectTicket(ticket)}
            />
          ))
        ))}

      <Loading visible={loading} center className="h-16">
        <Loading.Indicator size={32} borderWidth={2} center />
      </Loading>
    </ScrollTrigger>
  );
};

export default ClosedTicketList;
