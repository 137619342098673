import React from 'react';
import SendBirdDesk from 'sendbird-desk';
import { GroupChannel } from 'sendbird';

import ClosedTicketList from './ClosedTicketList';
import MessageList from '../MessageList';
import ConciergeTabs, { ConciergeTab } from '../ConciergeTabs';

const ClosedTicketContent = () => {
  const [closedTicketList, setClosedTicketList] = React.useState<SendBirdDesk.Ticket[]>(null);
  const [selectedTicket, setSelectedTicket] = React.useState<SendBirdDesk.Ticket>(null);

  const selectTicket = (ticket: SendBirdDesk.Ticket) => {
    setSelectedTicket(ticket);
    (ticket.channel as GroupChannel).markAsRead();
  };

  return (
    <div className="flex h-full">
      <div className="flex flex-col border-r border-solid border-flyblack-dark-gray">
        <ConciergeTabs selectedTab={ConciergeTab.Closed} />

        <ClosedTicketList
          ticketList={closedTicketList}
          setTicketList={setClosedTicketList}
          selectedTicket={selectedTicket}
          selectTicket={selectTicket}
        />
      </div>

      {selectedTicket && (
        <div className="flex flex-1 w-full flex-col">
          <MessageList ticket={selectedTicket} className="flex-auto" />
        </div>
      )}
    </div>
  );
};

export default ClosedTicketContent;
