import React from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { BARLOW_URL } from '@/constants';

import { getStripePromise } from '@/services/stripe';

interface Props {
  children: any;
}

const StripeElementsProvider = ({ children }: Props) => {
  const promise = React.useMemo(getStripePromise, []);

  return (
    <Elements
      stripe={promise}
      options={{
        fonts: [{ cssSrc: BARLOW_URL }]
      }}
    >
      {children}
    </Elements>
  );
};

export default StripeElementsProvider;
