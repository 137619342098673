import React from 'react';
import { useHistory } from 'react-router-dom';

import { urls } from '@/constants';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getNow } from '@flyblack/common/util';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import { SubmitError } from '@flyblack/common/components/Error';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';

import JetCharterSearchRow from './SearchRow';
import { oneWaySchema } from './Schema';

export interface Props {
  initialValues?;
}

const OneWaySearch: React.FC<Props> = ({ initialValues }) => {
  const history = useHistory();

  return (
    <Form
      id="charter-one-way-search"
      schema={oneWaySchema}
      subscription={{ errors: true, submitting: true, submitError: true, dirty: true, values: true, valid: true }}
      initialValues={
        initialValues
          ? { ...initialValues }
          : {
              paxNum: 2,
              fromDate: getNow(dateFormat)
            }
      }
      onSubmit={(values) => {
        return history.push(
          queryString.stringifyUrl({
            url: urls.jetCharters.oneWay,
            query: {
              paxNum: values.paxNum,
              legs: JSON.stringify([
                {
                  fromDate: values.fromDate,
                  fromICAO: values.fromICAO,
                  toICAO: values.toICAO
                }
              ])
            }
          })
        ) as any;
      }}
      className="w-full flex flex-col"
    >
      {({ submitError, submitting, values, valid, form, errors, dirty }, formId) => (
        <React.Fragment>
          <JetCharterSearchRow submitting={submitting} form={form} values={values} withPassengers />

          <Spacer xs={4} />

          {submitError && <SubmitError error={submitError} />}

          <Button type="submit" className="w-full md:w-[220px] font-normal ml-auto" appearance="white">
            <FormattedMessage id={translations.inputs.buttons.getQuotes} />
          </Button>

          <Spacer xs={10} />
        </React.Fragment>
      )}
    </Form>
  );
};

export default OneWaySearch;
