import React, { useState } from 'react';
import { ElementProps } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import classnames from 'classnames';

// TODO Use colors from tailwind
const cardStyle = {
  base: {
    fontFamily: '"Barlow", sans-serif',
    lineHeight: '20px',
    '::placeholder': {
      color: '#a3a3a3'
    }
  },
  invalid: {
    color: '#B91C1C'
  }
};

interface Props extends ElementProps {
  is: any;
  label: any;
  options?: object;
  className?: string;
  onFocus?: () => any;
  onBlur?: () => any;
  onChange?: (event: StripeElementChangeEvent) => any;
}

const StripeElement = ({ is: Component, label, options, className, onFocus, onBlur, onChange, ...rest }: Props) => {
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState<object>();

  const handleFocus = () => {
    setFocus(true);
    onFocus && onFocus();
  };

  const handleBlur = () => {
    setFocus(false);
    onBlur && onBlur();
  };

  const handleChange = (event: StripeElementChangeEvent) => {
    setError(event.error);
    onChange && onChange(event);
  };

  return (
    <label
      className={classnames(
        'block text-xs -mb-1 text-left',
        className,
        error ? 'text-flyblack-red' : 'text-flyblack-light-gray'
      )}
    >
      {label}
      <Component
        options={{
          style: cardStyle,
          classes: {
            base: classnames(
              'py-2 border-b border-solid text-black',
              error ? 'border-flyblack-red' : focus ? 'border-black' : 'border-flyblack-light-gray'
            )
          },
          ...options
        }}
        {...rest}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </label>
  );
};

export default StripeElement;
