import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

export interface Props {
  description: string;
  className?: string;
}

const AboutSection: React.FC<Props> = ({ description, className }) => (
  <div className={classnames('bg-white flex flex-col w-full max-w-[480px] p-6', className)}>
    <Typography type="hummingbird" is="span" className="uppercase tracking-[2px]" color="black">
      <FormattedMessage id={translations.pages.aircraftDetail.about} />
    </Typography>

    <Spacer xs={2} />

    <Typography type="hummingbird" is="span" color="lightGray">
      {description}
    </Typography>
  </div>
);
export default AboutSection;
