import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { sortPhotos } from '@flyblack/common/util';
import { getAircraftCategories } from '@/services/api/aircraft-type';

import useLoad from '@flyblack/common/hooks/useLoad';
import Spacer from '@flyblack/common/components/Spacer';
import ClientPhotoGallery from '@/components/ClientPhotoGallery';

import TitleSection from './TitleSection';
import AboutSection from './AboutSection';
import StatsSection from './StatsSection';

export interface Props {
  type: 'aircraftCategory' | 'aicraftType';
}
interface Params {
  id: string;
}

const AircraftCategoryDetail: React.FC<Props & RouteComponentProps<Params>> = ({ match, type }) => {
  const { value: data, loading } = useLoad({
    load: () =>
      getAircraftCategories().then((response) =>
        response.categories.find((item) => item.id === parseInt(match.params.id))
      )
  });

  return (
    !loading && (
      <div className="bg-black min-h-full w-full flex flex-col items-center overflow-auto">
        <Spacer xs={5} sm={8} />

        <div className="flex flex-wrap w-full justify-around lg:justify-between max-w-4xl">
          <TitleSection
            title={`${data.name}`}
            manufacturer={data.manufacturer}
            numberOfSeats={data.seats}
            crewNumber={data.crewNumber}
            className="order-0"
          />

          <ClientPhotoGallery images={sortPhotos(data.photos)} loading={loading} className="order-1 mb-6 lg:mb-0" />

          <AboutSection description={data.description} className="order-2 lg:order-3" />

          <StatsSection
            maximumPayload={data.maximumPayload}
            cruiseSpeed={data.cruiseSpeed}
            serviceCeiling={data.serviceCeiling}
            maximumRange={data.maximumRange}
            className="order-3 lg:order-2"
          />
        </div>
        <Spacer xs={8} />
      </div>
    )
  );
};

export default AircraftCategoryDetail;
