import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import MyTripsList from './MyTripsList';
import MyTripDetail from './MyTripsDetail/MyTripDetail';

export interface Props {}

const MyTrips: React.FC<Props> = () => {
  return (
    <Switch>
      <Route path={urls.myTrips.detail.base} component={MyTripDetail} />

      <Route exact path={urls.myTrips.base} component={MyTripsList} />

      <ConsumeRedirectRoute render={() => <Redirect to={urls.jetDeals.base} />} />
    </Switch>
  );
};

export default MyTrips;
