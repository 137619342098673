import { translations } from '@/locale';
import * as yup from 'yup';

const paxNumSchema = yup
  .number()
  .positive()
  .max(11)
  .required();

const fromICAOSchema = yup
  .string()
  .label(translations.inputs.searchBars.charterDeparture.label)
  .test('differentThanDestination', translations.validation.custom.charterIdenticalLocations, function(value) {
    const destinationAirportId: number = this.resolve(yup.ref('toICAO'));

    return !destinationAirportId ? true : destinationAirportId !== value;
  })
  .required();

const toICAOSchema = yup
  .string()
  .label(translations.inputs.searchBars.charterDestination.label)
  .test('differentThanDeparture', translations.validation.custom.charterIdenticalLocations, function(value) {
    const destinationAirportId: number = this.resolve(yup.ref('fromICAO'));

    return !destinationAirportId ? true : destinationAirportId !== value;
  })
  .required();

export const multiLegSchema = yup.object({
  paxNum: paxNumSchema,
  legs: yup.array().of(
    yup.object().shape({
      fromDate: yup.string().required(),
      fromICAO: fromICAOSchema,
      toICAO: toICAOSchema
    })
  )
});

export const roundTripSchema = yup.object({
  paxNum: paxNumSchema,
  fromDateStart: yup.string().required(),
  fromDateEnd: yup.string().required(),
  fromICAO: fromICAOSchema,
  toICAO: toICAOSchema
});

export const oneWaySchema = yup.object({
  paxNum: paxNumSchema,
  fromDate: yup.string().required(),
  fromICAO: fromICAOSchema,
  toICAO: toICAOSchema
});
