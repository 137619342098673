import { Page, EmptyLegListItem, FlightPrice } from '@flyblack/common/domains';

import { authorizedApi } from '../network/authorized-api';

export const getEmptyLegs = (
  pageNumber: number,
  pageSize: number,
  options?: {
    [x: string]: any;
  }
) => authorizedApi.get<Page<EmptyLegListItem>>(`/v1/empty-legs`, { pageNumber, pageSize, ...options });

export const getEmptyLeg = (id: number) => authorizedApi.get<EmptyLegListItem>(`/v1/empty-legs/${id}`);

export const getEmptyLegPrice = (id: number, options?: { [x: string]: any }) =>
  authorizedApi.get<FlightPrice>(`/v1/empty-legs/${id}/price`, options);
