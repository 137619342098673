import React from 'react';
import classnames from 'classnames';

import { getDatesBetween } from '@flyblack/common/util';

import Card from '@flyblack/common/components/Card';
import Month from '@flyblack/common/components/Calendar/Month';
import MonthNavigation from '@flyblack/common/components/Calendar/MonthNavigation';
import { useMonthNavigation } from '@flyblack/common/components/Calendar/useMonthNavigation';

export interface Props {
  firstDate?: Date;
  secondDate?: Date;
  selectDates: (startDate: Date, endDate: Date) => any;
  className?: string;
}

const DateRangePicker = ({ firstDate, secondDate, selectDates, className }: Props) => {
  const { currentDate, goToPreviousMonth, goToNextMonth } = useMonthNavigation(firstDate || secondDate);

  const [isFirstDateClick, setIsFirstDateClick] = React.useState(
    (!firstDate && !secondDate) || !!(firstDate && secondDate)
  );
  const [selectedFirstDate, setSelectedFirstDate] = React.useState<Date | undefined | null>(
    (firstDate && secondDate && firstDate < secondDate) || (firstDate && !secondDate) ? firstDate : secondDate
  );
  const [selectedSecondDate, setSelectedSecondDate] = React.useState<Date | undefined | null>(
    (firstDate && secondDate && firstDate < secondDate) || (!firstDate && secondDate) ? secondDate : firstDate
  );
  const [selectedDateRange, setSelectedDateRange] = React.useState([]);

  const selectDate = (date: Date) => {
    if (isFirstDateClick) {
      setSelectedFirstDate(date);
      setSelectedSecondDate(null);
    } else {
      setSelectedSecondDate(date);
    }
    setIsFirstDateClick((state) => !state);
  };

  React.useEffect(() => {
    // @ts-ignore
    const dateRange = getDatesBetween(selectedFirstDate, selectedSecondDate); // returns ordered dates array
    // @ts-ignore
    setSelectedDateRange(dateRange);
    const currentStartDate = !!dateRange.length && dateRange[0];
    const currentEndDate = dateRange.length > 1 && dateRange[dateRange.length - 1];
    // @ts-ignore
    selectDates(currentStartDate, currentEndDate);
  }, [selectedFirstDate, selectedSecondDate]);

  return (
    <Card className={classnames('flex justify-center rounded-[2px]', className)} bg="white">
      <div className="px-6 py-3 flex flex-col justify-between">
        <div className="w-[308px] flex justify-between items-center pb-3">
          <MonthNavigation
            currentDate={currentDate}
            onGoToPreviousMonth={goToPreviousMonth}
            onGoToNextMonth={goToNextMonth}
          />
        </div>

        <div className="w-[308px]">
          <Month
            year={currentDate.getFullYear()}
            month={currentDate.getMonth()}
            selectedDate={selectedDateRange}
            onSelectDate={selectDate}
          />
        </div>
      </div>
    </Card>
  );
};

export default DateRangePicker;
