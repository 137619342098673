import cuid from 'cuid';
import { stringify } from 'query-string';

import { API } from '@/config';

import { CLIENT_ID } from '@/config';

import { HttpClient, HttpRequestConfig, HttpError } from './http';

enum Header {
  TraceId = 'X-B3-TraceId',
  SpanId = 'X-B3-SpanId',
  Timezone = 'X-App-Time-Zone',
  Platform = 'X-App-Platform',
  Type = 'X-App-Type',
  Version = 'X-App-Version',
  ClientId = 'clientId'
}

export class ApiClient extends HttpClient {
  constructor(options: HttpRequestConfig) {
    super(options);

    this.client.interceptors.request.use(this.setHeaders);
  }

  private setHeaders = (config: HttpRequestConfig): HttpRequestConfig => {
    const headers = config.headers || {};
    const traceId = headers[Header.TraceId] || `t-${cuid()}`;

    return {
      ...config,
      headers: {
        ...headers,
        [Header.TraceId]: traceId,
        [Header.SpanId]: traceId,
        [Header.Timezone]: Intl.DateTimeFormat().resolvedOptions().timeZone,
        [Header.Platform]: 'Web',
        [Header.Type]: 'customer-app',
        [Header.Version]: '0.0.1',
        [Header.ClientId]: CLIENT_ID
      }
    };
  };

  protected getReportOptions(error: HttpError<any>) {
    return {
      ...super.getReportOptions(error),
      traceId: error.config.headers[Header.TraceId]
    };
  }
}

export const api = new ApiClient({
  baseURL: API,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: (params) => stringify(params, { skipNull: true })
});
