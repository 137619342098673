import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { By, FlightType, Order, Sort, TripListItem, TripsFilter } from '@flyblack/common/domains';
import { getCharterLegSummaryDisplayData } from '@flyblack/common/util';
import { getMyTrips } from '@/services/api/trips';

import Loading from '@flyblack/common/components/Loading';
import Container from '@flyblack/common/components/Container';
import Typography from '@flyblack/common/components/Typography';
import InfiniteScroll from '@flyblack/common/components/InfiniteScroll';

import UnderlinedButton from '@/components/UnderlinedButton';

import MyTripsCardItem from './MyTripsCardItem';

const orderingOptionsByFilter: Record<TripsFilter, any> = {
  [TripsFilter.Upcoming]: { [Sort.Order]: Order.Ascendent },
  [TripsFilter.Past]: { [Sort.Order]: Order.Descendent }
};

const MyTripsList = () => {
  const [selectedFilter, setSelectedFilter] = React.useState(TripsFilter.Upcoming);

  const intl = useIntl();

  return (
    <div className="bg-black min-h-full">
      <Container className="md:max-w-3xl lg:max-w-4xl min-h-full">
        <div className="flex justify-between pb-6 my-20">
          <Typography is="div" type="flamingo">
            <FormattedMessage id={translations.pages.myTrips.title} />
          </Typography>

          <div className="flex gap-10">
            <UnderlinedButton
              active={selectedFilter === TripsFilter.Upcoming}
              onClick={() => setSelectedFilter(TripsFilter.Upcoming)}
              text={intl.formatMessage({ id: translations.pages.myTrips.filters.upcoming })}
            />

            <UnderlinedButton
              active={selectedFilter === TripsFilter.Past}
              onClick={() => setSelectedFilter(TripsFilter.Past)}
              text={intl.formatMessage({ id: translations.pages.myTrips.filters.history })}
            />
          </div>
        </div>

        <InfiniteScroll
          key={JSON.stringify(selectedFilter)}
          source={(pageNumber, size) =>
            getMyTrips(pageNumber, size, {
              ...(selectedFilter === TripsFilter.Upcoming ? { after: new Date().toISOString() } : null),
              ...(selectedFilter === TripsFilter.Past ? { before: new Date().toISOString() } : null),
              [Sort.By]: By.DepartureTime,
              ...orderingOptionsByFilter[selectedFilter]
            })
          }
          className="flex flex-col mb-6 min-h-[600px] h-[600px]"
        >
          {({ data, loading }) =>
            !loading && data.length === 0 ? (
              <div className="flex flex-col items-center mt-40">
                <Typography is="span" type="halcyon">
                  <FormattedMessage id={translations.pages.myTrips.empty[selectedFilter]} />
                </Typography>
              </div>
            ) : (
              <React.Fragment>
                {data.map((item: TripListItem) => {
                  if (item.type === FlightType.Charter) {
                    const tripData = getCharterLegSummaryDisplayData(item.jetCharterLegs, item.jetCharterType);
                    return (
                      <MyTripsCardItem
                        key={item.id}
                        id={item.id}
                        type={item.type}
                        status={item.status}
                        showInterval={item.jetCharterLegs.length > 1}
                        aircraftCategory={tripData.aircraftCategory}
                        aircraftType={tripData.aircraftType}
                        sourceCity={tripData.sourceAirport.city.name}
                        destinationCity={tripData.destinationAirport.city.name}
                        schedule={tripData.schedule}
                      />
                    );
                  }
                  return (
                    <MyTripsCardItem
                      key={item.id}
                      id={item.id}
                      aircraftCategory={item.aircraftCategory}
                      aircraftType={item.aircraftType}
                      eventName={item.eventName}
                      schedule={item.schedule}
                      sourceCity={item.sourceAirport.city.name}
                      destinationCity={item.destinationAirport.city.name}
                      purchasedSeats={item.purchasedSeats}
                      type={item.type}
                      status={item.status}
                    />
                  );
                })}

                <Loading visible={loading} center className="mt-[250px]">
                  <Loading.Indicator size={100} borderWidth={2} />
                </Loading>
              </React.Fragment>
            )
          }
        </InfiniteScroll>
      </Container>
    </div>
  );
};

export default MyTripsList;
