import { authorizedApi } from '../network/authorized-api';

import { FlightPrice, JetShuttleDetail, Page } from '@flyblack/common/domains';

export const getShuttles = (
  pageNumber: number,
  pageSize: number,
  options?: {
    [x: string]: any;
  }
) => authorizedApi.get<Page<JetShuttleDetail>>(`/v1/jet-shuttles`, { pageNumber, pageSize, ...options });

export const getShuttle = (id: number) => authorizedApi.get<JetShuttleDetail>(`/v1/jet-shuttles/${id}`);

export const getShuttlePrice = (id: number, seats: number, options?: { [x: string]: any }) =>
  authorizedApi.get<FlightPrice>(`/v1/jet-shuttles/${id}/seats/${seats}/price`, options);
