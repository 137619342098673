import React from 'react';
import classNames from 'classnames';

import Link from '@flyblack/common/components/Link';
import Icon, { Type } from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

export interface Props extends React.DOMAttributes<{}> {
  is?: React.ElementType;
  icon: Type;
  url?: string;
  title: string;
  subtitle?: string;
  withArrow?: boolean;
  className?: string;
}

const SettingItem: React.FC<Props> = ({
  is: Component = Link,
  icon,
  url,
  title,
  subtitle,
  withArrow = true,
  className,
  ...rest
}) => {
  return (
    <Component to={url} className={classNames('group flex flex-row items-center', className)} {...rest}>
      <Icon type={icon} className="mr-4" />

      <div className="flex flex-col">
        <Typography is="span" type="halcyon">
          {title}
        </Typography>

        {subtitle && (
          <Typography
            is="span"
            type="hummingbird"
            color="lightGray"
            className="group-hover:text-white transition-colors duration-300"
          >
            {subtitle}
          </Typography>
        )}
      </div>

      {withArrow && (
        <Icon
          type="forward"
          className="ml-auto opacity-0 group-hover:opacity-100 transition-all duration-300"
          sizeClassName="text-[24px] leading-[24px]"
        />
      )}
    </Component>
  );
};

export default SettingItem;
