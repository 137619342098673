import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

interface Props {}

const NoSearchResults = () => {
  return (
    <div className="flex flex-col items-center mt-20 sm:mt-40 w-full">
      <Typography is="span" type="halcyon">
        <FormattedMessage id={translations.pages.jetDeals.empty.title} />
      </Typography>

      <Spacer xs={2} />

      <Typography is="span" type="hummingbird" color="lightGray" className="text-center">
        <FormattedMessage id={translations.pages.jetDeals.empty.subtitle} />
      </Typography>
    </div>
  );
};

export default NoSearchResults;
