import React from 'react';
import classnames from 'classnames';

import Toggle, { Props as ToggleProps } from '@flyblack/common/components/Toggle';
import Icon from '@flyblack/common/components/Icon';

export type CheckboxAppearance = 'square' | 'circle';

const appearanceToClassNameMap: Record<CheckboxAppearance, string> = {
  square: 'rounded-sm',
  circle: 'rounded-full'
};

interface Props extends Omit<ToggleProps, 'renderToggle'> {
  appearance: CheckboxAppearance;
}

const Checkbox: React.FC<Props> = ({ className, ...props }) => (
  <Toggle
    {...props}
    className={classnames(props.disabled ? 'cursor-auto' : 'cursor-pointer', className)}
    toggleContainerClass="flex"
    renderToggle={(toggled, disabled) => (
      <div
        className={classnames(
          'relative inline-block w-[16px] h-[16px] border border-solid rounded-sm align-middle transition-colors',
          appearanceToClassNameMap[props.appearance],
          disabled
            ? 'bg-flyblack-lighter-gray border-flyblack-light-gray focus:border-gray-400'
            : 'bg-white border-black hover:border-gray-500 focus:border-gray-500'
        )}
      >
        <Icon
          type="check"
          className={classnames(
            'text-flyblack-gray font-bold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity',
            toggled ? 'opacity-100' : 'opacity-0'
          )}
          sizeClassName="text-[16px] leading-[16px]"
        />
      </div>
    )}
  />
);

export default Checkbox;
