import React from 'react';
import * as yup from 'yup';
import Helmet from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import { useQuery } from '@flyblack/common/hooks/useQuery';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SubmitError } from '@flyblack/common/components/Error';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import { SessionContext } from '@/components/Context/Session';

import { emailSchema, passwordSchema } from '../UnauthorizedSchemas';

import bgLoginImg from '@/assets/bg-login.jpg';

const TextInputWithValidation = withValidation(TextInput);

const schema = yup.object({
  email: emailSchema,
  password: passwordSchema
});

const SignInPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const query = useQuery();

  const resetPasswordMessage = !!query.get('reset-password');

  const { signInWithEmail } = React.useContext(SessionContext);

  return (
    <div className="flex flex-col h-full w-full">
      <Helmet bodyAttributes={{ style: `background-image: url(${bgLoginImg})` }} />

      <div className="flex flex-col bg-white md:rounded-lg my-auto sm:max-w-[800px] mx-auto items-center w-full">
        <Spacer xs={7} />

        <div className="flex flex-col sm:max-w-[400px] w-[320px] sm:w-full px-5">
          <div className="text-2xl text-black text-center">
            <FormattedMessage id={translations.pages.signIn.title} />
          </div>

          <Form
            schema={schema}
            subscription={{ submitError: true, submitting: true, values: true, valid: true, dirty: true }}
            onSubmit={({ email, password }) =>
              signInWithEmail(email, password).then(() => history.push(urls.jetDeals.base))
            }
          >
            {({ submitError, submitting }) => (
              <div className="flex flex-col">
                <Spacer xs={6} />

                {resetPasswordMessage && (
                  <div className="text-sm font-normal text-flyblack-light-gray text-center">
                    <FormattedMessage id={translations.pages.signIn.subtitle} />
                  </div>
                )}

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id="email"
                  name="email"
                  type="text"
                  leadingIcon="email"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.email.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                />

                <Spacer xs={4} />

                <div className="relative">
                  <Form.Field
                    is={TextInputWithValidation}
                    id="password"
                    name="password"
                    type="password"
                    inputClassName="pr-[40px]"
                    leadingIcon="password"
                    appearance="light"
                    label={intl.formatMessage({ id: translations.inputs.password.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.password.placeholder })}
                  />

                  <Link
                    to={urls.forgotPassword.main}
                    className="absolute text-xs text-black font-medium right-0 top-[23px]"
                  >
                    <FormattedMessage id={translations.pages.signIn.forgotPassword} />
                  </Link>
                </div>

                <Spacer xs={5} />

                {!!submitError && (
                  <React.Fragment>
                    <SubmitError error={submitError} />

                    <Spacer xs={2} />
                  </React.Fragment>
                )}

                <Button fat type="submit" className="font-medium" appearance="black" disabled={submitting}>
                  <FormattedMessage id={translations.pages.signIn.logInButton} />
                </Button>

                <Spacer xs={5} />

                <div className="text-sm text-center text-flyblack-light-gray">
                  <FormattedMessage
                    id={translations.pages.signIn.noAccountSignUp}
                    values={{
                      a: (children) => (
                        <Link to={urls.signUp} className="text-black">
                          {children}
                        </Link>
                      )
                    }}
                  />
                </div>

                <Spacer xs={7} />
              </div>
            )}
          </Form>
        </div>
      </div>

      <Spacer xs={10} />
    </div>
  );
};

export default SignInPage;
