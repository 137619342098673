import { useState } from 'react';
import { StripeElementChangeEvent } from '@stripe/stripe-js';

export const useCreditCardValidation = () => {
  const [cardNumber, setCardNumber] = useState(false);
  const [cardExpiry, setCardExpiry] = useState(false);
  const [cardCvc, setCardCvc] = useState(false);

  const handleCardFieldChange = (event: StripeElementChangeEvent) => {
    switch (event.elementType) {
      case 'cardNumber':
        setCardNumber(event.complete);
        break;
      case 'cardExpiry':
        setCardExpiry(event.complete);
        break;
      case 'cardCvc':
        setCardCvc(event.complete);
        break;
    }
  };

  return { isCardComplete: cardNumber && cardExpiry && cardCvc, handleCardFieldChange };
};
