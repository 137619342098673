import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';

import Icon from '@flyblack/common/components/Icon';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import DatePickerInput from '@flyblack/common/components/Form/Input/DatePickerInput';
import DateRangePickerInput from '@flyblack/common/components/Form/Input/DateRangePickerInput';

import CharterAirportSelect from '@flyblack/common/components/CharterAirportSelect';
import PassengerNumberSelect from '@/components/PassengerNumberSelect';

export interface Props {
  form;
  values;
  submitting;
  withPassengers?: boolean;
  withDateRange?: boolean;
  arrayName?: string;
  onDelete?;
}

const DatePickerInputWithValidation = withValidation(DatePickerInput);
const CharterAirportSelectWithValidation = withValidation(CharterAirportSelect);
const DateRangePickerInputWithValidation = withValidation(DateRangePickerInput);

const SearchRow: React.FC<Props> = ({
  submitting,
  form,
  values,
  withPassengers,
  withDateRange,
  arrayName,
  onDelete
}) => {
  const intl = useIntl();

  const dateSelect = withDateRange ? (
    <Form.Field
      is={DateRangePickerInputWithValidation}
      id={`from-date`}
      name={`${arrayName || ''}fromDate`}
      type="text"
      readOnly={submitting}
      change={form.change}
      startDateValue={values.fromDateStart}
      endDateValue={values.fromDateEnd}
      changeStartDate={form.change}
      changeEndDate={form.change}
      className="w-[210px] flex-1 mr-4"
    />
  ) : (
    <Form.Field
      is={DatePickerInputWithValidation}
      id={`from-date`}
      name={`${arrayName || ''}fromDate`}
      type="text"
      readOnly={submitting}
      change={form.change}
      className="w-[210px] flex-1 mr-4"
    />
  );

  return (
    <div className="flex flex-col gap-6 sm:gap-0 mb-12 sm:mb-6 sm:flex-row w-full">
      <div className="w-full relative mr-4">
        <Form.Field
          is={CharterAirportSelectWithValidation}
          id={`from-icao`}
          name={`${arrayName || ''}fromICAO`}
          type="text"
          leadingIcon="departure"
          placeholder={intl.formatMessage({ id: translations.inputs.searchBars.charterDeparture.placeholder })}
          readOnly={submitting}
          className="w-full flex-1"
          editable
        />
      </div>

      <div className="w-full mr-4">
        <Form.Field
          is={CharterAirportSelectWithValidation}
          id={`to-icao`}
          name={`${arrayName || ''}toICAO`}
          type="text"
          leadingIcon="destination"
          placeholder={intl.formatMessage({ id: translations.inputs.searchBars.charterDestination.placeholder })}
          readOnly={submitting}
          className="w-full flex-1"
          editable
        />
      </div>

      <div className="flex w-full">
        {dateSelect}

        {withPassengers ? (
          <Form.Field
            is={PassengerNumberSelect}
            id={`pax-num`}
            name={`paxNum`}
            type="text"
            leadingIcon="crew"
            inputClassName="pr-[10px]"
            className="w-20"
            readOnly={submitting}
          />
        ) : (
          <div className="ml-auto sm:ml-0 sm:w-20 flex">
            <Button
              is="button"
              appearance="ghost"
              className="h-[37px] md:w-[37px] ml-auto text-flyblack-light-gray hover:text-white border-none"
              type="button"
              onClick={onDelete}
            >
              <Icon type="bin" />
              <Typography is="span" type="hummingbird" weight="regular" className="ml-2 md:hidden">
                Remove leg
              </Typography>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchRow;
