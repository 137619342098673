import React from 'react';
import classnames from 'classnames';

import Typography from '@flyblack/common/components/Typography';

interface Props {
  className?: string;
  children: any;
}

const SectionTitle = ({ className, children }: Props) => {
  return (
    <Typography is="div" type="hummingbird" className={classnames('uppercase', className)}>
      {children}
    </Typography>
  );
};

export default SectionTitle;
