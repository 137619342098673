import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { updateMe } from '@/services/api/me';
import { STRING_LONG_MAX_LENGTH } from '@/constants';

import { WeightUnit } from '@flyblack/common/domains';
import { MAX_FILE_SIZE, SUPPORTED_IMAGE_FORMATS } from '@flyblack/common/constants';

import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SessionContext } from '@/components/Context/Session';
import Typography from '@flyblack/common/components/Typography';
import PictureUpload from '@flyblack/common/components/PictureUpload';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import DateInput from '@flyblack/common/components/Form/Input/DateInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import GenderSelect from '@/components/GenderSelect';
import RowContainer from '@flyblack/common/components/RowContainer';

const TextInputWithValidation = withValidation(TextInput);
const DateInputWithValidation = withValidation(DateInput);
const GenderSelectWithValidation = withValidation(GenderSelect);

const photoSchema = yup
  .mixed()
  .test({
    name: 'fileSize',
    message: translations.validation.custom.fileSize,
    test: (value) => !value || !value.size || value.size <= MAX_FILE_SIZE
  })
  .test({
    name: 'fileFormat',
    message: translations.validation.custom.fileFormat,
    test: (value) => !value || !value.type || SUPPORTED_IMAGE_FORMATS.includes(value.type)
  });

const schema = yup.object({
  firstName: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.firstName.label)
    .required(),
  lastName: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.lastName.label)
    .required(),
  email: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.email.label)
    .required(),
  phoneNumber: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.phoneNumber.label)
    .nullable()
    .notRequired(),
  birthDate: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.dateOfBirth.label)
    .nullable()
    .notRequired(),
  weight: yup
    .number()
    .label(translations.inputs.weight.label)
    .nullable()
    .notRequired(),
  profilePictureUrl: photoSchema
    .label(translations.pages.editProfile.profilePhoto)
    .nullable()
    .notRequired(),
  identityDocumentUrl: photoSchema
    .label(translations.pages.editProfile.id)
    .nullable()
    .notRequired()
});

const UserProfile: React.FC = () => {
  const { me, loading, reload } = React.useContext(SessionContext);

  const intl = useIntl();

  return (
    !loading && (
      <div className="bg-black min-h-full">
        <Form
          id="user"
          schema={schema}
          subscription={{ errors: true, submitting: true, submitError: true, dirty: true, values: true, valid: true }}
          initialValues={{ ...me, weight: me.weight ? me.weight.value : null }}
          onSubmit={(values) =>
            updateMe({
              id: me.id,
              ...values,
              weight: !!values.weight
                ? {
                    unit: WeightUnit.Pound,
                    value: values.weight
                  }
                : null
            }).then(reload)
          }
        >
          {({ submitError, submitting, values, valid, form, errors, dirty }, formId) => (
            <div className="flex flex-col items-center w-full">
              <div className="bg-white w-full py-8 sm:py-12 md:py-16 px-6 flex flex-col items-center justify-center">
                <Form.Field
                  id={`${formId}-profile-picture-url`}
                  is={PictureUpload}
                  name="profilePictureUrl"
                  readOnly={submitting}
                  shape="round"
                />

                <Spacer xs={2} />

                <Typography is="span" type="hummingbird" color="lightGray" className="text-center">
                  <FormattedMessage id={translations.pages.editProfile.profilePhoto} />
                </Typography>
              </div>

              <div className="flex flex-col px-6 w-full max-w-[528px]">
                <Spacer xs={7} />

                <RowContainer>
                  <RowContainer.Item col={6}>
                    <Form.Field
                      is={TextInputWithValidation}
                      id={`${formId}-first-name`}
                      name="firstName"
                      type="text"
                      label={intl.formatMessage({ id: translations.inputs.firstName.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.firstName.placeholder })}
                      readOnly={submitting}
                      leadingIcon="navigationAccount"
                    />
                  </RowContainer.Item>

                  <RowContainer.Item col={6}>
                    <Form.Field
                      is={TextInputWithValidation}
                      id={`${formId}-last-name`}
                      name="lastName"
                      type="text"
                      label={intl.formatMessage({ id: translations.inputs.lastName.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.lastName.placeholder })}
                      readOnly={submitting}
                    />
                  </RowContainer.Item>
                </RowContainer>

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${formId}-email`}
                  name="email"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.email.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                  readOnly={submitting}
                  leadingIcon="email"
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${formId}-phone-number`}
                  name="phoneNumber"
                  label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.phoneNumber.placeholder })}
                  readOnly={submitting}
                  leadingIcon="phone"
                />

                <Spacer xs={7} />

                <Typography is="span" type="hummingbird" className="uppercase tracking-[2px]">
                  <FormattedMessage id={translations.pages.editProfile.bookingInfo} />
                </Typography>

                <Spacer xs={4} />

                <RowContainer>
                  <RowContainer.Item col={6}>
                    <Form.Field
                      is={DateInputWithValidation}
                      id={`${formId}-birthday`}
                      name="birthDate"
                      type="text"
                      label={intl.formatMessage({ id: translations.inputs.dateOfBirth.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.dateOfBirth.placeholder })}
                      readOnly={submitting}
                      leadingIcon="calendar"
                    />
                  </RowContainer.Item>

                  <RowContainer.Item col={6}>
                    <Form.Field
                      is={TextInputWithValidation}
                      id={`${formId}-weight`}
                      name="weight"
                      type="text"
                      label={intl.formatMessage({ id: translations.inputs.weight.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.weight.placeholder })}
                      readOnly={submitting}
                      leadingIcon="weight"
                    />
                  </RowContainer.Item>
                </RowContainer>

                <Spacer xs={5} />

                <Typography is="span" type="hummingbird" color="lightGray">
                  <FormattedMessage id={translations.pages.editProfile.id} />
                </Typography>

                <Spacer xs={1} />

                <Form.Field
                  id={`${formId}-identity-document-url`}
                  is={PictureUpload}
                  name="identityDocumentUrl"
                  readOnly={submitting}
                  shape="rectangle"
                  icon="id"
                />

                <Spacer xs={10} />

                <div
                  className={classnames(
                    'fixed inset-x-0 bottom-0 w-full bg-white p-4 transition-opacity duration-500 justify-center z-10',
                    dirty ? 'opacity-100 flex' : 'opacity-0 hidden'
                  )}
                >
                  <div className="flex justify-between px-6 w-full max-w-[528px]">
                    <Button
                      appearance="lightGhost"
                      fat
                      form={formId}
                      onClick={() => form.reset()}
                      type="button"
                      className="w-1/2 mr-2"
                    >
                      <Typography is="span" type="halcyon">
                        <FormattedMessage id={translations.inputs.buttons.cancel} />
                      </Typography>
                    </Button>

                    <Button
                      appearance="black"
                      fat
                      className="w-1/2 ml-2"
                      form={formId}
                      loading={submitting}
                      disabled={!dirty || !valid}
                      type="submit"
                    >
                      <Typography is="span" type="halcyon">
                        <FormattedMessage id={translations.inputs.buttons.save} />
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>

        <Spacer xs={10} />
      </div>
    )
  );
};

export default UserProfile;
