import React from 'react';
import { useHistory } from 'react-router-dom';

import ErrorView from '@/components/ErrorView';

interface Props {
  title: string;
  subtitle: string;
  onTryAgain: () => void;
}

const ErrorPage = ({ title, subtitle }: Props) => {
  const history = useHistory();

  return <ErrorView title={title} subtitle={subtitle} tryAgain={() => history.goBack()} />;
};

export default ErrorPage;
