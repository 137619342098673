import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { CheckoutStatus, FlightCodeType, PaymentMethodType, TypeOfProduct } from '@flyblack/common/domains';
import { getPaymentMethods, payWithACH } from '@/services/api/payment';
import { getEmptyLeg, getEmptyLegPrice } from '@/services/api/empty-leg';
import { getScheduleDeparture } from '@flyblack/common/util';

import { useStripePayment } from '@/hooks/useStripePayment';

import useLoad from '@flyblack/common/hooks/useLoad';
import Loading from '@flyblack/common/components/Loading';
import Typography from '@flyblack/common/components/Typography';
import Icon from '@flyblack/common/components/Icon';
import { isValidError, SubmitError } from '@flyblack/common/components/Error';

import FlightInfoSection from '@/components/Checkout/FlightInfoSection';
import FlightQuoteSection from '@/components/Checkout/FlightQuoteSection';
import FlightCheckoutForm from '@/components/Checkout/FlightCheckoutForm';
import SpecialCodeSection from '@/components/Checkout/SpecialCodeSection';
import CreditsSection from '@/components/Checkout/CreditsSection';
import UnknownErrorInfoBar from '@/components/UnknownErrorInfoBar';

interface Props {
  emptyLegId: number;
  setCheckoutStatus: React.Dispatch<React.SetStateAction<CheckoutStatus>>;
}

const JetDealCheckoutView = ({ emptyLegId, setCheckoutStatus }: Props) => {
  const intl = useIntl();

  const { submitPayment } = useStripePayment();

  const [payCarbonOffset, setPayCarbonOffset] = React.useState(true);
  const [specialCode, setSpecialCode] = React.useState('');
  const [useCredits, setUseCredits] = React.useState(false);

  const { value: emptyLegDetail, loading: loadingEmptyLegDetail, error: errorEmptyLegDetail } = useLoad({
    load: getEmptyLeg,
    id: emptyLegId
  });

  const {
    value: emptyLegPrice,
    loading: loadingEmptyLegPrice,
    reload: reloadEmptyLegPrice,
    error: errorEmptyLegPrice
  } = useLoad({
    load: () =>
      getEmptyLegPrice(emptyLegId, {
        carbonOffset: payCarbonOffset ? 'INCLUDED' : 'NOT_INCLUDED',
        ...(useCredits
          ? { discountType: FlightCodeType.Credit }
          : specialCode && { code: specialCode, discountType: FlightCodeType.PromoCode })
      }),
    keepData: true
  });

  const {
    value: paymentMethods,
    loading: loadingPaymentMethods,
    reload: reloadPaymentMethods,
    error: errorPaymentMethods
  } = useLoad({
    load: () =>
      getPaymentMethods().then((paymentMethodResponse) => [
        ...paymentMethodResponse.achs,
        ...paymentMethodResponse.cards
      ])
  });

  React.useEffect(() => {
    if (errorEmptyLegDetail) {
      setCheckoutStatus(CheckoutStatus.UnknownError);
    }
  }, [errorEmptyLegDetail]);

  React.useEffect(() => {
    if (errorEmptyLegPrice && !emptyLegPrice) {
      setCheckoutStatus(CheckoutStatus.UnknownError);
    }
  }, [errorEmptyLegPrice]);

  React.useEffect(() => {
    if (!loadingEmptyLegPrice) {
      if (errorEmptyLegPrice) {
        setSpecialCode('');
      } else {
        reloadEmptyLegPrice();
      }
    }
  }, [payCarbonOffset]);

  React.useEffect(() => {
    if (!loadingEmptyLegPrice) {
      reloadEmptyLegPrice();
    }
  }, [specialCode, useCredits]);

  const submitFlightBooking = (values) => {
    const selectedPaymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === values.selectedPaymentMethodId
    );

    if (selectedPaymentMethod.type === PaymentMethodType.Card) {
      return submitPayment({
        productId: emptyLegDetail.id,
        typeOfProduct: TypeOfProduct.EmptyLeg,
        payCarbonOffset,
        paymentMethodId: selectedPaymentMethod.id,
        ...(useCredits
          ? { discountType: FlightCodeType.Credit }
          : specialCode && !errorEmptyLegPrice && { code: specialCode, discountType: FlightCodeType.PromoCode })
      })
        .then((response) => setCheckoutStatus(CheckoutStatus.PaymentSucceeded))
        .catch((error) => setCheckoutStatus(CheckoutStatus.PaymentFailed));
    } else {
      // ACH
      return payWithACH({
        bankAccountId: selectedPaymentMethod.id,
        productId: emptyLegDetail.id,
        typeOfProduct: TypeOfProduct.EmptyLeg,
        payCarbonOffset,
        ...(useCredits
          ? { discountType: FlightCodeType.Credit }
          : specialCode && !errorEmptyLegPrice && { code: specialCode, discountType: FlightCodeType.PromoCode })
      })
        .then((response) => setCheckoutStatus(CheckoutStatus.PaymentSucceeded))
        .catch((error) => setCheckoutStatus(CheckoutStatus.PaymentFailed));
    }
  };

  return loadingEmptyLegDetail ? (
    <Loading visible={loadingEmptyLegDetail} center className="mt-40 w-full">
      <Loading.Indicator size={120} borderWidth={2} />
    </Loading>
  ) : (
    <React.Fragment>
      <div className="bg-white pt-28 lg:w-1/3 lg:flex lg:justify-end">
        <div className="w-[320px] px-6 pb-24 mx-auto sm:w-[430px] lg:px-10 lg:mx-0">
          <Typography is="div" type="flamingo" color="black" className="pb-10">
            <FormattedMessage id={translations.pages.checkout.flightInfoSection.title} />
          </Typography>

          <FlightInfoSection
            sourceAirport={emptyLegDetail.sourceAirport}
            destinationAirport={emptyLegDetail.destinationAirport}
            departureStartDate={getScheduleDeparture(emptyLegDetail.schedule)}
            className="pt-10 pb-[100px]"
          />

          {loadingEmptyLegPrice ? (
            <Loading visible={loadingEmptyLegPrice} center className="h-8">
              <Loading.Indicator size={32} borderWidth={2} color="#000000" />
            </Loading>
          ) : (
            <React.Fragment>
              <FlightQuoteSection
                payCarbonOffset={payCarbonOffset}
                price={emptyLegPrice.price}
                carbonOffset={emptyLegPrice.carbonOffset}
                subtotal={emptyLegPrice.subtotal}
                fetTax={emptyLegPrice.fetTax}
                total={emptyLegPrice.total}
                discountPrice={emptyLegPrice.discountType ? emptyLegPrice.discountPrice : null}
                removeDiscount={() => {
                  setSpecialCode('');
                  setUseCredits(false);
                }}
                titles={
                  emptyLegPrice.discountType
                    ? {
                        discount: intl.formatMessage({
                          id:
                            translations.pages.checkout.flightQuoteSection[
                              emptyLegPrice.discountType === FlightCodeType.PromoCode
                                ? 'codeDiscount'
                                : 'creditsDiscount'
                            ]
                        })
                      }
                    : { discount: null }
                }
              />
              {emptyLegPrice.discountType ? (
                emptyLegPrice.discountType === FlightCodeType.PromoCode && (
                  <Typography is="div" type="hummingbird" color="darkerGray" className="pt-5 flex">
                    <Icon type="check" className="mr-2" />
                    <FormattedMessage id={translations.pages.checkout.specialCodeSection.promoCodeAdded} />
                  </Typography>
                )
              ) : (
                <SpecialCodeSection specialCode={specialCode} setSpecialCode={setSpecialCode} className="pt-8" />
              )}

              {errorEmptyLegPrice &&
                (!isValidError(errorEmptyLegPrice) ? (
                  <UnknownErrorInfoBar />
                ) : (
                  <SubmitError error={errorEmptyLegPrice} color="black" className="pt-8" />
                ))}

              {emptyLegPrice.discountType ? (
                emptyLegPrice.discountType === FlightCodeType.Credit && (
                  <Typography is="div" type="hummingbird" color="darkerGray" className="pt-5 flex">
                    <Icon type="check" className="mr-2" />
                    <FormattedMessage id={translations.pages.checkout.creditsSection.creditsAdded} />
                  </Typography>
                )
              ) : (
                <CreditsSection className="pt-12" onUseCreditsClick={() => setUseCredits(true)} />
              )}
            </React.Fragment>
          )}
        </div>
      </div>

      {errorPaymentMethods ? (
        <UnknownErrorInfoBar />
      ) : (
        <div className="lg:w-2/3 pt-28">
          <div className="w-[320px] px-6 pb-24 mx-auto sm:w-full lg:px-0 xl:w-[1010px] xl:mx-0">
            <div className="sm:w-[450px] sm:mx-auto">
              <FlightCheckoutForm
                payCarbonOffset={payCarbonOffset}
                setPayCarbonOffset={setPayCarbonOffset}
                carbonOffset={emptyLegPrice && emptyLegPrice.carbonOffset}
                loadingPrice={loadingEmptyLegPrice}
                setCheckoutStatus={setCheckoutStatus}
                loadingPaymentMethods={loadingPaymentMethods}
                paymentMethods={paymentMethods}
                reloadPaymentMethods={reloadPaymentMethods}
                onSubmit={submitFlightBooking}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default JetDealCheckoutView;
