import React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import HeaderWrapper from '@flyblack/common/components/HeaderWrapper';

import applicationSent from './applicationSent.png';

const Sent: React.FC = () => {
  const history = useHistory();

  return (
    <div className=" min-h-full w-full">
      <Helmet bodyAttributes={{ style: `background-image: url(${applicationSent})` }} />

      <HeaderWrapper />

      <Spacer xs={4} sm={10} />

      <div className="flex flex-col max-w-[420px] text-center mx-auto px-5 sm:px-0">
        <Icon type="bookingCompleted" sizeClassName="text-[80px]" />

        <Spacer xs={6} />

        <Typography is="span" type="flamingo">
          <FormattedMessage id={translations.pages.membership.applicationSent.title} />
        </Typography>

        <Spacer xs={2} />

        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.pages.membership.applicationSent.subtitle} />
        </Typography>

        <Spacer xs={5} />

        <Button onClick={() => history.push(urls.home)} appearance="white" fat className="w-full">
          <FormattedMessage id={translations.pages.membership.applicationSent.button} />
        </Button>
      </div>
    </div>
  );
};

export default Sent;
