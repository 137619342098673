import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';

import { Airport } from '@flyblack/common/domains';

import InfoItem from '@/components/InfoItem';

interface Props {
  sourceAirport: Airport;
  destinationAirport: Airport;
  departureStartDate: string;
  className?: string;
}

const FlightInfoSection: React.FC<Props> = ({ sourceAirport, destinationAirport, departureStartDate, className }) => {
  const intl = useIntl();

  return (
    <div className={className}>
      <InfoItem
        className="pb-6"
        iconType="departure"
        title={intl.formatMessage({ id: translations.pages.checkout.flightInfoSection.departure.title })}
        content={intl.formatMessage(
          { id: translations.pages.checkout.flightInfoSection.departure.detail },
          {
            displayCode: sourceAirport.displayCode,
            city: sourceAirport.city.name,
            state: sourceAirport.city.state,
            airportName: sourceAirport.name
          }
        )}
      />
      <InfoItem
        className="pb-6"
        iconType="destination"
        title={intl.formatMessage({ id: translations.pages.checkout.flightInfoSection.destination.title })}
        content={intl.formatMessage(
          { id: translations.pages.checkout.flightInfoSection.destination.detail },
          {
            displayCode: destinationAirport.displayCode,
            city: destinationAirport.city.name,
            state: destinationAirport.city.state,
            airportName: destinationAirport.name
          }
        )}
      />
      <InfoItem
        iconType="calendar"
        title={intl.formatMessage({ id: translations.pages.checkout.flightInfoSection.date.title })}
        content={intl.formatDate(departureStartDate, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          weekday: 'long'
        })}
      />
    </div>
  );
};

export default FlightInfoSection;
