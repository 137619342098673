import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { translations } from '@/locale';
import { urls } from '@/constants';
import { TripDetailItem, TripStatus } from '@flyblack/common/domains';
import { sortPhotos } from '@flyblack/common/util';

import Spacer from '@flyblack/common/components/Spacer';
import { useQuery } from '@flyblack/common/hooks/useQuery';
import ClientPhotoGallery from '@/components/ClientPhotoGallery';
import PassengersSection from '@/components/Passengers/PassengersSection';
import UnderlinedButton from '@/components/UnderlinedButton';

import TripDetailSection from './CharterDetailSections/TripDetailSection';
import BookingRequestSentSection from './CharterDetailSections/BookingRequestSentSection';
import BookingConfirmedSection from './CharterDetailSections/BookingConfirmedSection';
import BookingUnavailableSection from './CharterDetailSections/BookingUnavailableSection';

const isValidSelectedLeg = (selectedLegIndex: number, selectedLegCount: number) =>
  selectedLegIndex >= 0 && selectedLegIndex < selectedLegCount;

interface Props {
  value: TripDetailItem;
  reload: () => any;
}

const MyJetCharterDetail = ({ value: { id, status, receiptUrl, jetCharterLegs }, reload }: Props) => {
  const intl = useIntl();
  const history = useHistory();
  const query = useQuery();

  const selectedLegIndex = parseInt(query.get('leg')) || 0;

  React.useEffect(() => {
    if (!isValidSelectedLeg(selectedLegIndex, jetCharterLegs.length)) {
      history.replace(queryString.stringifyUrl({ url: urls.myTrips.detail.get(id), query: { leg: 0 } }));
    }
  }, [selectedLegIndex]);

  // do not render anything if the selected index isn't valid
  if (!isValidSelectedLeg(selectedLegIndex, jetCharterLegs.length)) {
    return null;
  }
  // set selected leg only after we know the selected index is valid
  const selectedLeg = jetCharterLegs[selectedLegIndex];

  const getSelectedFlightsForPassenger = (passengerId: number) =>
    jetCharterLegs
      .filter((leg) => leg.passengers.find((passenger) => passenger.id === passengerId))
      .map((leg) => leg.flightId);

  const getSelectedFlightsForPet = (petId: number) =>
    jetCharterLegs.filter((leg) => leg.pets.find((pet) => pet.id === petId)).map((leg) => leg.flightId);

  const statusToStateSectionMap: Record<
    TripStatus.WaitingForApproval | TripStatus.WaitingForPayment | TripStatus.Booked,
    React.FC<any>
  > = {
    [TripStatus.WaitingForApproval]: BookingRequestSentSection,
    [TripStatus.WaitingForPayment]: (props) => (
      <BookingConfirmedSection tripId={id} aircraftCategory={selectedLeg.aircraftCategory} {...props} />
    ),
    [TripStatus.Booked]: (props) => (
      <PassengersSection
        onAction={reload}
        tripId={id}
        passengers={selectedLeg.passengers.map((passenger) => ({
          ...passenger,
          jetCharterFlightIds: getSelectedFlightsForPassenger(passenger.id)
        }))}
        pets={selectedLeg.pets.map((pet) => ({
          ...pet,
          jetCharterFlightIds: getSelectedFlightsForPet(pet.id)
        }))}
        legOptions={jetCharterLegs.map((leg, index) => ({
          flightId: leg.flightId,
          name: intl.formatMessage({ id: translations.pages.myTrips.jetCharter.legNumber }, { number: index + 1 }),
          detail: `${leg.sourceAirport.city.name} - ${leg.destinationAirport.city.name}`,
          disabled: leg.flightId === selectedLeg.flightId
        }))}
        flightId={selectedLeg.flightId}
        {...props}
      />
    )
  };

  const BookingStateSection = statusToStateSectionMap[status] || BookingUnavailableSection;

  return (
    <div className="bg-black min-h-full w-full flex flex-col items-center">
      <Spacer xs={0} sm={9} />

      {jetCharterLegs.length > 1 && (
        <React.Fragment>
          <div className="flex flex-wrap w-full min-h-full max-w-[480px] lg:max-w-5xl lg:px-10">
            <div className="flex gap-10">
              {jetCharterLegs.map((charterLegSummary, index) => (
                <UnderlinedButton
                  key={charterLegSummary.flightId}
                  active={selectedLegIndex === index}
                  onClick={() =>
                    history.push(queryString.stringifyUrl({ url: urls.myTrips.detail.get(id), query: { leg: index } }))
                  }
                  text={intl.formatMessage(
                    {
                      id: translations.pages.myTrips.jetCharter.legNumber
                    },
                    { number: index + 1 }
                  )}
                />
              ))}
            </div>
          </div>

          <Spacer xs={5} />
        </React.Fragment>
      )}

      <div className="flex flex-wrap w-full justify-around md:justify-between min-h-full max-w-5xl">
        <BookingStateSection className="order-1 mx-auto lg:order-2 lg:max-w-[380px]" />

        <div className="flex flex-col w-full max-w-[480px] mx-auto order-2 lg:order-1">
          <TripDetailSection
            tripId={id}
            status={status}
            receiptUrl={receiptUrl}
            charterLeg={selectedLeg}
            className="order-1 lg:order-2"
          />

          <ClientPhotoGallery
            images={sortPhotos(
              selectedLeg.aircraftType ? selectedLeg.aircraftType.photos : selectedLeg.aircraftCategory.photos
            )}
            airplaneCategory={selectedLeg.aircraftCategory}
            airplaneType={selectedLeg.aircraftType}
            className="order-2 lg:order-1"
          />
        </div>
      </div>

      <Spacer xs={10} />
    </div>
  );
};

export default MyJetCharterDetail;
