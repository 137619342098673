import React from 'react';
import { useHistory } from 'react-router';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { MembershipApplication } from '@/domains/membership';
import { applyForMembership } from '@/services/api/membership';

import Icon from '@flyblack/common/components/Icon';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import HeaderWrapper from '@flyblack/common/components/HeaderWrapper';

import { SessionContext } from '@/components/Context/Session';

import Steps from './Steps';
import StepItem from './StepItem';

import { stepToSchemaMap } from './ApplySchema';

const MembershipApplicationPage: React.FC = () => {
  const { me, loading, reload } = React.useContext(SessionContext);

  const [stepNumber, setStepNumber] = React.useState(1);

  const history = useHistory();

  const stepText = translations.pages.membership.application.steps;

  return (
    <div className="bg-black min-h-full">
      <HeaderWrapper />

      <div className="w-full flex items-center justify-center">
        <div className="w-full max-w-[420px] px-5 mb-[-1px] flex items-center justify-between border-solid border-flyblack-gray border-b">
          <StepItem stepNumber={stepNumber} stepNumberThreshold={1} textId={stepText.firstStep.title} />

          <Icon type="chevronRight" className={stepNumber >= 1 ? 'text-white' : 'text-flyblack-light-gray'} />

          <StepItem stepNumber={stepNumber} stepNumberThreshold={2} textId={stepText.secondStep.title} />

          <Icon type="chevronRight" className={stepNumber >= 2 ? 'text-white' : 'text-flyblack-light-gray'} />

          <StepItem stepNumber={stepNumber} stepNumberThreshold={3} textId={stepText.thirdStep.title} />
        </div>
      </div>

      {!loading && (
        <Form
          id="user"
          schema={stepToSchemaMap[stepNumber]}
          subscription={{ errors: true, submitting: true, submitError: true, dirty: true, values: true, valid: true }}
          initialValues={{ ...me, weight: me.weight ? me.weight.value : null, step: 1 }}
          onSubmit={({
            termsAndConditions,
            ...values
          }: Omit<MembershipApplication, 'weight'> & { weight: number; termsAndConditions: boolean }) => {
            if (stepNumber < 3) {
              return new Promise((resolve) => {
                setStepNumber((stepNumber) => stepNumber + 1);
                resolve(true);
              });
            }
            return applyForMembership({ ...values }).then(() =>
              reload().finally(() => history.push(urls.membership.sent))
            );
          }}
        >
          {({ submitting, values, valid, form }, formId) => (
            <React.Fragment>
              <div className="flex justify-center">
                <div className="flex flex-col w-full max-w-[420px]">
                  <Spacer xs={3} />

                  {stepNumber > 1 ? (
                    <Button
                      appearance="transparent"
                      className="ml-1 mr-auto hover:bg-transparent flex items-center"
                      type="button"
                      onClick={() => setStepNumber((stepNumber) => stepNumber - 1)}
                    >
                      <Icon type="back" className="text-white" />
                    </Button>
                  ) : (
                    <Spacer xs={2} />
                  )}

                  <Spacer xs={3} />
                </div>
              </div>

              <Steps
                formId={formId}
                stepNumber={stepNumber}
                submitting={submitting}
                valid={valid}
                values={values}
                change={form.change}
              />
            </React.Fragment>
          )}
        </Form>
      )}

      <Spacer xs={10} />
    </div>
  );
};

export default MembershipApplicationPage;
