import React from 'react';

import Loading from '@flyblack/common/components/Loading';
import { SendBirdContext } from '@/components/Context/SendBird';
import CommonErrorView from '@/components/CommonErrorView';

import { useQuery } from '@flyblack/common/hooks/useQuery';
import OpenedTicketContent from './OpenedTicketContent';
import ClosedTicketContent from './ClosedTicketContent';
import { ConciergeTab, SELECTED_CONCIERGE_TAB_QUERY_PARAM } from './ConciergeTabs';

export interface Props {}

const Concierge = () => {
  const { data, loading, error } = React.useContext(SendBirdContext);

  const query = useQuery();
  const selectedTab =
    query.get(SELECTED_CONCIERGE_TAB_QUERY_PARAM) === ConciergeTab.Closed ? ConciergeTab.Closed : ConciergeTab.Opened;

  const conciergeTabToContentMap = {
    [ConciergeTab.Closed]: ClosedTicketContent,
    [ConciergeTab.Opened]: OpenedTicketContent
  };

  const ConciergeContent = conciergeTabToContentMap[selectedTab];

  return loading ? (
    <Loading visible={true} className="mt-40" center>
      <Loading.Indicator size={120} borderWidth={2} />
    </Loading>
  ) : error ? (
    <CommonErrorView error={error} />
  ) : (
    data && <ConciergeContent />
  );
};

export default Concierge;
