import React from 'react';

import Form from '@flyblack/common/components/Form';
import HiddenInput from '@flyblack/common/components/Form/Input/HiddenInput';

import AboutYou from './AboutYou';
import ConfirmYourProfile from './ConfirmYourProfile';
import Summary from './Summary';

interface Props {
  stepNumber: number;
  submitting: boolean;
  valid: boolean;
  formId: string;
  values: any;
  change: any;
}

const Steps = ({ stepNumber, submitting, valid, formId, values, change }: Props) => {
  React.useEffect(() => {
    change('step', stepNumber);
  }, [stepNumber]);

  return (
    <React.Fragment>
      {/* with this we can rerun the validation when the step changes */}
      <Form.Field is={HiddenInput} id={`${formId}-step`} name="step" type="text" />

      {stepNumber === 1 && <ConfirmYourProfile submitting={submitting} valid={valid} formId={formId} />}

      {stepNumber === 2 && <AboutYou submitting={submitting} valid={valid} formId={formId} />}

      {stepNumber === 3 && <Summary submitting={submitting} valid={valid} formId={formId} values={values} />}
    </React.Fragment>
  );
};

export default Steps;
