import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Icon from '@flyblack/common/components/Icon';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import PictureUpload from '@flyblack/common/components/PictureUpload';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import DateInput from '@flyblack/common/components/Form/Input/DateInput';
import RowContainer from '@flyblack/common/components/RowContainer';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import GenderSelect from '@/components/GenderSelect';

const TextInputWithValidation = withValidation(TextInput);
const DateInputWithValidation = withValidation(DateInput);
const GenderSelectWithValidation = withValidation(GenderSelect);

export interface Props {
  formId: string;
  submitting: boolean;
  valid: boolean;
}

const ConfirmYourProfile: React.FC<Props> = ({ formId, submitting, valid }) => {
  const intl = useIntl();

  return (
    <div className="flex justify-center">
      <div className="flex flex-col p-5 w-full max-w-[420px]">
        <Spacer xs={2} />

        <Form.Field
          id={`${formId}-profile-picture-url`}
          is={PictureUpload}
          name="profilePictureUrl"
          readOnly={submitting}
          className="mx-auto"
          shape="round"
        />

        <Spacer xs={2} />

        <Typography is="span" type="hummingbird" color="lightGray" className="text-center">
          <FormattedMessage id={translations.pages.editProfile.profilePhoto} />
        </Typography>

        <Spacer xs={4} />

        <div className="flex items-center p-5 rounded-sm bg-white">
          <Icon type="attention" className="text-black pr-5" />

          <Typography is="span" type="hummingbird" className="text-black">
            <FormattedMessage id={translations.pages.membership.application.steps.firstStep.recentPhoto} />
          </Typography>
        </div>

        <Spacer xs={5} />

        <RowContainer>
          <RowContainer.Item col={6}>
            <Form.Field
              is={TextInputWithValidation}
              id={`${formId}-first-name`}
              name="firstName"
              type="text"
              label={intl.formatMessage({ id: translations.inputs.firstName.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.firstName.placeholder })}
              readOnly={submitting}
              leadingIcon="navigationAccount"
            />
          </RowContainer.Item>

          <RowContainer.Item col={6}>
            <Form.Field
              is={TextInputWithValidation}
              id={`${formId}-last-name`}
              name="lastName"
              type="text"
              label={intl.formatMessage({ id: translations.inputs.lastName.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.lastName.placeholder })}
              readOnly={submitting}
              leadingIcon="navigationAccount"
            />
          </RowContainer.Item>
        </RowContainer>

        <Spacer xs={4} />

        <Form.Field
          is={TextInputWithValidation}
          id={`${formId}-email`}
          name="email"
          type="text"
          label={intl.formatMessage({ id: translations.inputs.email.label })}
          placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
          readOnly={submitting}
          leadingIcon="email"
        />

        <Spacer xs={4} />

        <Form.Field
          is={TextInputWithValidation}
          type="text"
          id={`${formId}-phone-number`}
          name="phoneNumber"
          label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
          placeholder={intl.formatMessage({ id: translations.inputs.phoneNumber.placeholder })}
          readOnly={submitting}
          leadingIcon="phone"
        />

        <Spacer xs={4} />

        <RowContainer>
          <RowContainer.Item col={6}>
            <Form.Field
              is={TextInputWithValidation}
              type="text"
              id={`${formId}-nationality`}
              name="nationality"
              label={intl.formatMessage({ id: translations.inputs.nationality.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.nationality.placeholder })}
              readOnly={submitting}
              leadingIcon="country"
            />
          </RowContainer.Item>

          <RowContainer.Item col={6}>
            <Form.Field
              is={GenderSelectWithValidation}
              type="text"
              id={`${formId}-gender`}
              name="gender"
              label={intl.formatMessage({ id: translations.inputs.gender.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.gender.placeholder })}
              readOnly={submitting}
              leadingIcon="gender"
            />
          </RowContainer.Item>
        </RowContainer>

        <Spacer xs={10} />

        <Typography is="span" type="hummingbird" className="uppercase tracking-[2px]">
          <FormattedMessage id={translations.pages.editProfile.bookingInfo} />
        </Typography>

        <Spacer xs={4} />

        <RowContainer>
          <RowContainer.Item col={6}>
            <Form.Field
              is={DateInputWithValidation}
              id={`${formId}-birthday`}
              name="birthDate"
              type="text"
              label={intl.formatMessage({ id: translations.inputs.dateOfBirth.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.dateOfBirth.placeholder })}
              readOnly={submitting}
              leadingIcon="calendar"
            />
          </RowContainer.Item>

          <RowContainer.Item col={6}>
            <Form.Field
              is={TextInputWithValidation}
              id={`${formId}-weight`}
              name="weight"
              type="text"
              label={intl.formatMessage({ id: translations.inputs.weight.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.weight.placeholder })}
              readOnly={submitting}
              leadingIcon="weight"
            />
          </RowContainer.Item>
        </RowContainer>

        <Spacer xs={5} />

        <Typography is="span" type="hummingbird" color="lightGray">
          <FormattedMessage id={translations.pages.editProfile.id} />
        </Typography>

        <Spacer xs={1} />

        <Form.Field
          id={`${formId}-identity-document-url`}
          is={PictureUpload}
          name="identityDocumentUrl"
          readOnly={submitting}
          shape="rectangle"
          icon="id"
        />

        <Spacer xs={5} />

        <Button
          appearance="white"
          fat
          className="w-full"
          form={formId}
          loading={submitting}
          disabled={!valid}
          type="submit"
        >
          <Typography is="span" type="halcyon">
            <FormattedMessage id={translations.inputs.buttons.proceed} />
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default ConfirmYourProfile;
