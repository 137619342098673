import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import { urls } from '@/constants';

import MyTrips from '@/page/Home/MyTrips';
import JetDeals from '@/page/Home/JetDeals';
import Concierge from '@/page/Home/Concierge';
import JetShuttles from '@/page/Home/JetShuttles';
import JetCharters from '@/page/Home/JetCharters';
import JetDealDetail from '@/page/Home/JetDealDetail';
import UserProfile from '@/page/Home/UserProfile/Base';
import LegalPages from '@/page/Unauthorized/LegalPages';
import EditUserProfile from '@/page/Home/UserProfile/Edit';
import Membership from '@/page/Home/UserProfile/Membership';
import JetShuttleDetail from '@/page/Home/JetShuttleDetail';
import AircraftTypeDetail from '@/page/Home/AircraftDetail/AircraftTypeDetail';
import AircraftCategoryDetail from '@/page/Home/AircraftDetail/AircraftCategoryDetail';

export interface Props {}

const Main: React.FC<Props> = () => {
  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none" tabIndex={0}>
      <Switch>
        <Route path={urls.planeDetail.category} component={AircraftCategoryDetail} />

        <Route path={urls.planeDetail.type} component={AircraftTypeDetail} />

        <Route path={urls.jetDeals.detail.base} component={JetDealDetail} />

        <Route path={urls.jetDeals.base} component={JetDeals} />

        <Route path={urls.jetCharters.base} component={JetCharters} />

        <Route path={urls.jetShuttles.detail.base} component={JetShuttleDetail} />

        <Route path={urls.jetShuttles.base} component={JetShuttles} />

        <Route path={urls.myTrips.base} component={MyTrips} />

        <Route path={urls.profile.edit} component={EditUserProfile} />

        <Route path={urls.profile.base} component={UserProfile} />

        <Route path={urls.membership.base} component={Membership} />

        <Route path={urls.legal.base} component={LegalPages} />

        <Route path={urls.concierge.base} component={Concierge} />

        <ConsumeRedirectRoute render={() => <Redirect to={urls.jetDeals.base} />} />
      </Switch>
    </main>
  );
};

export default Main;
