import React from 'react';
import classnames from 'classnames';

import Icon, { Type } from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  iconType: Type;
  title: string;
  content: string;
  className?: string;
}

const InfoItem = ({ iconType, title, content, className }: Props) => {
  return (
    <div className={classnames('flex', className)}>
      <div className="pt-4 pr-8">
        <Icon type={iconType} appearance="gray" />
      </div>
      <div>
        <Typography is="div" type="hummingbird" className="pb-[6px]" color="lightGray">
          {title}
        </Typography>
        <Typography is="div" type="halcyon" color="gray">
          {content}
        </Typography>
      </div>
    </div>
  );
};

export default InfoItem;
