import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { Pricing } from '@flyblack/common/domains';

import Link from '@flyblack/common/components/Link';
import Typography from '@flyblack/common/components/Typography';
import Icon from '@flyblack/common/components/Icon';

interface Props {
  id: number;
  departsAt: string;
  sourceCity: string;
  destinationCity: string;
  aircraftType?: string;
  aircraftCategory: string;
  availableSeats: number;
  price: Pricing;
  photoUrl: string;
  eventName?: string;
}

const JetShuttleCardItem = ({
  id,
  departsAt,
  sourceCity,
  destinationCity,
  aircraftType,
  aircraftCategory,
  availableSeats,
  price,
  eventName,
  photoUrl
}: Props) => {
  return (
    <Link
      to={urls.jetShuttles.detail.get(id)}
      className="mb-4 md:flex md:h-[132px] md:flex-grow-0 md:flex-shrink-0 md:mb-6 group"
    >
      <div
        className="hidden md:block flex-grow-0 flex-shrink-0 w-[156px] bg-cover bg-center rounded-l-[4px]"
        style={{ backgroundImage: `url(${photoUrl})` }}
      />
      <div className="flex flex-col md:flex-grow bg-white p-4 justify-center">
        {eventName && (
          <Typography is="span" type="hummingbird" color="lightGray" className="mb-3">
            {eventName}
          </Typography>
        )}

        <div className="flex">
          <div className="flex-grow-0 flex-shrink-0 w-[80px] lg:w-[132px]">
            <Typography is="div" type="halcyon" color="black" weight="medium" className="hidden lg:block pb-2">
              <FormattedDate value={departsAt} weekday="long" />
            </Typography>

            <Typography is="div" type="halcyon" color="black" weight="medium" className="block lg:hidden pb-2">
              <FormattedDate value={departsAt} weekday="short" />
            </Typography>

            <Typography is="div" type="hummingbird" color="darkerGray">
              <FormattedDate value={departsAt} month="short" day="numeric" />
            </Typography>
          </div>
          <div>
            <div className="flex items-center pb-2">
              <Typography is="div" type="halcyon" color="black" weight="medium">
                {sourceCity}
              </Typography>

              <Icon type="plane" appearance="black" className="px-3" />

              <Typography is="div" type="halcyon" color="black" weight="medium">
                {destinationCity}
              </Typography>
            </div>
            <Typography is="div" type="hummingbird" color="darkerGray">
              {aircraftType || aircraftCategory}
            </Typography>

            <Typography is="div" type="hummingbird" color="darkerGray">
              <FormattedMessage id={translations.pages.jetShuttles.availableSeats} values={{ availableSeats }} />
            </Typography>
          </div>
        </div>
      </div>

      <div
        className="flex items-center justify-center border border-solid border-white rounded-b-sm transition-colors
          md:flex-grow-0 md:flex-shrink-0 md:w-[156px] md:rounded-bl-none md:rounded-r-md md:group-hover:bg-white
          lg:w-[180px]"
      >
        <div className="flex items-center py-4">
          <Icon type="chair" className="transition-colors text-white md:group-hover:text-black" />
          <Typography
            is="div"
            type="halcyon"
            weight="medium"
            className="pl-3 pr-[2px] transition-colors text-white md:group-hover:text-black"
          >
            <FormattedMessage
              id={translations.pages.jetShuttles.pricing}
              values={{ symbol: price.symbol, value: price.value }}
            />
          </Typography>
          <Typography
            is="div"
            type="hummingbird"
            className="transition-colors text-flyblack-light-gray md:group-hover:text-flyblack-darker-gray"
          >
            <FormattedMessage id={translations.pages.jetShuttles.perSeat} />
          </Typography>
        </div>
      </div>
    </Link>
  );
};

export default JetShuttleCardItem;
