import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { WeightUnit } from '@flyblack/common/domains';
import { LegOption } from '@/domains/charter';
import { createPassenger, updatePassenger } from '@/services/api/passengers';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import DateInput from '@flyblack/common/components/Form/Input/DateInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import LegCheckboxes from './LegCheckboxes';

interface InitialPassengerData {
  birthDate?: string;
  legalName?: string;
  weight?: number;
  jetCharterFlightIds?: number[];
}

export interface Props {
  passengerId?: number;
  initialData?: InitialPassengerData;
  onSuccess: () => any;
  onCancel: () => any;
  tripId: number;
  legOptions?: LegOption[];
}

const TextInputWithValidation = withValidation(TextInput);
const DateInputWithValidation = withValidation(DateInput);

const schema = yup.object().shape({
  legalName: yup
    .string()
    .label(translations.inputs.legalName.label)
    .required(),
  birthDate: yup
    .string()
    .label(translations.inputs.dateOfBirth.label)
    .required(),
  weight: yup
    .number()
    .label(translations.inputs.weight.label)
    .required()
});

const PassengerForm = ({ passengerId, initialData, onSuccess, onCancel, tripId, legOptions }: Props) => {
  const intl = useIntl();

  const handleSubmit = (values) =>
    (passengerId
      ? updatePassenger({ ...values, id: passengerId, weight: { value: values.weight, unit: WeightUnit.Pound } })
      : createPassenger({
          ...values,
          weight: { value: values.weight, unit: WeightUnit.Pound },
          tripId
        })
    ).then(onSuccess);

  return (
    <Form
      id={`passenger-${passengerId || 'new'}`}
      subscription={{ errors: true, submitting: true, submitError: true, dirty: true, values: true, valid: true }}
      initialValues={initialData}
      schema={schema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ submitError, submitting, values, valid, form, errors, dirty }, formId) => (
        <React.Fragment>
          <Form.Field
            is={TextInputWithValidation}
            id={`${formId}-legal-name`}
            name="legalName"
            type="text"
            label={intl.formatMessage({ id: translations.inputs.legalName.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.legalName.placeholder })}
            readOnly={submitting}
            appearance="light"
            leadingIcon="navigationAccount"
          />

          <Spacer xs={2} />

          <div className="flex justify-between">
            <Form.Field
              is={DateInputWithValidation}
              type="text"
              id={`${formId}-birth-date`}
              name="birthDate"
              label={intl.formatMessage({ id: translations.inputs.dateOfBirth.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.dateOfBirth.placeholder })}
              readOnly={submitting}
              appearance="light"
              leadingIcon="calendar"
            />

            <div className="w-6" />

            <Form.Field
              is={TextInputWithValidation}
              id={`${formId}-weight`}
              name="weight"
              type="text"
              label={intl.formatMessage({ id: translations.inputs.weight.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.weight.placeholder })}
              readOnly={submitting}
              appearance="light"
              leadingIcon="weight"
            />
          </div>

          <Spacer xs={4} />

          {legOptions && legOptions.length > 1 && (
            <React.Fragment>
              <LegCheckboxes formId={formId} legOptions={legOptions} submitting={submitting} />
              <Spacer xs={4} />
            </React.Fragment>
          )}

          <div className="flex flex-row justify-end">
            <Button appearance="underlined" form={formId} onClick={onCancel} type="button" className="w-1/4">
              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.inputs.buttons.cancel} />
              </Typography>
            </Button>

            <Button
              appearance="black"
              className="w-2/3 ml-8"
              form={formId}
              loading={submitting}
              disabled={!dirty}
              type="submit"
            >
              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.inputs.buttons.save} />
              </Typography>
            </Button>
          </div>

          <Spacer xs={2} />
        </React.Fragment>
      )}
    </Form>
  );
};

export default PassengerForm;
