import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { deletePaymentMethod, getPaymentMethods } from '@/services/api/payment';

import { InstanceProps } from '@flyblack/common/components/Modal';
import Typography from '@flyblack/common/components/Typography';
import Card from '@flyblack/common/components/Card';
import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';
import useLoad from '@flyblack/common/hooks/useLoad';
import { Card as CreditCard, PaymentMethod, PaymentMethodType } from '@flyblack/common/domains';
import Loading from '@flyblack/common/components/Loading';

import PaymentMethodListItem from '@/components/PaymentMethodListItem';

const PaymentMethodModal = ({ close }: InstanceProps) => {
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [loadingOnDelete, setLoadingOnDelete] = React.useState(false);

  const { value, loading, reload } = useLoad({
    load: () => getPaymentMethods()
  });

  React.useEffect(() => {
    if (value) {
      setPaymentMethods([...value.achs, ...value.cards]);
    }
  }, [value]);

  const onDelete = (id: string, type: PaymentMethodType) => {
    setLoadingOnDelete(true);
    deletePaymentMethod(id, type)
      .then(() => {
        setLoadingOnDelete(false);
        reload();
      })
      .catch(() => setLoadingOnDelete(false));
  };

  return (
    <Card bg="white" className="relative w-[642px] overflow-visible block text-black">
      <Card.Row className="flex flex-row justify-end items-center">
        <Button type="button" appearance="white" className="z-[1] w-12 cursor-pointer" onClick={close}>
          <Icon type="close" className="text-flyblack-dark-gray" />
        </Button>
      </Card.Row>

      <Card.Row className="px-8 sm:px-14 md:px-24">
        <Card.Column padded={false} className="w-full mb-20">
          <Typography is="div" type="owl" className="mb-10">
            <FormattedMessage id={translations.modals.paymentMethodsModal.title} />
          </Typography>

          {loading || loadingOnDelete ? (
            <Loading visible={true} center className="w-full h-[40px]">
              <Loading.Indicator size={40} borderWidth={2} />
            </Loading>
          ) : paymentMethods.length ? (
            paymentMethods.map((paymentMethod: PaymentMethod) => (
              <PaymentMethodListItem
                key={paymentMethod.id}
                type={paymentMethod.type}
                brand={paymentMethod.type === PaymentMethodType.Card ? (paymentMethod as CreditCard).brand : null}
                last4={paymentMethod.last4}
                appearance="light"
                className="mt-4"
                onDelete={() => onDelete(paymentMethod.id, paymentMethod.type)}
              />
            ))
          ) : (
            <Typography is="div" type="halcyon">
              <FormattedMessage id={translations.modals.paymentMethodsModal.noPaymentMethods} />
            </Typography>
          )}
        </Card.Column>
      </Card.Row>
    </Card>
  );
};

export default PaymentMethodModal;
