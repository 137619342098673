import React from 'react';

import { getLinkToken } from '@/services/api/payment';

import OpenPlaidLink from './OpenPlaidLink';

interface RenderProps {
  setupACHPayment: () => any;
}

interface Props {
  onSuccess: () => any;
  onFail: () => any;
  children: (props: RenderProps) => any;
}

const ACHSetup = ({ onSuccess, onFail, children }: Props) => {
  const [linkToken, setLinkToken] = React.useState<string>();

  const setupACHPayment = React.useCallback(() => {
    getLinkToken()
      .then(({ linkToken }) => setLinkToken(linkToken))
      .catch(onFail);
  }, []);

  return (
    <React.Fragment>
      {children({ setupACHPayment })}
      {linkToken && <OpenPlaidLink token={linkToken} onSetupSuccess={onSuccess} onSetupFail={onFail} />}
    </React.Fragment>
  );
};

export default ACHSetup;
