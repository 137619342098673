import React from 'react';
import classnames from 'classnames';

import { useImagePreview } from '@flyblack/common/hooks/useImagePreview';
import { SUPPORTED_IMAGE_FORMATS } from '@flyblack/common/constants';

import Icon, { Type } from '@flyblack/common/components/Icon';
import { Props as PictureProps } from '@flyblack/common/components/Picture';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import FileUpload, { Props as FileUploadProps } from '@flyblack/common/components/FileUpload';

export interface Props extends Pick<PictureProps, 'type'>, Omit<FileUploadProps, 'type' | 'value' | 'onChange'> {
  id: string;
  name: string;
  title: string;
  invalid: boolean;
  value: File | string | null;
  onFocus: () => any;
  onBlur: () => any;
  onChange: (file: File | null) => any;
  shape?: 'round' | 'rectangle';
  icon?: Type;
}

const shapeToClassNameMap: Record<string, string> = {
  round:
    'relative h-[132px] w-[132px] flex items-center justify-center rounded-full bg-center bg-no-repeat bg-cover after:rounded-full',
  rectangle:
    'relative h-[220px] w-full max-w-[480px] border-2 border-dashed bg-[#000000] border-flyblack-gray flex items-center justify-center bg-white bg-center bg-no-repeat bg-cover'
};

const PictureUpload = ({
  value,
  invalid,
  title,
  disabled,
  type,
  className,
  shape = 'round',
  icon = 'navigationAccount',
  onChange,
  ...rest
}: Props) => {
  const { preview, updateSource } = useImagePreview(value);

  React.useEffect(() => {
    updateSource(value);
    rest.onBlur();
  }, [value]);

  const change = (files: FileList) => {
    return onChange(files[0] || null);
  };

  return (
    <div
      className={classnames(
        !preview && 'bg-flyblack-darkest-gray',
        invalid && 'after:bg-flyblack-red after:bg-opacity-50 after:w-full after:h-full',
        shapeToClassNameMap[shape],
        className
      )}
      style={{ backgroundImage: `url(${preview})` }}
    >
      {!preview && <Icon type={icon} className="text-white" />}
      <FileUpload
        {...rest}
        value={value}
        multiple={false}
        onChange={change}
        accept={SUPPORTED_IMAGE_FORMATS.join(',')}
        className="absolute cursor-pointer bottom-0 right-0 z-10"
      >
        <div className="w-10 h-10 bg-white flex items-center justify-center rounded-full">
          <Icon type="camera" className="text-black" />
        </div>
      </FileUpload>
    </div>
  );
};

export default withValidation(PictureUpload);
