import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  className?: string;
}

const BookingUnavailableSection = ({ className }: Props) => {
  const history = useHistory();

  return (
    <div className={classnames('bg-white flex flex-col w-full p-6 mb-auto text-black max-w-[480px]', className)}>
      <Typography is="span" type="hummingbird" color="lightGray">
        <FormattedMessage id={translations.pages.myTrips.jetCharter.bookingUnavailable.detail} />
      </Typography>

      <Spacer xs={4} />

      <Button onClick={() => history.push(urls.concierge.base)} appearance="black" fat className="w-full">
        <FormattedMessage id={translations.inputs.buttons.getInTouch} />
      </Button>
    </div>
  );
};

export default BookingUnavailableSection;
