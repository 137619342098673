import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { sortPhotos } from '@flyblack/common/util';
import { getEmptyLeg } from '@/services/api/empty-leg';

import Spacer from '@flyblack/common/components/Spacer';
import ClientPhotoGallery from '@/components/ClientPhotoGallery';
import UnknownErrorView from '@/components/UnknownErrorView';

import useLoad from '@flyblack/common/hooks/useLoad';

import TripSection from './TripSection';
import ChairSection from './ChairSection';

export interface Props {}

interface Params {
  id: string;
}

const JetDealDetail: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const { value, loading, error } = useLoad({ load: () => getEmptyLeg(parseInt(match.params.id)) });

  return (
    !loading &&
    (error ? (
      <UnknownErrorView />
    ) : (
      <div className="bg-black min-h-full w-full flex flex-col items-center">
        <Spacer xs={0} sm={9} />

        <div className="flex flex-wrap w-full justify-around md:justify-between min-h-full max-w-5xl">
          <ChairSection
            jetDealId={value.id}
            seats={value.aircraftCategory.seats}
            price={value.price}
            className="order-1 mx-auto lg:order-2 lg:max-w-[380px]"
          />

          <div className="flex flex-col w-full max-w-[480px] mx-auto order-2 lg:order-1">
            <TripSection
              sourceAirport={value.sourceAirport}
              destinationAirport={value.destinationAirport}
              duration={value.durationInMinutes}
              schedule={value.schedule}
              className="order-1 lg:order-2"
            />

            <ClientPhotoGallery
              images={sortPhotos(value.aircraftType ? value.aircraftType.photos : value.aircraftCategory.photos)}
              loading={loading}
              airplaneCategory={value.aircraftCategory}
              airplaneType={value.aircraftType}
              className="order-2 lg:order-1"
            />
          </div>
        </div>

        <Spacer xs={10} />
      </div>
    ))
  );
};

export default JetDealDetail;
