import React from 'react';
import { Redirect, Switch } from 'react-router';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import { SessionContext } from '@/components/Context/Session';

const AwaitingPayment: React.FC = () => {
  const { me } = React.useContext(SessionContext);

  return (
    <Switch>
      <ConsumeRedirectRoute render={() => <Redirect to={urls.membership.checkout.get(me.membership.id)} />} />
    </Switch>
  );
};

export default AwaitingPayment;
