import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

const WaitingList: React.FC = () => {
  return (
    <div className="bg-black flex flex-col items-center min-h-full px-5 sm:px-0 max-w-[490px] mx-auto">
      <Spacer xs={2} sm={10} />

      <Spacer xs={10} />

      <Typography is="span" type="flamingo" className="text-center md:mt-32">
        <FormattedMessage id={translations.pages.membership.information.waitingList.title} />
      </Typography>

      <Spacer xs={2} />

      <Typography is="span" type="halcyon" color="lightGray" className="text-center">
        <FormattedMessage id={translations.pages.membership.information.waitingList.subtitle} />
      </Typography>

      <Typography is="span" type="halcyon" color="lightGray" className="text-center">
        <FormattedMessage id={translations.pages.membership.information.waitingList.getBack} />
      </Typography>

      <Spacer xs={10} />
    </div>
  );
};

export default WaitingList;
