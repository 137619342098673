import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Typography from '@flyblack/common/components/Typography';
import Spacer from '@flyblack/common/components/Spacer';
import ToggleSwitch from '@flyblack/common/components/ToggleSwitch';
import Form from '@flyblack/common/components/Form';
import Link from '@flyblack/common/components/Link';

import SectionTitle from '@/components/SectionTitle';

interface Props {
  formId: string;
  submitting: boolean;
  values: any;
  change: (name: string, value: any) => void;
  paragraphs?: { [x: string]: string };
}

const TOSSection = ({ formId, submitting, values, change, paragraphs = {} }: Props) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <SectionTitle>
        <FormattedMessage id={translations.application.common.tosSection.title} />
      </SectionTitle>

      <Spacer xs={2} />

      {Object.keys(paragraphs).map((id) => (
        <React.Fragment key={paragraphs[id]}>
          <Typography is="div" type="hummingbird" color="lightGray">
            <FormattedMessage id={paragraphs[id]} />
          </Typography>

          <Spacer xs={2} />
        </React.Fragment>
      ))}

      <div className="flex flex-row items-center justify-between w-full">
        <Typography is="div" type="hummingbird" color="white">
          <FormattedMessage
            id={translations.application.common.tosSection.termsAgreement}
            values={{
              terms: (children) => (
                <Link external underlined to={urls.legal.terms}>
                  {children}
                </Link>
              )
            }}
          />
        </Typography>

        <Form.Field
          is={ToggleSwitch}
          id={`${formId}-tos`}
          name="tos"
          type="checkbox"
          readOnly={submitting}
          checked={values.tos}
          onChange={() => change('tos', !values.tos)}
        />
      </div>
    </React.Fragment>
  );
};

export default TOSSection;
