import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locale';

import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import Form from '@flyblack/common/components/Form';
import Typography from '@flyblack/common/components/Typography';
import Button from '@flyblack/common/components/Button';
import SectionTitle from '@/components/SectionTitle';

const schema = yup.object().shape({
  code: yup.string()
});

interface Props {
  specialCode: string;
  setSpecialCode: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
}

const SpecialCodeSection = ({ specialCode, setSpecialCode, className }: Props) => {
  const intl = useIntl();

  return (
    <div className={className}>
      <SectionTitle className="pb-1 text-black">
        <FormattedMessage id={translations.pages.checkout.specialCodeSection.title} />
      </SectionTitle>

      <Form
        id="special-code"
        schema={schema}
        initialValues={{ code: specialCode }}
        subscription={{ submitting: true, dirty: true }}
        onSubmit={(values) => {
          setSpecialCode(values.code);
          return Promise.resolve(values.code);
        }}
      >
        {({ submitting, dirty }, formId) => (
          <div className="flex justify-between items-end">
            <Form.Field
              is={TextInput}
              id={`${formId}-code`}
              name="code"
              type="text"
              appearance="light"
              placeholder={intl.formatMessage({ id: translations.inputs.specialCode.placeholder })}
              readOnly={submitting}
            />

            <Button
              appearance="lightGhost"
              className="w-[140px]"
              form={formId}
              loading={submitting}
              disabled={!dirty}
              type="submit"
              fat
            >
              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.inputs.buttons.applySpecialCode} />
              </Typography>
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default SpecialCodeSection;
