import React from 'react';
import * as copy from 'copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog, Transition } from '@headlessui/react';

import { translations } from '@/locale';
import { getReferralBonus } from '@/services/api/me';

import Icon from '@flyblack/common/components/Icon';
import useLoad from '@flyblack/common/hooks/useLoad';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

export interface Props {
  open: boolean;
  setOpen: (boolean) => any;
  credits: number;
  promoCode: string;
}

const FriendReferral: React.FC<Props> = ({ open, setOpen, credits, promoCode }) => {
  const { value: bonus, loading: bonusLoading } = useLoad({
    load: () => getReferralBonus()
  });

  const intl = useIntl();

  return (
    !bonusLoading && (
      <Transition.Root show={open} as={React.Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-hidden z-20 shadow-inner" onClose={setOpen}>
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 left-0 max-w-full flex">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="-translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="-translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="w-screen max-w-[468px] px-6 flex flex-col items-center bg-white">
                  <div className=" relative flex flex-col max-w-[340px] w-full text-black">
                    <Button
                      type="button"
                      appearance="transparent"
                      className=" z-[1] h-[24px] w-[24px] mt-6 md:mt-11 ml-auto cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <Icon type="close" className="text-black" />
                    </Button>

                    <div className="flex items-center">
                      <div className="h-32 w-32 rounded-full border border-solid border-flyblack-light-gray flex items-center justify-center">
                        <Icon type="gift" />
                      </div>

                      <div className="flex flex-col ml-10">
                        <Typography is="span" type="hummingbird" color="lightGray">
                          <FormattedMessage id={translations.pages.profile.friendReferral.credits} />
                        </Typography>

                        <Typography is="span" type="flamingo" color="black">
                          {credits}
                        </Typography>
                      </div>
                    </div>

                    <Spacer xs={7} />

                    <Typography is="span" type="halcyon" color="black">
                      <FormattedMessage id={translations.pages.profile.friendReferral.title} />
                    </Typography>

                    <Spacer xs={3} />

                    <Typography is="span" type="hummingbird" color="lightGray">
                      <FormattedMessage id={translations.pages.profile.friendReferral.promoCode} />
                    </Typography>

                    <Typography is="span" type="flamingo" color="black">
                      {promoCode}
                    </Typography>

                    <Spacer xs={3} />

                    <Typography is="span" type="hummingbird" color="lightGray">
                      <FormattedMessage
                        id={translations.pages.profile.friendReferral.description}
                        values={{
                          symbol: bonus.symbol,
                          bonus: bonus.value
                        }}
                      />
                    </Typography>

                    <Spacer xs={3} />

                    <Button
                      fat
                      appearance="black"
                      onClick={() =>
                        copy.default(
                          intl.formatMessage(
                            { id: translations.pages.profile.friendReferral.shareMessage },
                            { promoCode: promoCode, symbol: bonus.symbol, bonus: bonus.value }
                          )
                        )
                      }
                    >
                      <FormattedMessage id={translations.pages.membership.detail.promoCode.share} />
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  );
};

export default FriendReferral;
