import React from 'react';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';

import { urls } from '@/constants';

import { CheckoutStatus } from '@flyblack/common/domains';

import { translations } from '@/locale';

import LogoLink from '@flyblack/common/components/LogoLink';

import ErrorView from '@/components/ErrorView';
import UnknownErrorView from '@/components/UnknownErrorView';
import StripeElementsProvider from '@/components/StripeElementsProvider';
import FlightPaymentSuccessView from '@/components/Checkout/FlightPaymentSuccessView';

import JetShuttleCheckoutView from './JetShuttleCheckoutView';
import { withSeatsParam } from './withSeatsParam';

interface Props {
  id: number;
  seats: number;
}

const JetShuttleCheckout = ({ id, seats }: Props) => {
  const intl = useIntl();
  const history = useHistory();

  const [checkoutStatus, setCheckoutStatus] = React.useState(CheckoutStatus.Default);

  const resetCheckoutStatus = () => setCheckoutStatus(CheckoutStatus.Default);

  const checkoutViewByStatus: Record<CheckoutStatus, React.FC> = {
    [CheckoutStatus.Default]: () => (
      <StripeElementsProvider>
        <JetShuttleCheckoutView jetShuttleId={id} seats={seats} setCheckoutStatus={setCheckoutStatus} />
      </StripeElementsProvider>
    ),
    [CheckoutStatus.PaymentSucceeded]: () => <FlightPaymentSuccessView />,
    [CheckoutStatus.PaymentFailed]: () => (
      <ErrorView
        title={intl.formatMessage({ id: translations.application.common.paymentFail.title })}
        subtitle={intl.formatMessage({ id: translations.application.common.paymentFail.subtitle })}
        tryAgain={resetCheckoutStatus}
      />
    ),
    [CheckoutStatus.ACHSetupFailed]: () => (
      <ErrorView
        title={intl.formatMessage({ id: translations.application.common.achSetupFail.title })}
        subtitle={intl.formatMessage({ id: translations.application.common.achSetupFail.subtitle })}
        tryAgain={resetCheckoutStatus}
      />
    ),
    [CheckoutStatus.BookingNotAllowed]: () => (
      <ErrorView
        title={intl.formatMessage({ id: translations.application.common.bookingNotAllowed.title })}
        subtitle={intl.formatMessage({ id: translations.application.common.bookingNotAllowed.subtitle })}
        tryAgain={() => history.push(urls.jetShuttles.detail.get(id))}
      />
    ),
    [CheckoutStatus.UnknownError]: () => <UnknownErrorView />
  };

  const CheckoutViewByStatus = checkoutViewByStatus[checkoutStatus];

  return (
    <div className={'flex flex-col relative text-white lg:flex-row'}>
      <div className="absolute top-7 left-10">
        <LogoLink className="h-4 w-auto" />
      </div>
      <CheckoutViewByStatus />
    </div>
  );
};

export default withSeatsParam(JetShuttleCheckout);
