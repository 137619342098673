import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Form from '@flyblack/common/components/Form';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import Spacer from '@flyblack/common/components/Spacer';

import SectionTitle from '@/components/SectionTitle';

interface Props {
  formId: string;
  submitting: boolean;
}

const TextInputWithValidation = withValidation(TextInput);

const BillingAddressSection = ({ formId, submitting }: Props) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <SectionTitle>
        <FormattedMessage id={translations.application.common.billingAddressSection.title} />
      </SectionTitle>

      <Spacer xs={2} />

      <Form.Field
        is={TextInputWithValidation}
        id={`${formId}-country`}
        name="country"
        type="text"
        placeholder={intl.formatMessage({ id: translations.inputs.country.placeholder })}
        readOnly={submitting}
        leadingIcon="country"
        disabled
      />

      <Spacer xs={2} />

      <Form.Field
        is={TextInputWithValidation}
        id={`${formId}-city`}
        name="city"
        type="text"
        placeholder={intl.formatMessage({ id: translations.inputs.city.placeholder })}
        readOnly={submitting}
        leadingIcon="city"
        disabled
      />

      <Spacer xs={2} />

      <Form.Field
        is={TextInputWithValidation}
        id={`${formId}-state`}
        name="state"
        type="text"
        placeholder={intl.formatMessage({ id: translations.inputs.state.placeholder })}
        readOnly={submitting}
        leadingIcon="state"
        disabled
      />

      <Spacer xs={2} />

      <Form.Field
        is={TextInputWithValidation}
        id={`${formId}-zip`}
        name="zipCode"
        type="text"
        placeholder={intl.formatMessage({ id: translations.inputs.zip.placeholder })}
        readOnly={submitting}
        leadingIcon="zip"
        disabled
      />
    </React.Fragment>
  );
};

export default BillingAddressSection;
