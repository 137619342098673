import React from 'react';

import { sortPhotos } from '@flyblack/common/util';
import { TripDetailItem } from '@flyblack/common/domains';

import Spacer from '@flyblack/common/components/Spacer';
import ClientPhotoGallery from '@/components/ClientPhotoGallery';

import PassengersSection from '@/components/Passengers/PassengersSection';
import TripDetailSection from '@/components/TripDetailSection';

interface Props {
  value: TripDetailItem;
  reload: () => any;
}

const MyJetShuttleDetail: React.FC<Props> = ({ value, reload }) => {
  return (
    <div className="bg-black min-h-full w-full flex flex-col items-center">
      <Spacer xs={0} sm={9} />

      <div className="flex flex-wrap w-full justify-around md:justify-between min-h-full max-w-5xl">
        <PassengersSection
          onAction={reload}
          tripId={value.id}
          passengers={value.passengers}
          passengerLimit={value.purchasedSeats}
          className="order-1 mx-auto lg:order-2 lg:max-w-[380px]"
        />

        <div className="flex flex-col w-full max-w-[480px] mx-auto order-2 lg:order-1">
          <TripDetailSection data={value} className="order-1 lg:order-2" />

          <ClientPhotoGallery
            images={sortPhotos(value.aircraftType ? value.aircraftType.photos : value.aircraftCategory.photos)}
            airplaneCategory={value.aircraftCategory}
            airplaneType={value.aircraftType}
            className="order-2 lg:order-1"
          />
        </div>
      </div>

      <Spacer xs={10} />
    </div>
  );
};

export default MyJetShuttleDetail;
