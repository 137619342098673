import { FlightRoute, JetShuttleBasic, Page } from '@flyblack/common/domains';

import { authorizedApi } from '../network/authorized-api';

export const getFlightRoutes = () =>
  authorizedApi.get<Page<FlightRoute>>(`/v1/flight-routes`).then(({ content }) => content);

export const getShuttlesOfRoute = (flightRouteId: number) =>
  authorizedApi
    .get<Page<JetShuttleBasic>>(`/v1/flight-routes/${flightRouteId}/jet-shuttles`)
    .then(({ content }) => content);
