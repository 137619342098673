import React from 'react';
import range from 'lodash/range';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { CharterLegListItem } from '@/domains/charter';

import Icon from '@flyblack/common/components/Icon';
import Modal from '@flyblack/common/components/Modal';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import DoubleContentWithIcon from '@flyblack/common/components/DoubleContentWithIcon';

import { SessionContext } from '@/components/Context/Session';

import UserDataModal from './UserDataModal';

interface Props {
  seats: number;
  charterData: {
    paxNum: string;
    id: string;
    aircraftCategoryId: string;
    legs: CharterLegListItem[];
    price: number;
  };
  showMoreSeats?: boolean;
  className?: string;
}

const ChairSection = ({ seats, charterData, showMoreSeats = false, className }: Props) => {
  const { me } = React.useContext(SessionContext);

  return (
    <div
      className={classnames('bg-white flex flex-col w-full p-6 mb-auto h-[350px] text-black max-w-[480px]', className)}
    >
      <DoubleContentWithIcon
        noSpacer
        top={
          <Typography is="span" type="halcyon">
            <FormattedMessage
              id={translations.pages.jetCharters.results.seats}
              values={{ seats, plusSign: showMoreSeats }}
            />
          </Typography>
        }
        bottom={
          <Typography is="span" type="hummingbird" color="lightGray">
            <FormattedMessage id={translations.pages.jetCharters.results.seatsReserved} />
          </Typography>
        }
        icon="seat"
      />

      <Spacer xs={2} sm={3} />

      <div className="flex flex-wrap gap-3 ml-9 md:ml-11">
        {range(seats).map((seat) => (
          <div key={seat} className="flex flex-col items-center">
            <Icon type="chair" sizeClassName="text-[36px] leading-[36px]" />
          </div>
        ))}
      </div>

      <div className="flex justify-between mt-auto">
        <Typography is="span" type="halcyon" weight="medium">
          <FormattedMessage id={translations.pages.jetCharters.results.estimatedPrice} />
        </Typography>

        <Typography is="span" type="halcyon" weight="medium">
          <FormattedMessage
            id={translations.pages.jetCharters.results.price}
            values={{ symbol: '$', value: charterData.price }}
          />
        </Typography>
      </div>

      <Spacer xs={3} />

      <Modal modal={UserDataModal} charterData={charterData}>
        {({ open }) => (
          <Button type="button" onClick={open} appearance="black">
            <FormattedMessage id={translations.inputs.buttons.sendBookingRequest} />
          </Button>
        )}
      </Modal>
    </div>
  );
};

export default ChairSection;
