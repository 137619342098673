import React from 'react';
import * as yup from 'yup';
import Helmet from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { CLIENT_ID } from '@/config';
import { api } from '@/services/network/api';
import { urls } from '@/constants';
import { ClientSignUp } from '@/domains/session';

import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import { SubmitError } from '@flyblack/common/components/Error';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import CitySelect from '@/components/CitySelect';
import LocationSelect from '@/components/LocationSelect';
import { SessionContext } from '@/components/Context/Session';

import {
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  nearestCitySchema,
  locationSchema,
  passwordSchema
} from '../UnauthorizedSchemas';

import bgLoginImg from '@/assets/bg-login.jpg';

const TextInputWithValidation = withValidation(TextInput);
const LocationSelectWithValidation = withValidation(LocationSelect);
const CitySelectWithValidation = withValidation(CitySelect);

const schema = yup.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  password: passwordSchema,
  cityId: nearestCitySchema,
  location: locationSchema
});

const SignUpPage: React.FC = () => {
  const intl = useIntl();

  const history = useHistory();

  const { signInWithEmail } = React.useContext(SessionContext);

  const signUpAndLogIn = (values: ClientSignUp) =>
    api
      .post('/v1/users', values, { params: { clientId: CLIENT_ID } })
      .then(() => signInWithEmail(values.email, values.password).then(() => history.push(urls.jetDeals.base)));

  return (
    <div className="flex flex-col h-full w-full">
      <Helmet bodyAttributes={{ style: `background-image: url(${bgLoginImg})` }} />

      <div className="flex flex-col bg-white md:rounded-lg my-auto sm:max-w-[800px] mx-auto items-center w-full">
        <Spacer xs={7} />

        <div className="flex flex-col sm:max-w-[400px] w-[320px] sm:w-full px-5">
          <div className="text-2xl text-black text-center">
            <FormattedMessage id={translations.pages.signUp.title} />
          </div>

          <Spacer xs={4} />

          <Typography is="span" type="hummingbird" color="lightGray" className="text-center">
            <FormattedMessage id={translations.pages.signUp.subtitle} />
          </Typography>

          <Form
            schema={schema}
            subscription={{ submitError: true, submitting: true, values: true, valid: true, dirty: true }}
            onSubmit={({ location, ...rest }) => signUpAndLogIn({ placeId: location.placeId, ...rest })}
          >
            {({ submitError, submitting }) => (
              <div className="flex flex-col">
                <Spacer xs={6} />

                <div className="flex justify-between">
                  <Form.Field
                    is={TextInputWithValidation}
                    id="firstName"
                    name="firstName"
                    type="text"
                    leadingIcon="navigationAccount"
                    appearance="light"
                    label={intl.formatMessage({ id: translations.inputs.firstName.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.firstName.placeholder })}
                    inputClassName="w-[135px]"
                  />

                  <Form.Field
                    is={TextInputWithValidation}
                    id="lastName"
                    name="lastName"
                    type="text"
                    appearance="light"
                    label={intl.formatMessage({ id: translations.inputs.lastName.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.lastName.placeholder })}
                    inputClassName="w-[135px]"
                  />
                </div>

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id="email"
                  name="email"
                  type="text"
                  leadingIcon="email"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.email.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id="password"
                  name="password"
                  type="password"
                  leadingIcon="password"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.password.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.password.placeholder })}
                />

                <Spacer xs={4} />

                <Form.Field
                  is={LocationSelectWithValidation}
                  id="location"
                  name="location"
                  type="text"
                  leadingIcon="location"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.location.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.location.placeholder })}
                  readOnly={submitting}
                  editable
                />

                <Spacer xs={4} />

                <Form.Field
                  is={CitySelectWithValidation}
                  id="city-id"
                  name="cityId"
                  type="text"
                  leadingIcon="city"
                  appearance="light"
                  label={intl.formatMessage({ id: translations.inputs.nearestCity.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.nearestCity.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={5} />

                {!!submitError && (
                  <React.Fragment>
                    <SubmitError error={submitError} />

                    <Spacer xs={2} />
                  </React.Fragment>
                )}

                <Button fat type="submit" className="font-medium" appearance="black" disabled={submitting}>
                  <FormattedMessage id={translations.pages.signUp.signUpButton} />
                </Button>

                <Spacer xs={5} />

                <div className="text-sm text-center text-flyblack-light-gray">
                  <FormattedMessage
                    id={translations.pages.signUp.haveAccountSignIn}
                    values={{
                      a: (children) => (
                        <Link to={urls.signIn} className="text-black">
                          {children}
                        </Link>
                      )
                    }}
                  />
                </div>

                <Spacer xs={7} />
              </div>
            )}
          </Form>
        </div>
      </div>

      <Spacer xs={10} />
    </div>
  );
};

export default SignUpPage;
