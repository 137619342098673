import { uploadImage } from './media';
import { MediaFileType, WeightUnit } from '@flyblack/common/domains';
import { MembershipApplication, MembershipPrice } from '@/domains/membership';
import { authorizedApi } from '@/services/network/authorized-api';

export const applyForMembership: (data: MembershipApplication) => Promise<any> = ({
  profilePictureUrl,
  identityDocumentUrl,
  weight,
  ...rest
}) => {
  return Promise.all([
    uploadImage(profilePictureUrl, MediaFileType.ProfilePicture),
    uploadImage(identityDocumentUrl, MediaFileType.Id)
  ]).then((urls) =>
    authorizedApi.post<MembershipApplication>(`/v1/memberships`, {
      ...rest,
      weight: { value: weight, unit: WeightUnit.Pound },
      profilePictureUrl: urls[0],
      identityDocumentUrl: urls[1]
    })
  );
};

export const getMembershipPrice = (code?: string) => {
  return authorizedApi.get<MembershipPrice>(`/v1/memberships/price`, { ...(code && { code }) });
};

export const getNewMembershipId = (membershipId: number) =>
  authorizedApi.post<{ id: number }>(`/v1/memberships/${membershipId}/renew`);
