import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { GroupChannel } from 'sendbird';
import SendBirdDesk from 'sendbird-desk';

import { translations } from '@/locale';

import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';
import FileUpload from '@flyblack/common/components/FileUpload';

import { SendBirdMessage } from '../MessageList/MessageBubble';

interface Props {
  ticket: SendBirdDesk.Ticket;
  appendMessage: (message: SendBirdMessage) => void;
  onMessageSent: () => void;
  className?: string;
}

const MessageInput = ({ ticket, appendMessage, onMessageSent, className }: Props) => {
  const intl = useIntl();

  const sendMessage = ({ message }, form) => {
    return new Promise((resolve, reject) => {
      const trimmedMessage = message && message.trim();
      if (!trimmedMessage) {
        return reject();
      }

      (ticket.channel as GroupChannel).sendUserMessage(trimmedMessage, (res, err) => {
        // TODO: handle error
        if (err) {
          reject(err);
        } else {
          form.reset();
          appendMessage(res);
          onMessageSent();
          resolve(trimmedMessage);
        }
      });
    });
  };

  const handleFileChange = (files: FileList) => {
    (ticket.channel as GroupChannel).sendFileMessage(files[0], (res, err) => {
      // TODO: handle error
      if (!err) {
        appendMessage(res);
      }
    });
  };

  return (
    <div className={classnames('flex flex-col items-center w-full', className)}>
      <Form
        id="send-chat-message"
        initialValues={{ message: '' }}
        onSubmit={sendMessage}
        className="max-w-[720px] w-full h-full bg-white flex items-center pl-8 pr-5"
      >
        {(form, formId) => (
          <React.Fragment>
            <Form.Field
              is={TextInput}
              id={`${formId}-input`}
              name="message"
              appearance="light"
              placeholder={intl.formatMessage({ id: translations.pages.concierge.input.placeholder })}
              className="w-full mr-2"
            />

            <FileUpload
              multiple={false}
              onChange={handleFileChange}
              className="flex items-center h-[48px] w-[30px] px-1 mx-1 bg-white text-black cursor-pointer"
            >
              <Icon type="addFile" sizeClassName="text-[20px] leading-[20px]" />
            </FileUpload>

            <Button form={formId} type="submit" className="px-1 mx-1 bg-white text-black border-transparent">
              <Icon type="send" sizeClassName="text-[20px] leading-[20px]" />
            </Button>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
};

export default MessageInput;
