import React from 'react';

import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  title: string;
  subtitle?: string;
  details?: string;
  buttonTitle: string;
  onButtonClick: () => void;
}

const SuccessPage = ({ title, subtitle, details, buttonTitle, onButtonClick }: Props) => {
  return (
    <div className="h-screen mx-auto flex flex-col justify-center items-center text-center">
      <Icon type="bookingCompleted" className="pb-8" sizeClassName="text-[80px]" />

      <Typography is="div" type="flamingo" className="pb-4">
        {title}
      </Typography>

      <div className="w-[400px] flex flex-col">
        {subtitle && (
          <Typography is="div" type="halcyon" className="pb-12">
            {subtitle}
          </Typography>
        )}

        {details && (
          <Typography is="div" type="halcyon" className="pb-12">
            {details}
          </Typography>
        )}

        <Button appearance="white" fat onClick={onButtonClick}>
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
};

export default SuccessPage;
