import { MembershipCodeType, Pricing } from '@flyblack/common/domains';

export interface Membership {
  id: number;
  createdAt: string;
  status: MembershipStatus;
  startsAt: string;
  endsAt: string;
}

export enum MembershipStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING_APPROVAL',
  WaitingList = 'WAITING_LIST',
  AwaitingPayment = 'APPROVED_AND_AWAITING_PAYMENT',
  ActiveAndRenewable = 'ACTIVE_AND_RENEWABLE',
  Expired = 'EXPIRED'
}

export interface MembershipApplication {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthDate: string;
  weight: number;
  profilePictureUrl: string;
  identityDocumentUrl: string;
  nationality: string;
  gender: string;
  workRole: string;
  workplace: string;
  applicationReason: string;
}

export interface MembershipPrice {
  codeType: MembershipCodeType;
  codePrice: Pricing;
  subTotal: Pricing;
  total: Pricing;
}
