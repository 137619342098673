import * as yup from 'yup';

import { translations } from '@/locale';
import { STRING_LONG_MAX_LENGTH } from '@/constants';

export const emailSchema = yup
  .string()
  .email()
  .max(STRING_LONG_MAX_LENGTH)
  .label(translations.inputs.email.label)
  .required();

export const passwordSchema = yup
  .string()
  .min(8)
  .max(STRING_LONG_MAX_LENGTH)
  .label(translations.inputs.password.label)
  .required();

export const firstNameSchema = yup
  .string()
  .max(STRING_LONG_MAX_LENGTH)
  .label(translations.inputs.firstName.label)
  .required();

export const lastNameSchema = yup
  .string()
  .max(STRING_LONG_MAX_LENGTH)
  .label(translations.inputs.lastName.label)
  .required();

export const nearestCitySchema = yup
  .number()
  .label(translations.inputs.nearestCity.label)
  .required();

export const locationSchema = yup
  .object({
    placeId: yup.string().required(),
    country: yup.string().required()
  })
  .label(translations.inputs.location.label)
  .default(null)
  .required();
