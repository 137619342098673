import React from 'react';
import range from 'lodash/range';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { Pricing } from '@flyblack/common/domains';
import { getShuttlePrice } from '@/services/api/shuttle';
import { hasValidMembership } from '@/util/membership';

import useLoad from '@flyblack/common/hooks/useLoad';
import Loading from '@flyblack/common/components/Loading';
import Link from '@flyblack/common/components/Link';
import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import DoubleContentWithIcon from '@flyblack/common/components/DoubleContentWithIcon';

import { SessionContext } from '@/components/Context/Session';

import ChairSelect from './ChairSelect';

interface Props {
  jetShuttleId: number;
  seatPrice: Pricing;
  availableSeats: number;
  seats: number;
  className?: string;
}

const ChairSelectionSection: React.FC<Props> = ({ jetShuttleId, seatPrice, availableSeats, seats, className }) => {
  const [selectedSeatCount, setSelectedSeatCount] = React.useState(availableSeats ? 1 : 0);

  const { value: jetShuttlePrice, loading, reload } = useLoad({
    load: () => getShuttlePrice(jetShuttleId, selectedSeatCount)
  });

  React.useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [selectedSeatCount]);

  const { me } = React.useContext(SessionContext);
  const history = useHistory();

  return (
    <div className={classnames('bg-white flex flex-col w-full p-6 mb-auto text-black max-w-[480px]', className)}>
      <DoubleContentWithIcon
        noSpacer
        top={
          <Typography is="span" type="halcyon">
            <FormattedMessage id={translations.pages.jetShuttleDetail.selectSeats} />
          </Typography>
        }
        bottom={
          <Typography is="span" type="hummingbird" color="lightGray">
            <FormattedMessage
              id={translations.pages.jetShuttleDetail.selectedSeats}
              values={{ seats: selectedSeatCount }}
            />
          </Typography>
        }
        icon="seat"
      />

      <Spacer xs={2} sm={3} />

      <div className="flex flex-wrap gap-5">
        {range(seats).map((seat) => (
          <div key={seat} className="flex flex-col items-center">
            <ChairSelect
              selected={seat < selectedSeatCount}
              disabled={seat > availableSeats - 1 || loading}
              onClick={() => setSelectedSeatCount(seat + 1)}
            />

            <Typography is="span" type="hummingbird">
              {seat > availableSeats - 1 ? (
                <FormattedMessage id={translations.pages.jetShuttleDetail.sold} />
              ) : (
                `${seatPrice.symbol}${seatPrice.value.toLocaleString()}`
              )}
            </Typography>
          </div>
        ))}
      </div>

      <Spacer xs={4} sm={6} />

      <div className="flex justify-between">
        <Typography is="span" type="halcyon" weight="medium">
          <FormattedMessage id={translations.pages.jetShuttleDetail.total} />
        </Typography>

        {loading ? (
          <Loading visible={true} className="ml-2">
            <Loading.Indicator size={20} borderWidth={2} />
          </Loading>
        ) : (
          <Typography is="span" type="halcyon" weight="medium">
            {`${jetShuttlePrice.price.symbol}${jetShuttlePrice.price.value.toLocaleString()}`}
          </Typography>
        )}
      </div>

      <Spacer xs={3} />

      {me.membership && hasValidMembership(me.membership.status) ? (
        <Button
          {...(selectedSeatCount === 0
            ? { disabled: true }
            : { onClick: () => history.push(urls.jetShuttles.checkout.get(jetShuttleId, selectedSeatCount)) })}
          appearance="black"
        >
          <FormattedMessage id={translations.inputs.buttons.proceedToCheckout} />
        </Button>
      ) : (
        <Popover className="relative">
          <Popover.Button className="w-full">
            <Button appearance="black" fat className="w-full" trailingIcon="forward">
              <Typography is="div" type="halcyon" className="font-normal">
                <FormattedMessage id={translations.inputs.buttons.proceedToCheckout} />
              </Typography>
            </Button>
          </Popover.Button>

          <Transition
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-80 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-80 opacity-0"
          >
            <Popover.Panel className="absolute -mt-16 shadow z-20 bg-white rounded-md w-full p-5 flex flex-col text-black">
              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.modals.membershipRedirectModal.title} />
              </Typography>

              <Spacer xs={2} />

              <Typography is="span" type="hummingbird" color="lightGray" className="w-3/4">
                <FormattedMessage id={translations.modals.membershipRedirectModal.subtitle} />
              </Typography>

              <Spacer xs={2} />

              <div className="flex justify-between items-center">
                <Typography is="span" type="halcyon" className="cursor-pointer">
                  <FormattedMessage id={translations.inputs.buttons.cancel} />
                </Typography>

                <Link to={urls.membership.base} className="ml-auto flex items-center">
                  <FormattedMessage id={translations.inputs.buttons.applyForMembership} />
                  <Icon type="chevronRight" />
                </Link>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    </div>
  );
};

export default ChairSelectionSection;
