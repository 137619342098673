import React from 'react';
import classnames from 'classnames';

import { PaymentMethodType, CreditCardBrand } from '@flyblack/common/domains';

import Icon from '@flyblack/common/components/Icon';

import CreditCardContent from './CreditCardContent';
import ACHContent from './ACHContent';

export type Appearance = 'regular' | 'light';

interface Props {
  type: PaymentMethodType;
  brand?: CreditCardBrand;
  last4: string;
  isDefault?: boolean;
  onClick?: () => any;
  onDelete?: () => any;
  appearance?: Appearance;
  className?: string;
}

const PaymentMethodListItem: React.FC<Props> = ({
  type,
  brand,
  last4,
  isDefault,
  onClick,
  onDelete,
  appearance = 'regular',
  className
}) => {
  return (
    <div
      className={classnames(
        'flex items-center justify-between px-6 py-5 border border-solid',
        { 'cursor-pointer': onClick },
        isDefault
          ? 'bg-white text-black border-white'
          : appearance === 'regular'
          ? 'bg-black text-white border-flyblack-dark-gray'
          : 'bg-white text-black border-flyblack-lighter-gray',
        className
      )}
      onClick={onClick && onClick}
    >
      {type === PaymentMethodType.Card ? (
        <CreditCardContent brand={brand} last4={last4} />
      ) : (
        <ACHContent last4={last4} />
      )}

      {isDefault && <Icon type="check" />}

      {onDelete && (
        <div className="cursor-pointer" onClick={onDelete}>
          <Icon type="bin" />
        </div>
      )}
    </div>
  );
};

export default PaymentMethodListItem;
