import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import useLoad from '@flyblack/common/hooks/useLoad';
import { getMyTripDetail } from '@/services/api/trips';

import { FlightType } from '@flyblack/common/domains';

import MyJetDealDetail from './MyJetDealDetail';
import MyJetShuttleDetail from './MyJetShuttleDetail';
import MyJetCharterDetail from './MyJetCharterDetail';

export interface Props {}

interface Params {
  id: string;
}

const MyTripDetail: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const { value, loading, reload } = useLoad({ load: () => getMyTripDetail(parseInt(match.params.id)) });

  const flightTypeToComponentMap: Record<FlightType, any> = {
    [FlightType.Deal]: <MyJetDealDetail value={value} reload={reload} />,
    [FlightType.Shuttle]: <MyJetShuttleDetail value={value} reload={reload} />,
    [FlightType.Charter]: <MyJetCharterDetail value={value} reload={reload} />
  };

  return !loading && flightTypeToComponentMap[value.type];
};

export default MyTripDetail;
