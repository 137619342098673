import React from 'react';
import classnames from 'classnames';
import { Disclosure, Transition } from '@headlessui/react';

const TRANSITION_DURATION_CLASS = 'duration-300';

interface Props {
  open: boolean;
  children: any;
  showOverflow?: boolean;
}

const DisclosureContent = ({ open, showOverflow, children }: Props) => {
  const containerRef = React.useRef<HTMLDivElement>();

  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setHeight(containerRef.current && open ? containerRef.current.offsetHeight : 0);
  }, [open, containerRef.current]);

  return (
    <Transition leave={TRANSITION_DURATION_CLASS}>
      <Disclosure.Panel
        className={classnames('overflow-hidden transition-all ease-in-out h-0', TRANSITION_DURATION_CLASS)}
        style={{ height: showOverflow ? `${height * 1.1}px` : `${height}px` }}
      >
        {/* @ts-ignore */}
        <div ref={containerRef}>{children}</div>
      </Disclosure.Panel>
    </Transition>
  );
};

export default DisclosureContent;
