import React from 'react';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Typography from '@flyblack/common/components/Typography';
import HeaderWrapper from '@flyblack/common/components/HeaderWrapper';

const NavColumn: React.FC = () => {
  return (
    <div className="sm:sticky sm:top-0 flex flex-col gap-1 flex-wrap text-white w-full max-w-[180px]">
      <Typography is={HeaderWrapper.NavItem} light to={urls.legal.terms} type="hummingbird">
        <FormattedMessage id={translations.pages.profile.settings.legal.terms} />
      </Typography>

      <Typography is={HeaderWrapper.NavItem} light to={urls.legal.dataSubjects} type="hummingbird">
        <FormattedMessage id={translations.pages.profile.settings.legal.dataSubjects} />
      </Typography>

      <Typography is={HeaderWrapper.NavItem} light to={urls.legal.credit} type="hummingbird">
        <FormattedMessage id={translations.pages.profile.settings.legal.credit} />
      </Typography>

      <Typography is={HeaderWrapper.NavItem} light to={urls.legal.charter} type="hummingbird">
        <FormattedMessage id={translations.pages.profile.settings.legal.charter} />
      </Typography>

      <Typography is={HeaderWrapper.NavItem} light to={urls.legal.cookies} type="hummingbird">
        <FormattedMessage id={translations.pages.profile.settings.legal.cookies} />
      </Typography>

      <Typography is={HeaderWrapper.NavItem} light to={urls.legal.privacy} type="hummingbird">
        <FormattedMessage id={translations.pages.profile.settings.legal.privacy} />
      </Typography>
    </div>
  );
};

export default NavColumn;
