import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SessionContext } from '@/components/Context/Session';
import Typography from '@flyblack/common/components/Typography';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

const TextInputWithValidation = withValidation(TextInput);

export interface Props {
  formId: string;
  submitting: boolean;
  valid: boolean;
}

const AboutYou: React.FC<Props> = ({ formId, submitting, valid }) => {
  const { me, loading, reload } = React.useContext(SessionContext);

  const intl = useIntl();

  return (
    <div className="flex justify-center">
      <div className="flex flex-col p-5 w-full max-w-[420px]">
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.inputs.workplace.label} />
        </Typography>

        <Spacer xs={2} />

        <Form.Field
          is={TextInputWithValidation}
          id={`${formId}-workplace`}
          name="workplace"
          type="text"
          placeholder={intl.formatMessage({ id: translations.inputs.workplace.placeholder })}
          readOnly={submitting}
        />

        <Spacer xs={6} />

        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.inputs.workRole.label} />
        </Typography>

        <Spacer xs={2} />

        <Form.Field
          is={TextInputWithValidation}
          id={`${formId}-work-role`}
          name="workRole"
          type="text"
          placeholder={intl.formatMessage({ id: translations.inputs.workRole.placeholder })}
          readOnly={submitting}
        />

        <Spacer xs={6} />

        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.inputs.applicationReason.label} />
        </Typography>

        <Spacer xs={2} />

        <Form.Field
          is={TextInputWithValidation}
          id={`${formId}-application-reason`}
          name="applicationReason"
          type="text"
          placeholder={intl.formatMessage({ id: translations.inputs.applicationReason.placeholder })}
          readOnly={submitting}
        />

        <Spacer xs={6} />

        <Button
          appearance="white"
          fat
          className="w-full"
          form={formId}
          loading={submitting}
          disabled={!valid}
          type="submit"
        >
          <Typography is="span" type="halcyon">
            <FormattedMessage id={translations.inputs.buttons.proceed} />
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default AboutYou;
