import React from 'react';
import classnames from 'classnames';
import queryString from 'query-string';

import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@flyblack/common/components/Card';
import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';
import Link from '@flyblack/common/components/Link';
import { urls } from '@/constants';
import { CharterQuoteListItem } from '@/domains/charter';
import { sortPhotos } from '@flyblack/common/util';

export interface Props {
  paxNum: number;
  data: CharterQuoteListItem;
}

const ResultCardItem: React.FC<Props> = ({ data, paxNum }) => {
  return (
    <Link
      to={queryString.stringifyUrl({
        url: urls.jetCharters.detail,
        query: {
          data: JSON.stringify({
            paxNume: paxNum,
            id: data.id,
            aircraftCategoryId: data.aircraftCategory.id,
            legs: data.legs,
            price: data.price
          })
        }
      })}
    >
      <Card bg="white" className="flex flex-col w-full max-h-[236px]">
        <img
          src={sortPhotos(data.aircraftCategory.photos)[0].url}
          className={classnames(`rounded-t-md w-[100%] min-h-[160px] h-[160px] object-cover`)}
        />
        <Card.Row className="flex flex-row p-4 items-center">
          <Icon type="navigationPlane" className="text-black mr-3" />

          <div className="flex flex-col">
            <Typography is="span" type="hummingbird" color="black">
              {`${data.aircraftCategory.name}`}
            </Typography>

            <Typography is="span" type="hummingbird" color="lightGray">
              <FormattedMessage
                id={translations.pages.jetCharters.results.seats}
                values={{ seats: data.aircraftCategory.seats, plusSign: data.aircraftCategory.name === 'Heavy Jet' }}
              />
            </Typography>
          </div>

          <div className="ml-auto flex flex-col">
            <Typography is="span" type="hummingbird" color="lightGray">
              <FormattedMessage id={translations.pages.jetCharters.results.estimatedPrice} />
            </Typography>

            <Typography is="span" type="halcyon" color="black" className="text-right">
              {`$${data.price.toLocaleString()}`}
            </Typography>
          </div>
        </Card.Row>
      </Card>
    </Link>
  );
};

export default ResultCardItem;
