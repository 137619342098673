import React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Link from '@flyblack/common/components/Link';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';

import bgLoginImg from '@/assets/bg-login.jpg';

const ForgotPasswordLinkSent: React.FC = () => {
  const history = useHistory();

  return (
    <div className="flex flex-col h-full w-full">
      <Helmet bodyAttributes={{ style: `background-image: url(${bgLoginImg})` }} />

      <div className="flex flex-col text-center bg-white md:rounded-lg my-auto sm:max-w-[800px] mx-auto items-center w-full">
        <Spacer xs={7} />

        <div className="flex flex-col sm:max-w-[400px] w-[320px] sm:w-full px-5">
          <div className="text-2xl text-black text-center">
            <FormattedMessage id={translations.pages.resetLinkSent.reset} />
          </div>

          <Spacer xs={6} />

          <div className="text-xl text-black">
            <FormattedMessage id={translations.pages.resetLinkSent.title} />
          </div>

          <Spacer xs={2} />

          <div className="text-sm font-normal text-flyblack-light-gray">
            <FormattedMessage id={translations.pages.resetLinkSent.subtitle} />
          </div>

          <Spacer xs={5} />

          <Button
            onClick={() => history.push(urls.signIn)}
            fat
            type="button"
            className="font-medium"
            appearance="black"
          >
            <FormattedMessage id={translations.pages.resetLinkSent.backToLogin} />
          </Button>

          <Spacer xs={4} />

          <Link type="button" className="text-sm text-black" to={urls.forgotPassword.main}>
            <FormattedMessage id={translations.pages.resetLinkSent.resend} />
          </Link>

          <Spacer xs={6} />
        </div>
      </div>

      <Spacer xs={10} />
    </div>
  );
};

export default ForgotPasswordLinkSent;
