import React from 'react';
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess } from 'react-plaid-link';

import { createACHPaymentMethod } from '@/services/api/payment';

interface Props {
  token: string;
  onSetupSuccess?: () => any;
  onSetupFail?: () => any;
}

const OpenPlaidLink = ({ token, onSetupSuccess, onSetupFail }: Props) => {
  const onSuccess = React.useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    switch (metadata.accounts.length) {
      case 1:
        return createACHPaymentMethod(metadata.accounts[0].id, publicToken)
          .then(() => onSetupSuccess && onSetupSuccess())
          .catch(() => onSetupFail && onSetupFail());
      default:
        return onSetupFail && onSetupFail();
    }
  }, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess
  };

  const { open, ready, error } = usePlaidLink(config);

  // this opens link as soon as it's ready
  React.useEffect(() => {
    if (!ready) {
      return;
    }
    open();
  }, [ready, open]);

  // don't render anything, just open Link
  return null;
};

export default OpenPlaidLink;
