import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { CreditCardBrand } from '@flyblack/common/domains';

import Typography from '@flyblack/common/components/Typography';
import Icon from '@flyblack/common/components/Icon';

import imgAmex from './images/ic_amex.svg';
import imgCartesBancaires from './images/ic_cartes_bancaires.svg';
import imgDinersClub from './images/ic_diners_club.svg';
import imgDiscover from './images/ic_discover.svg';
import imgJcb from './images/ic_jcb.svg';
import imgMastercard from './images/ic_mastercard.svg';
import imgUnionpay from './images/ic_unionpay.svg';
import imgVisa from './images/ic_visa.svg';

const creditCardBrandToImgMap: Record<CreditCardBrand, string> = {
  amex: imgAmex,
  /* eslint-disable-next-line @typescript-eslint/camelcase */
  cartes_bancaires: imgCartesBancaires,
  diners: imgDinersClub,
  discover: imgDiscover,
  jcb: imgJcb,
  mastercard: imgMastercard,
  unionpay: imgUnionpay,
  visa: imgVisa
};

interface Props {
  brand: CreditCardBrand;
  last4: string;
}

const CreditCardListItem: React.FC<Props> = ({ brand, last4 }) => {
  return (
    <div className="flex items-center leading-6">
      {creditCardBrandToImgMap[brand] ? (
        <img src={creditCardBrandToImgMap[brand]} className="w-8 h-8" />
      ) : (
        <Icon type="payment" />
      )}

      <Typography is="div" type="hummingbird" className="pl-4">
        <FormattedMessage id={translations.pages.checkout.paymentSection.creditCard} values={{ last4 }} />
      </Typography>
    </div>
  );
};

export default CreditCardListItem;
