import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { translations } from '@/locale';
import { urls } from '@/constants';
import { getJetCharterTripPrice } from '@/services/api/trips';

import useLoad from '@flyblack/common/hooks/useLoad';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import Spacer from '@flyblack/common/components/Spacer';
import Loading from '@flyblack/common/components/Loading';
import { AircraftCategory } from '@flyblack/common/domains';

interface Props {
  tripId: number;
  aircraftCategory: AircraftCategory;
  className?: string;
}

const BookingConfirmedSection = ({ tripId, aircraftCategory, className }: Props) => {
  const { value: jetCharterTripPrice, loading, error } = useLoad({
    load: () => getJetCharterTripPrice(tripId)
  });
  const history = useHistory();

  return (
    <div className={classnames('bg-white flex flex-col w-full p-6 mb-auto text-black max-w-[480px]', className)}>
      <Typography is="span" type="halcyon" weight="medium">
        <FormattedMessage id={translations.pages.myTrips.jetCharter.bookingConfirmed.title} />
      </Typography>

      <Spacer xs={2} />

      <Typography is="span" type="hummingbird" color="lightGray">
        <FormattedMessage id={translations.pages.myTrips.jetCharter.bookingConfirmed.detail} />
      </Typography>

      <Spacer xs={2} />

      {loading ? (
        <Loading visible={true} className="ml-2">
          <Loading.Indicator size={20} borderWidth={2} />
        </Loading>
      ) : (
        !error && (
          <Typography is="span" type="hummingbird" color="lightGray">
            <FormattedMessage
              id={translations.pages.myTrips.jetCharter.bookingConfirmed.price}
              values={{ symbol: jetCharterTripPrice.price.symbol, value: jetCharterTripPrice.price.value }}
            />
          </Typography>
        )
      )}

      <Typography is="span" type="hummingbird" color="lightGray">
        <FormattedMessage
          id={translations.pages.myTrips.jetCharter.bookingConfirmed.aircraft}
          values={{ aircraftCategory: aircraftCategory.name }}
        />
      </Typography>

      <Spacer xs={4} />

      <Button
        onClick={() => history.push(urls.jetCharters.checkout.get(tripId))}
        appearance="black"
        fat
        className="w-full"
      >
        <FormattedMessage id={translations.inputs.buttons.proceedToCheckout} />
      </Button>
    </div>
  );
};

export default BookingConfirmedSection;
