import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import { AircraftCategory, AircraftType, GalleryImage } from '@flyblack/common/domains';

import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';
import Loading from '@flyblack/common/components/Loading';

import placeholder from './placeholder.jpg';

export interface Props {
  images?: GalleryImage[];
  loading?: boolean;
  className?: string;
  airplaneCategory?: AircraftCategory;
  airplaneType?: AircraftType;
}

const ClientPhotoGallery: React.FC<Props> = ({ images, loading, className, airplaneCategory, airplaneType }) => {
  const [imageIndex, setImageIndex] = React.useState(0);

  const history = useHistory();

  const onBackClick = React.useCallback(() => setImageIndex((index) => (index === 0 ? images.length - 1 : index - 1)), [
    imageIndex,
    images
  ]);

  const onForwardClick = React.useCallback(
    () => setImageIndex((index) => (index === images.length - 1 ? 0 : index + 1)),
    [imageIndex, images]
  );

  return loading ? (
    <Loading visible={loading} center className="h-[270px] sm:h-[350px] max-w-[480px]">
      <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
    </Loading>
  ) : (
    <div
      className={classnames(
        `relative flex flex-col justify-center h-[270px] sm:h-[350px] max-w-[480px] w-full`,
        className
      )}
    >
      <div className="absolute w-full h-full">
        <img
          className="w-full h-full object-cover rounded-t-sm"
          src={images.length > 0 ? images[imageIndex].url : placeholder}
        />
      </div>

      <div className="flex justify-between w-full px-6 z-[1]">
        <Button
          type="button"
          appearance="transparent"
          className="px-0 h-6 w-6 hover:bg-transparent rounded-[50%]"
          onClick={onBackClick}
        >
          <Icon type="arrowLeft" />
        </Button>

        <Button
          type="button"
          appearance="transparent"
          className="px-0 h-6 w-6 hover:bg-transparent rounded-[50%]"
          onClick={onForwardClick}
        >
          <Icon type="arrowRight" />
        </Button>
      </div>

      {airplaneCategory && (
        <div className="absolute inset-x bottom-0 px-6 flex w-full items-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm h-16 sm:h-24 z-[1]">
          <Icon type="navigationPlane" className="mr-6" />

          <div className="flex flex-col">
            <div className="text-flyblack-light-gray text-[10px] leading-4 sm:text-sm">
              <FormattedMessage id={translations.application.common.clientPhotoGallery.title} />
            </div>

            <div className="text-white text-xs leading-5 sm:text-base">
              {airplaneType ? `${airplaneType.name}, ${airplaneCategory.name}` : `${airplaneCategory.name}`}
            </div>
          </div>

          <Button
            type="button"
            onClick={() =>
              history.push(
                airplaneType
                  ? urls.planeDetail.getType(airplaneType.id)
                  : urls.planeDetail.getCategory(airplaneCategory.id)
              )
            }
            appearance="transparent"
            className="pr-0 ml-auto h-6 w-6 hover:bg-opacity-0 flex items-center justify-around hover:bg-black"
          >
            <Icon type="forward" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ClientPhotoGallery;
