import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { JetShuttleStatus, Sort, By, Order, JetShuttleDetail } from '@flyblack/common/domains';
import { getShuttles } from '@/services/api/shuttle';

import Container from '@flyblack/common/components/Container';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import InfiniteScroll from '@flyblack/common/components/InfiniteScroll';
import Loading from '@flyblack/common/components/Loading';
import FilterManager from '@flyblack/common/components/FilterManager';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import DateRangePickerInput from '@flyblack/common/components/Form/Input/DateRangePickerInput';
import Icon from '@flyblack/common/components/Icon';

import JetShuttleCardItem from './JetShuttleCardItem';
import JetShuttleRoutes from './JetShuttleRoutes';

const JetShuttles = () => {
  const intl = useIntl();

  return (
    <div className="bg-black min-h-full">
      <Container className="max-w-md md:max-w-3xl lg:max-w-4xl min-h-full">
        <Typography is="div" type="flamingo" className="pt-10 pb-6 md:pt-14">
          <FormattedMessage id={translations.pages.jetShuttles.title} />
        </Typography>

        <Spacer xs={4} />

        <Typography is="div" type="halcyon" color="lightGray">
          <FormattedMessage id={translations.pages.jetShuttles.subtitle} />
        </Typography>

        <Spacer xs={7} />

        <JetShuttleRoutes />

        <FilterManager initialCriteria={{ start: null, end: null }}>
          {({ criteria, updateCriteria, debouncedUpdate }) => {
            const updateDateCriteria = (dateEdge: string, value: string) => {
              updateCriteria(dateEdge, value);
              if (dateEdge === 'start') {
                updateCriteria('end', value);
              } else if (dateEdge === 'end' && !criteria.start) {
                updateCriteria('start', value);
              }
            };

            return (
              <React.Fragment>
                <div className="flex flex-col md:flex-row pb-10 md:pb-14">
                  <TextInput
                    id="search"
                    type="text"
                    leadingIcon="search"
                    onChange={(event) => debouncedUpdate('search', event.target.value)}
                    className="flex-1 pb-6 md:pb-0"
                    placeholder={intl.formatMessage({
                      id: translations.inputs.searchBars.searchJetShuttles.placeholder
                    })}
                  />

                  <Spacer className="w-4" />

                  <div className="flex flex-row items-center justify-between flex-1">
                    <div className="relative flex-grow">
                      <DateRangePickerInput
                        id="dateRange"
                        type="text"
                        name="dateRange"
                        placeholder={intl.formatMessage({ id: translations.inputs.dateRange.label })}
                        startDateValue={criteria.start}
                        endDateValue={criteria.end}
                        changeStartDate={(_, value) => updateDateCriteria('start', value)}
                        changeEndDate={(_, value) => updateDateCriteria('end', value)}
                      />
                      {(criteria.start || criteria.end) && (
                        <div className="cursor-pointer absolute right-0 top-1/2 -translate-y-1/2 p-1">
                          <div onClick={() => updateDateCriteria('start', null)}>
                            <Icon type="close" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <InfiniteScroll
                  key={JSON.stringify(criteria)}
                  source={(pageNumber, size) =>
                    getShuttles(pageNumber, size, {
                      keyword: criteria.search,
                      start: criteria.start,
                      end: criteria.end,
                      status: JetShuttleStatus.Open,
                      ...((criteria.search || criteria.start || criteria.end) && {
                        [Sort.By]: By.DepartureDate,
                        [Sort.Order]: Order.Ascendent
                      })
                    })
                  }
                  className="flex flex-col mb-6 min-h-[600px] h-[600px]"
                >
                  {({ data, loading }) =>
                    !loading && data.length === 0 ? (
                      <div className="flex flex-col items-center mt-40">
                        <Typography is="span" type="halcyon">
                          <FormattedMessage id={translations.pages.jetShuttles.empty.title} />
                        </Typography>

                        <Typography is="span" type="hummingbird" color="lightGray">
                          <FormattedMessage id={translations.pages.jetShuttles.empty.subtitle} />
                        </Typography>
                      </div>
                    ) : (
                      <React.Fragment>
                        {data.map((item: JetShuttleDetail) => (
                          <JetShuttleCardItem
                            key={item.id}
                            id={item.id}
                            departsAt={item.schedule.departsAt}
                            sourceCity={item.sourceAirport.city.name}
                            destinationCity={item.destinationAirport.city.name}
                            aircraftType={!!item.aircraftType && item.aircraftType.name}
                            aircraftCategory={item.aircraftCategory.name}
                            availableSeats={item.availableSeats}
                            price={item.price}
                            photoUrl={
                              item.aircraftType
                                ? item.aircraftType.photos[0].url
                                : item.aircraftCategory.photos.find((photo) => photo.type !== 'MAIN_PHOTO').url ||
                                  item.aircraftCategory.photos[0].url
                            }
                            eventName={item.eventName}
                          />
                        ))}

                        <Loading visible={loading} center className="mt-[250px]">
                          <Loading.Indicator size={100} borderWidth={2} />
                        </Loading>
                      </React.Fragment>
                    )
                  }
                </InfiniteScroll>
              </React.Fragment>
            );
          }}
        </FilterManager>
      </Container>
    </div>
  );
};

export default JetShuttles;
