import React from 'react';
import classnames from 'classnames';

export interface Props {
  active: boolean;
  text: string;
  onClick: () => any;
}

const UnderlinedButton: React.FC<Props> = ({ active, text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={classnames(
        'relative text-sm py-2 transition-colors group hover:text-white',
        active ? 'text-white' : 'text-flyblack-light-gray'
      )}
    >
      <span
        className={classnames(
          'absolute bottom-0 left-0 w-6 h-[1px] bg-white transform transition-all duration-300 group-hover:scale-x-100 group-hover:bg-opacity-80',
          active ? 'scale-x-100' : 'scale-x-0'
        )}
      />
      {text}
    </div>
  );
};

export default UnderlinedButton;
