import * as COMMON_CONFIG from '@flyblack/common/config';

export const { API, GOOGLE_API_KEY, ENVIRONMENT, SENTRY_DSN, RELEASE } = COMMON_CONFIG;

declare var CONFIG: {
  STRIPE_PUBLIC_KEY: string;
  SENDBIRD_APP_ID: string;
  CLIENT_ID: string;
  ABOUT_US_URL: string;
  CONTACT_US_URL: string;
};

export const { STRIPE_PUBLIC_KEY, SENDBIRD_APP_ID, CLIENT_ID, CONTACT_US_URL, ABOUT_US_URL } = CONFIG;
